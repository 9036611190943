import cls from "./EditVideo.module.scss";
import AppHeader from "../../../components/appHeader/AppHeader";
import SidebarMenu from "../../../components/sidebarMenu/SidebarMenu";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { CollapsedSidebarContext } from "../../../context/collapsedSidebarProvider";
import MainBtn from "../../../components/common/buttons/mainBtn/MainBtn";
import FormInput from "../../../components/common/formInput/FormInput";

import { useMutation, useQueryClient } from "react-query";
import Loading from "../../../components/common/loading/Loading";
import ConfirmationPopUp from "../../../components/common/confirmationPopUp/ConfirmationPopUp";

import { UrlParams } from "../../../interfaces/UrlParams";
import {
  deleteVideo,
  updateVideo,
  useVideos,
  VideoToUpdate,
} from "../../../api/videos/videosService";

import { IVideo } from "../../../interfaces/IVideo";
import { useWeeks } from "../../../api/weeks/weeksService";
import { IWeek } from "../../../interfaces/IWeek";
import CallToActionPopUp from "../../../components/common/callToActionPopUp/CallToActionPopUp";

const EditVideo: React.FC = () => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, termId, weekId, videoId } = routeParameters;
  const queryClient = useQueryClient();
  const { collapsed } = useContext(CollapsedSidebarContext);
  const navigate = useNavigate();
  const [currentVideoData, setCurrentVideoData] = useState<IVideo | null>(null);
  const [currentWeekData, setCurrentWeekData] = useState<IWeek | null>(null);
  const [deleteVideoPopup, showDeleteVideoPopup] = useState<boolean>(false);
  const [successConfirmationDelete, showSuccessConfirmationDelete] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [title, setVideoTitle] = useState<string | null>(null);
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);

  const { data: videosData, isLoading: isLoadingVideos } = useVideos(
    courseId,
    termId,
    weekId
  );
  const { data: weeksData, isLoading: isLoadingWeeks } = useWeeks(
    courseId,
    termId
  );
  const [successConfirmation, showSuccessConfirmation] =
    useState<boolean>(false);

  const [errorConfirmation, showErrorConfirmation] = useState<boolean>(false);

  const updateVideosRQ = useMutation(updateVideo, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("videos saved successfully!");
      queryClient.invalidateQueries(["videos", courseId, termId, weekId]);
      showSuccessConfirmation(true);
    },
    onError: (err) => {
      console.log("error videos updated", err);
      setIsLoading(false);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  // handle video title
  const handleChangeVideoTitle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const txtVal = event.target.value;
    setVideoTitle(txtVal);
  };

  // handle input videos
  const handleOnchangeInputVideos = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const files = event.target.files;
    if (files) {
      const videoFile = files[0];
      setVideoFile(videoFile);
    }
  };
  // handle video thimbnails
  const handleOnchangeVideoThumbnail = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const files = event.target.files;

    if (files) {
      const file = files[0];
      setThumbnailFile(file);
    }
  };
  // handle update video
  const handleUpdateVideo = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (title || videoFile || thumbnailFile) {
      if (currentWeekData && currentVideoData) {
        const videoToUpdate: VideoToUpdate = {
          videoFile: videoFile,
          thumbnailImgFile: thumbnailFile,
          videoTitle: title,
          courseId,
          termId,
          weekId,
          initialVideo: currentVideoData,
          vimeoVideoId: currentVideoData.metadata?.vimeoVideoId as string,
          parentFolderUri: currentWeekData.metadata.folderUri,
        };

        updateVideosRQ.mutate(videoToUpdate);
      }

      console.log({ title, videoFile, thumbnailFile });
    }
  };

  // handle delete video
  const deleteVideoRQ = useMutation(deleteVideo, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("video deleted successfully!");
      queryClient.invalidateQueries(["videos", courseId, termId, weekId]);
      showSuccessConfirmationDelete(true);
    },
    onError: (err) => {
      console.log("error deleting course", err);
      setIsLoading(false);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });
  // handle confirm delete video
  const handleConfirmDeleteVideo = () => {
    deleteVideoRQ.mutate({ courseId, termId, weekId, videoId });
    showDeleteVideoPopup(false);
  };

  useEffect(() => {
    if (videosData) {
      const currentVideoData = videosData.find(
        (video: IVideo) => video.id === videoId
      );
      setCurrentVideoData(currentVideoData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videosData]);
  useEffect(() => {
    if (weeksData) {
      const currentWeekData = weeksData.find(
        (week: IWeek) => week.id === weekId
      );
      setCurrentWeekData(currentWeekData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeksData]);
  return (
    <div className={cls.root}>
      {successConfirmation && (
        <ConfirmationPopUp
          type='success'
          message='Video Updated!'
          onClick={() => showSuccessConfirmation(false)}
        />
      )}
      {errorConfirmation && (
        <ConfirmationPopUp
          type='error'
          message='Something went wrong! Please try again.'
          onClick={() => showErrorConfirmation(false)}
        />
      )}
      {deleteVideoPopup && (
        <CallToActionPopUp
          message='Are you sure you want to delete this video?'
          onDeny={() => showDeleteVideoPopup(false)}
          onConfirm={handleConfirmDeleteVideo}
        />
      )}

      {successConfirmationDelete && (
        <ConfirmationPopUp
          type='success'
          message='Video deleted!'
          onClick={() => navigate(`/courses/${courseId}/${termId}/${weekId}`)}
        />
      )}
      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />

        <div className={cls.container}>
          {(isLoading || isLoadingVideos || isLoadingWeeks) && <Loading />}

          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Edit Video</div>
              <div className={cls.subtitle}>{currentVideoData?.title}</div>
            </div>
          </div>

          <div className={cls.contents}>
            <div className={cls.form}>
              <form action=''>
                <div className={cls.row}>
                  <div className={cls.fields}>
                    <div className={cls.field}>
                      <div className={cls.title}>Update video</div>
                      <FormInput
                        id='videoTitle'
                        type='text'
                        label='title'
                        maxLength={60}
                        height={75}
                        onChange={handleChangeVideoTitle}
                        defaultValue={currentVideoData?.title}
                      />
                    </div>
                    <div className={cls.field}>
                      <div className={cls.videoUploader}>
                        <label
                          htmlFor='videoFileInput'
                          role='button'
                          className={cls.fileUpload}>
                          Click to upload video file
                        </label>
                        <input
                          // disabled={disabled}

                          id='videoFileInput'
                          type='file'
                          name='videos'
                          multiple={false}
                          accept='video/mp4, video/mov'
                          onChange={handleOnchangeInputVideos}
                        />
                        {videoFile && (
                          <video src={URL.createObjectURL(videoFile)} />
                        )}

                        {!videoFile && (
                          <img
                            src={
                              currentVideoData?.thumbnailSource ||
                              "/placeholder.webp"
                            }
                            alt=''
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cls.row}>
                  <div className={cls.bgImg}>
                    <div className={cls.label}>Background Image</div>
                    <label
                      htmlFor='thumbnailImg'
                      role='button'
                      className={cls.fileUpload}>
                      Click to upload file
                    </label>
                    <input
                      id='thumbnailImg'
                      type='file'
                      name='thumbnailImg'
                      multiple={false}
                      accept='image/*'
                      onChange={handleOnchangeVideoThumbnail}
                    />

                    {thumbnailFile && (
                      <img src={URL.createObjectURL(thumbnailFile)} alt='' />
                    )}
                    {!thumbnailFile && (
                      <img
                        src={
                          currentVideoData?.thumbnailSource ||
                          "/placeholder.webp"
                        }
                        alt=''
                      />
                    )}
                  </div>
                </div>
              </form>

              <div className={cls.actions}>
                <MainBtn
                  disabled={isLoading}
                  label='Back'
                  transparent={true}
                  width={130}
                  height={44}
                  onClick={() =>
                    navigate(`/courses/${courseId}/${termId}/${weekId}`)
                  }
                />
                <MainBtn
                  disabled={isLoading}
                  label='Delete'
                  transparent={true}
                  width={130}
                  height={44}
                  onClick={() => showDeleteVideoPopup(true)}
                />
                <div
                  className={cls.save}
                  onClick={handleUpdateVideo}
                  style={{ width: 180, height: 44 }}>
                  Save
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditVideo;
