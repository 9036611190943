import { useNavigate, useParams } from "react-router-dom";
import { ITerm } from "../../interfaces/ITerm";
import cls from "./TermListItem.module.scss";

import LazyImage from "../common/lazyImage/LazyImage";

type Props = { data: ITerm };
const TermListItem: React.FC<Props> = ({ data }) => {
  const routeParameters = useParams();
  const { courseId } = routeParameters;
  const navigate = useNavigate();
  return (
    <div className={cls.root}>
      <div className={cls.wrapper}>
        <div className={cls.avatar}>
          {/* <img src={data.thumbnailUrl} alt='' /> */}
          <LazyImage
            src={data.thumbnailUrl || "/placeholder.webp"}
            type='course'
          />
        </div>

        <div className={cls.subtitle}>{data.semester}</div>

        <div className={cls.title}>{data.title}</div>

        <div className={cls.description}>{data.description}</div>

        <div className={cls.actions}>
          <div
            className={cls.button}
            onClick={() => navigate(`/courses/${courseId}/${data.id}`)}>
            Edit
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermListItem;
