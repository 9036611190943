import { QueryFunctionContext, useQuery, useQueryClient } from "react-query";
import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../requestApi";
import { v4 as uuidV4 } from "uuid";
import { cacheTimes } from "../cacheTimesRQ";
import {
  CourseCreationData,
  EFeatureFlag,
  ICoursePreview,
} from "../../interfaces/ICoursePreview";
import VimeoService from "../vimeo/vimeoService";
import StorageService from "../storage/storageService";
import { EServiceTier, IServiceTier } from "../../interfaces/IServiceTier";
import { ICourseClass } from "../../interfaces/ICourseClass";
import { saveCourseTier } from "../tiers/tiresService";
import { saveClass } from "../class/classService";

const fetchCourses = () => {
  return requestApi({
    url: ApiEndpoints.GET_ALL_COURSES,
    method: "POST",
    data: {},
  });
};
export const useAllCourses = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["all-courses"], fetchCourses, {
    cacheTime: cacheTimes.COURSE_LIST_DATA_CACHE,
    staleTime: cacheTimes.COURSE_LIST_DATA_STALE,
    initialData: queryClient.getQueryData("all-courses"),
    refetchOnWindowFocus: false,
    enabled: enabled,
    select: (data) => data.data.courses,
    onError: (err) => {
      console.log(err);
    },
  });
};

const fetchCourse = ({ queryKey }: QueryFunctionContext) => {
  const courseId: string = queryKey[1] as string;
  return requestApi({
    url: ApiEndpoints.GET_COURSE,
    method: "POST",
    data: { courseId: courseId },
  });
};

export const useCourse = (courseId: string, enabled?: boolean) => {
  const queryClient = useQueryClient();

  return useQuery(["course", courseId], fetchCourse, {
    cacheTime: cacheTimes.COURSE_LIST_DATA_CACHE,
    staleTime: cacheTimes.COURSE_LIST_DATA_STALE,
    initialData: queryClient.getQueryData(["course", courseId]),
    refetchOnWindowFocus: false,
    enabled: enabled,
    select: (data) => data.data.course,
    onError: (err) => {
      console.log(err);
    },
  });
};

export const getDefaultFeatureFlags = () => {
  const featureFlagsMap: Record<EServiceTier, EFeatureFlag[]> = {} as Record<
    EServiceTier,
    EFeatureFlag[]
  >;

  featureFlagsMap[EServiceTier.STANDARD] = [
    EFeatureFlag.ASSIGNMENT_DESCRIPTION,
    EFeatureFlag.STUDENT_LIST,
  ];

  return featureFlagsMap;
};

export const saveCourse = (course: ICoursePreview) => {
  return requestApi({
    url: ApiEndpoints.SAVE_COURSE,
    method: "POST",
    data: course,
  });
};

export const createNewCourse = async (course: CourseCreationData) => {
  const courseId: string = uuidV4();
  let thumbnailUrl: string = "";

  // upload course background image
  if (course.backgroundImage)
    thumbnailUrl = await StorageService.UploadCourseThumbnail(
      course.backgroundImage,
      courseId
    );

  // create course video folder
  const folderUri: string = (await VimeoService.CreateFolder(courseId))
    .folderUri;

  const newCourse: ICoursePreview = {
    id: courseId,
    description: course.description,
    title: course.title,
    thumbnailUrl,
    scheduleId: undefined as unknown as string,
    year: course.year,
    lecturerIds: course.lecturerIds || [],
    reference: course.reference || [],
    syllabus: course.syllabus || [],
    endsAtUnixTimestamp: new Date(course.endingDate || "").getTime(),
    startsAtUnixTimestamp: new Date(course.startingDate || "").getTime(),
    metadata: {
      folderUri,
      isLocked: false,
    },
    featuresFlagMap: course.featuresFlagMap,
    orderIndex: course.orderIndex,
  };

  // save course
  await saveCourse(newCourse);

  await Promise.all([
    ...Object.keys(course.featuresFlagMap).map(async (tier) => {
      const tierToSave: IServiceTier = {
        classCapacity: 10,
        numberOfClasses: 1,
        priceUSDCents: 0,
        remainingSpots: 10,
        tier: tier as EServiceTier,
      };

      await saveCourseTier(tierToSave, courseId);

      const newClass: ICourseClass = {
        enrolledUsers: [],
        instructors: [],
        courseId,
        id: uuidV4(),
        name: `${tier[0].toUpperCase()}-${Math.floor(Math.random() * 1000)
          .toString()
          .padStart(4, "0")}`,
        tier: tierToSave.tier,
        scheduledQAs: {},
      };

      await saveClass(newClass);
    }),
  ]);
  return courseId;
};

type UpdateCoursePayload = {
  course: CourseCreationData;
  existingCourse: ICoursePreview;
};

export const updateCourse = async (payload: UpdateCoursePayload) => {
  let thumbnailUrl: string = payload.existingCourse.thumbnailUrl;

  // upload course background image if is new one
  if (payload.course.backgroundImage)
    thumbnailUrl = await StorageService.UploadCourseThumbnail(
      payload.course.backgroundImage,
      payload.existingCourse.id
    );

  // create new update course obj
  const newCourse: ICoursePreview = {
    id: payload.existingCourse.id,
    description: payload.course.description,
    title: payload.course.title,
    thumbnailUrl: thumbnailUrl,
    year: payload.existingCourse.year,
    lecturerIds:
      payload.course.lecturerIds || payload.existingCourse.lecturerIds,
    reference: payload.course.reference || payload.existingCourse.reference,
    syllabus: payload.course.syllabus || payload.existingCourse.syllabus,
    startsAtUnixTimestamp:
      payload.course.startingDate?.getTime() ||
      payload.existingCourse.startsAtUnixTimestamp,
    endsAtUnixTimestamp:
      payload.course.endingDate?.getTime() ||
      payload.existingCourse.endsAtUnixTimestamp,
    scheduleId: payload.existingCourse.scheduleId,
    metadata: payload.existingCourse.metadata,
    featuresFlagMap: payload.existingCourse.featuresFlagMap,
    orderIndex: payload.existingCourse.orderIndex,
  };

  // save course
  await saveCourse(newCourse);
};

export const deleteCourse = (courseId: string) => {
  return requestApi({
    url: ApiEndpoints.DELETE_COURSE,
    method: "POST",
    data: { courseId },
  });
};
