import { ICoursePreview } from "../../../../../interfaces/ICoursePreview";
import cls from "./AddCourse.module.scss";

type Props = {
  courses: ICoursePreview[];
  onAddCourse: (d: ICoursePreview) => void;
  selectedCourseId?: string;
};

const AddCourse: React.FC<Props> = ({
  courses,
  onAddCourse,
  selectedCourseId,
}) => {
  return (
    <div className={cls.root}>
      <div className={`${cls.container}`}>
        {courses.length === 0 && <p>No courses available for this user!</p>}
        {courses?.map((course: ICoursePreview, index: number) => (
          <div
            className={`${cls.courseItem} ${
              course.id === selectedCourseId ? cls.selected : ""
            }`}
            key={index}
            onClick={() => onAddCourse(course)}>
            <div className={cls.wrapper}>
              <div className={cls.avatar}>
                <img src={course.thumbnailUrl || "/placeholder.webp"} alt='' />
              </div>
              <div className={cls.subtitle}>Lorem ipsum sin dolor</div>

              <div className={cls.title}>{course.title}</div>

              <div className={cls.description}>{course.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddCourse;
