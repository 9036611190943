import { IUser } from "../../interfaces/IUser";
import ProfileImage from "../common/profileImage/ProfileImage";
import cls from "./UserListItem.module.scss";
import { useNavigate } from "react-router-dom";
import CallToActionPopUp from "../common/callToActionPopUp/CallToActionPopUp";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";
import {
  disableUserAccount,
  enableUserAccount,
  muteUserAccount,
  unmuteUserAccount,
} from "../../api/user/userService";
import ConfirmationPopUp from "../common/confirmationPopUp/ConfirmationPopUp";
type Props = { data: IUser };
const UserListItem: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [confirmationEnabledPopUp, showConfirmationEnabledPopUp] =
    useState<boolean>(false);
  const [successConfirmationEnabled, showSuccessConfirmationEnabled] =
    useState<boolean>(false);
  const [successConfirmationDisabled, showSuccessConfirmationDisabled] =
    useState<boolean>(false);

  //////////////////////////////

  const [confirmationMutedPopUp, showConfirmationMutedPopUp] =
    useState<boolean>(false);
  const [successConfirmationMuted, showSuccessConfirmationMuted] =
    useState<boolean>(false);
  const [successConfirmationUnmuted, showSuccessConfirmationUnmuted] =
    useState<boolean>(false);

  const [errorConfirmation, showErrorConfirmation] = useState<boolean>(false);

  const enableUserRQ = useMutation(enableUserAccount, {
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("user enabled successfully!");
      queryClient.invalidateQueries("user-list");
      showSuccessConfirmationEnabled(true);
    },
    onError: (err) => {
      console.log("error user enabled", err);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      // setButtonDisabled(false);
    },
  });

  const disableUserRQ = useMutation(disableUserAccount, {
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("user disabled successfully!");
      queryClient.invalidateQueries("user-list");
      showSuccessConfirmationDisabled(true);
    },
    onError: (err) => {
      console.log("error user disabled", err);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      // setButtonDisabled(false);
    },
  });

  const handleConfirmEnableAccount = () => {
    enableUserRQ.mutate(data.id);
    showConfirmationEnabledPopUp(false);
  };
  const handleConfirmDisableAccount = () => {
    disableUserRQ.mutate(data.id);
    showConfirmationEnabledPopUp(false);
  };

  ////////////////////////////////////////

  const muteUserRQ = useMutation(muteUserAccount, {
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("user muted successfully!");
      queryClient.invalidateQueries("user-list");
      showSuccessConfirmationMuted(true);
    },
    onError: (err) => {
      console.log("error user muted", err);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      // setButtonDisabled(false);
    },
  });

  const unmuteUserRQ = useMutation(unmuteUserAccount, {
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("user unmuted successfully!");
      queryClient.invalidateQueries("user-list");
      showSuccessConfirmationUnmuted(true);
    },
    onError: (err) => {
      console.log("error user disabled", err);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      // setButtonDisabled(false);
    },
  });

  const handleConfirmMuteUser = () => {
    muteUserRQ.mutate(data.id);
    showConfirmationMutedPopUp(false);
  };
  const handleConfirmUnmuteUser = () => {
    unmuteUserRQ.mutate(data.id);
    showConfirmationMutedPopUp(false);
  };

  /////////////////////////////////////////
  const handleDenyConfirmation = () => {
    showConfirmationEnabledPopUp(false);
    showConfirmationMutedPopUp(false);
  };

  return (
    <div className={cls.root}>
      {confirmationEnabledPopUp && (
        <CallToActionPopUp
          message={
            data.isDisabled
              ? "Are you sure you want to enable this account?"
              : "Are you sure you want to disable this account?"
          }
          onConfirm={() =>
            data.isDisabled
              ? handleConfirmEnableAccount()
              : handleConfirmDisableAccount()
          }
          onDeny={() => handleDenyConfirmation()}
        />
      )}

      {successConfirmationEnabled && (
        <ConfirmationPopUp
          type='success'
          message='Account successfully enabled!'
          onClick={() => showSuccessConfirmationEnabled(false)}
        />
      )}
      {successConfirmationDisabled && (
        <ConfirmationPopUp
          type='success'
          message='Account successfully disabled!'
          onClick={() => showSuccessConfirmationDisabled(false)}
        />
      )}

      {/* ///////////////////////////// */}

      {confirmationMutedPopUp && (
        <CallToActionPopUp
          message={
            data.isMuted
              ? "Are you sure you want to unmute this user?"
              : "Are you sure you want to mute this user?"
          }
          onConfirm={() =>
            data.isMuted ? handleConfirmUnmuteUser() : handleConfirmMuteUser()
          }
          onDeny={() => handleDenyConfirmation()}
        />
      )}

      {successConfirmationMuted && (
        <ConfirmationPopUp
          type='success'
          message='User successfully muted!'
          onClick={() => showSuccessConfirmationMuted(false)}
        />
      )}
      {successConfirmationUnmuted && (
        <ConfirmationPopUp
          type='success'
          message='User successfully unmuted!'
          onClick={() => showSuccessConfirmationUnmuted(false)}
        />
      )}

      {/* /////////////////////////////////////// */}
      {errorConfirmation && (
        <ConfirmationPopUp
          type='error'
          message='Could not update the account!'
          onClick={() => showErrorConfirmation(false)}
        />
      )}

      <div
        className={`${cls.wrapper} ${data.isDisabled ? cls.isDisabled : ""}`}>
        <div className={cls.header}>
          <div className={cls.avatar}>
            <ProfileImage size={38} rounded={false} imgUrl={data.avatarUrl} />
          </div>
          <div className={cls.info}>
            <div
              className={cls.name}>{`${data.firstName} ${data.lastName}`}</div>
            <div className={cls.role}>{data.role.name}</div>
          </div>
          {data.role.name === "USER" && (
            <div
              className={cls.muted}
              onClick={() => showConfirmationMutedPopUp(true)}>
              {data.isMuted ? (
                <FaVolumeMute color='#fc5a83' />
              ) : (
                <FaVolumeUp color='#99e24a' />
              )}
            </div>
          )}
        </div>
        <div className={cls.mail}>
          <div className={cls.title}>Email</div>
          <div className={cls.value}>{data.email ? data.email : "--"}</div>
        </div>
        <div className={cls.phone}>
          <div className={cls.title}>Phone Number</div>
          <div className={cls.value}>
            {data.phoneNumber ? data.phoneNumber : "--"}
          </div>
        </div>
        <div className={cls.date}>Added 03.05.1990</div>
        <div className={cls.actions}>
          <div
            className={cls.button}
            onClick={() => navigate(`/users/${data.id}`)}>
            Edit
          </div>
          <div
            onClick={() => showConfirmationEnabledPopUp(true)}
            className={`${cls.button} ${data.isDisabled ? cls.disabled : ""}`}>
            {data.isDisabled ? "Enable" : "Disable"}
          </div>
          <div className={cls.button}>Invoices</div>
        </div>
      </div>
    </div>
  );
};

export default UserListItem;
