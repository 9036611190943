import { useRef } from "react";
import cls from "./SearchInput.module.scss";

type Props = {
  name?: string;
  width?: number;
  height?: number;
  customPlaceholder?: string;
  placeholder?: string;

  disabled?: boolean;
  onValueChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const SearchInput: React.FC<Props> = ({
  name,
  customPlaceholder,
  placeholder,
  width,
  height,

  disabled,
  onValueChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={`${cls.root} ${placeholder ? cls.defaultPLaceholder : ""}`}
      style={{ width: width, height: height }}>
      <input
        ref={inputRef}
        required
        className={cls.input}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onValueChange}
      />
      {customPlaceholder && (
        <label
          htmlFor=''
          className={cls.label}
          onClick={(e) => inputRef.current?.focus()}>
          {customPlaceholder}
        </label>
      )}
    </div>
  );
};

export default SearchInput;
