import cls from "./AddNewClass.module.scss";
import FormInput from "../../common/formInput/FormInput";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { UrlParams } from "../../../interfaces/UrlParams";
// import CourseSelector from "../courseSelector/CourseSelector";

type FormInputs = {
  name: string;
  courseId: string;
};

const schema = yup.object().shape({
  name: yup.string().required("Class name is required!"),
  // courseId: yup.string().required("Course assignment is required!"),
});

type Props = {
  width?: number;
  height?: number;
  onSave: (d: FormInputs) => void;
  onDeny?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};
const AddNewClass: React.FC<Props> = ({ width, height, onDeny, onSave }) => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId } = routeParameters;

  ///////////////// handle submit form
  const { handleSubmit, control } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: FormInputs) => {
    onSave({ ...data, courseId });
  };

  return (
    <div className={cls.root}>
      <div className={cls.wrapper} style={{ width: width, height: height }}>
        <div className={cls.title}>Create New Class</div>

        {/* <form action=''> */}
        <div className={cls.row}>
          <Controller
            name='name'
            defaultValue=''
            control={control}
            render={({
              field: { onChange, onBlur, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <FormInput
                type='text'
                label='Class Name'
                maxLength={38}
                defaultValue=''
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                isTouched={isTouched}
                isDirty={isDirty}
                error={error}
              />
            )}
          />
        </div>
        {/*
        <div className={cls.row}>
          <Controller
            name='courseId'
            defaultValue=''
            control={control}
            render={({
              field: { onChange, onBlur, ref },
              fieldState: { error },
            }) => <CourseSelector onChange={onChange} error={error} />}
          />
        </div> */}
        {/* </form> */}

        <div className={cls.actions}>
          <button onClick={onDeny}>Back</button>
          <button onClick={handleSubmit(onSubmit)}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default AddNewClass;
