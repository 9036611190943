import { useNavigate } from "react-router-dom";
import { useQuestions } from "../../../api/class/classQuestionService";
import { IClassQuery } from "../../../interfaces/IClassQuery";

import cls from "./ClassListItem.module.scss";
type Props = { data: IClassQuery };
const ClassListItem: React.FC<Props> = ({ data }) => {
  const { data: classQuestions, isLoading: isLoadingClassQuestions } =
    useQuestions(data.classId);

  const navigate = useNavigate();

  const classQuestionsCount = classQuestions?.filter(
    (classQuestion: any) =>
      !classQuestion.question.lastAnswer ||
      classQuestion.question.lastAnswer === null ||
      classQuestion.question.lastAnswer?.userRole === "USER"
  ).length;

  // console.log("classQuestionsCount", classQuestionsCount);
  return (
    <div className={cls.root}>
      <div className={cls.wrapper}>
        {classQuestions && classQuestionsCount > 0 && (
          <div className={cls.questionCount}>{classQuestionsCount}</div>
        )}

        <div className={cls.title}>{data.class.name}</div>
        <div className={cls.subtitle}>{data.course?.title}</div>
        <div className={cls.users}>
          <div className={cls.label}>Users</div>
          <div className={cls.count}>{data.class.enrolledUsers.length}</div>
        </div>
        <div className={cls.actions}>
          <div className={cls.button} onClick={() => navigate(data.classId)}>
            Edit Class
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassListItem;
