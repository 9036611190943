import AppHeader from "../../../components/appHeader/AppHeader";
import SidebarMenu from "../../../components/sidebarMenu/SidebarMenu";
import cls from "./EditCourse.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { CollapsedSidebarContext } from "../../../context/collapsedSidebarProvider";
import MainBtn from "../../../components/common/buttons/mainBtn/MainBtn";
import FormInput from "../../../components/common/formInput/FormInput";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextareaInput from "../../../components/common/textareaInput/TextareaInput";
import BackgroundImageUploader from "../../../components/common/backgroundImageUploader/BackgroundImageUploader";
import { ICourseReference } from "../../../interfaces/ICourseReference";
import {
  CourseCreationData,
  ICoursePreview,
} from "../../../interfaces/ICoursePreview";
import CourseReferenceEditor from "../../../components/course/courseReferenceEditor/CourseReferenceEditor";
import LecturersEditor from "../../../components/course/lecturerEditor/LecturerEditor";
import { useMutation, useQueryClient } from "react-query";
import {
  deleteCourse,
  updateCourse,
  useCourse,
} from "../../../api/course/courseService";
import Loading from "../../../components/common/loading/Loading";
import ConfirmationPopUp from "../../../components/common/confirmationPopUp/ConfirmationPopUp";
import CallToActionPopUp from "../../../components/common/callToActionPopUp/CallToActionPopUp";
import { useCourseTerms } from "../../../api/terms/termsService";
import { ITerm } from "../../../interfaces/ITerm";
import TermListItem from "../../../components/termListItem/TermListItem";
import { UrlParams } from "../../../interfaces/UrlParams";
import { ICourseSyllabus } from "../../../interfaces/ICourseSyllabus";
import CourseSyllabusEditor from "../../../components/course/courseSyllabusEditor/CourseSyllabusEditor";

type FormInputs = {
  title: string;
  description: string;
  backgroundImage: File;
  reference: ICourseReference[];
  syllabus: ICourseSyllabus[];
  lecturerIds: string[];
};

const schema = yup.object().shape({
  title: yup.string().required("Course title is required!"),
  description: yup.string().required("Course description is required!"),
  backgroundImage: yup.mixed(),
  reference: yup.array(),
  syllabus: yup.array(),
  lecturerIds: yup.array().of(yup.string()),
});

const CreateCourse: React.FC = () => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId } = routeParameters;

  const { data: courseData, isLoading: isLoadingCourseData } = useCourse(
    courseId,
    !!courseId
  );

  const { data: terms, isLoading: isLoadingTerms } = useCourseTerms(
    courseId,
    !!courseData
  );

  const queryClient = useQueryClient();
  const { collapsed } = useContext(CollapsedSidebarContext);
  const navigate = useNavigate();

  const [deleteCoursePopup, showDeleteCoursePopup] = useState<boolean>(false);
  const [successConfirmationDelete, showSuccessConfirmationDelete] =
    useState<boolean>(false);
  const [successConfirmationUpdate, showSuccessConfirmationUpdate] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  ///////////////// handle submit form
  const { handleSubmit, control, reset } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const updateCourseRQ = useMutation(updateCourse, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("course updated successfully!");
      queryClient.invalidateQueries(["course", courseId]);
      queryClient.invalidateQueries("all-courses");
      showSuccessConfirmationUpdate(true);
      reset();
    },
    onError: (err) => {
      console.log("error updating course...", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });
  const onSubmit = (data: FormInputs) => {
    // console.log({ data, courseData });
    const existingCourse: ICoursePreview = courseData || ({} as ICoursePreview);
    const course: CourseCreationData = {
      ...data,
      featuresFlagMap: existingCourse?.featuresFlagMap,
      year: existingCourse?.year,
      orderIndex: existingCourse?.orderIndex,
    };
    if (!isLoading && courseData)
      updateCourseRQ.mutate({ course, existingCourse });
  };

  //// handle delete course
  const deleteCourseRQ = useMutation(deleteCourse, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("new course deleted successfully!");

      queryClient.invalidateQueries("all-courses");
      queryClient.invalidateQueries("all-classes");

      queryClient.removeQueries(["tiers", courseData?.id]);
      queryClient.removeQueries(["course", courseData?.id]);

      showSuccessConfirmationDelete(true);
    },
    onError: (err) => {
      console.log("error deleting course", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const handleConfirmDeleteCourse = () => {
    if (courseData) {
      deleteCourseRQ.mutate(courseData.id);
      showDeleteCoursePopup(false);
    }
  };

  return (
    <div className={cls.root}>
      {deleteCoursePopup && (
        <CallToActionPopUp
          message='Are you sure you want to delete this course?'
          onDeny={() => showDeleteCoursePopup(false)}
          onConfirm={() => handleConfirmDeleteCourse()}
        />
      )}

      {successConfirmationUpdate && (
        <ConfirmationPopUp
          type='success'
          message='Course updated!'
          onClick={() => showSuccessConfirmationUpdate(false)}
        />
      )}
      {successConfirmationDelete && (
        <ConfirmationPopUp
          type='success'
          message='Course deleted!'
          onClick={() => navigate("/courses")}
        />
      )}
      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />
        {(isLoadingCourseData || isLoading || isLoadingTerms) && <Loading />}
        <div className={cls.container}>
          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Edit Course</div>
              <div className={cls.subtitle}>{courseData?.title}</div>
            </div>
            <MainBtn
              label='Back'
              transparent={true}
              onClick={() => navigate(-1)}
            />
          </div>

          <div className={cls.contents}>
            {courseData && (
              <div className={cls.form}>
                <form action=''>
                  <div className={cls.row}>
                    <div className={cls.title}>Course details</div>
                    <div className={cls.fields}>
                      <div className={cls.field}>
                        <Controller
                          name='title'
                          defaultValue={courseData.title}
                          control={control}
                          render={({
                            field: { onChange, onBlur, ref },
                            fieldState: { isTouched, isDirty, error },
                          }) => (
                            <FormInput
                              type='text'
                              label='Title'
                              maxLength={60}
                              defaultValue={courseData.title}
                              onChange={onChange}
                              onBlur={onBlur}
                              inputRef={ref}
                              isTouched={isTouched}
                              isDirty={isDirty}
                              error={error}
                            />
                          )}
                        />
                      </div>
                      <div className={cls.field}>
                        <Controller
                          name='description'
                          defaultValue={courseData?.description}
                          control={control}
                          rules={{ required: true }}
                          render={({
                            field: { onChange, onBlur, ref },
                            fieldState: { isTouched, isDirty, error },
                          }) => (
                            <TextareaInput
                              label='Description'
                              defaultValue={courseData.description}
                              maxLength={1100}
                              onChange={onChange}
                              onBlur={onBlur}
                              inputRef={ref}
                              isTouched={isTouched}
                              isDirty={isDirty}
                              error={error}
                            />
                          )}
                        />
                      </div>
                      <div className={cls.field}>
                        <Controller
                          name='backgroundImage'
                          control={control}
                          defaultValue={undefined}
                          rules={{ required: true }}
                          render={({
                            field: { onChange, onBlur, ref, value },
                            fieldState: { isTouched, isDirty, error },
                          }) => (
                            <BackgroundImageUploader
                              defaultValue={courseData.thumbnailUrl}
                              label='Background Image'
                              value={value}
                              onChange={(event) => {
                                const val = event.target.files?.[0];
                                return onChange(val);
                              }}
                              onBlur={onBlur}
                              inputRef={ref}
                              isTouched={isTouched}
                              isDirty={isDirty}
                              error={error}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={cls.row}>
                    <div className={cls.title}>Lecture editor</div>
                    <Controller
                      name='lecturerIds'
                      control={control}
                      defaultValue={courseData.lecturerIds}
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => (
                        <LecturersEditor
                          selectedLecturers={courseData.lecturerIds}
                          onChange={onChange}
                          error={error}
                        />
                      )}
                    />
                  </div>
                  <div className={cls.row}>
                    <div className={cls.title}>References category editor</div>

                    <Controller
                      name='reference'
                      control={control}
                      defaultValue={courseData?.reference}
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => (
                        <CourseReferenceEditor
                          referencesData={courseData?.reference || []}
                          onChange={onChange}
                          error={error}
                        />
                      )}
                    />
                  </div>
                  <div className={cls.row}>
                    <div className={cls.title}>Syllabus category editor</div>

                    <Controller
                      name='syllabus'
                      control={control}
                      defaultValue={courseData?.syllabus}
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => (
                        <CourseSyllabusEditor
                          syllabusData={courseData?.syllabus || []}
                          onChange={onChange}
                          error={error}
                        />
                      )}
                    />
                  </div>
                </form>

                <div className={cls.terms}>
                  <div className={cls.title}>Terms</div>
                  <div className={cls.termsContainer}>
                    <div className={cls.add} onClick={() => navigate("terms")}>
                      <div className={cls.body}>
                        <div className={cls.icon}></div>
                        <div className={cls.label}>Add New Term</div>
                      </div>
                    </div>

                    {terms?.map((term: ITerm, index: number) => {
                      return <TermListItem data={term} key={index} />;
                    })}
                  </div>
                </div>

                <div className={cls.actions}>
                  <MainBtn
                    disabled={isLoading}
                    label='Back'
                    transparent={true}
                    width={130}
                    height={44}
                    onClick={() => navigate("/courses")}
                  />

                  <div
                    className={cls.delete}
                    onClick={() => showDeleteCoursePopup(true)}
                    style={{ width: 180, height: 44 }}>
                    Delete
                  </div>

                  <div
                    className={cls.save}
                    onClick={handleSubmit(onSubmit)}
                    style={{ width: 180, height: 44 }}>
                    Save
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCourse;
