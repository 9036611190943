// import { ReactNode } from "react";
import { useEffect, useState } from "react";
import cls from "./LazyImage.module.scss";
type Props = {
  src: string;
  type: "course" | "term";
};
const LazyImage: React.FC<Props> = ({ src, type }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      setIsLoading(false);
    };
  }, [src]);

  return (
    <>
      {isLoading ? (
        <div
          className={`${cls.loader} ${
            type === "course" ? cls.course : cls.term
          }`}></div>
      ) : (
        <img className={cls.image} src={src} alt='' />
      )}
    </>
  );
};

export default LazyImage;
