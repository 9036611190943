import cls from "./CreateTerm.module.scss";
import AppHeader from "../../../components/appHeader/AppHeader";
import SidebarMenu from "../../../components/sidebarMenu/SidebarMenu";

import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { CollapsedSidebarContext } from "../../../context/collapsedSidebarProvider";
import MainBtn from "../../../components/common/buttons/mainBtn/MainBtn";
import FormInput from "../../../components/common/formInput/FormInput";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextareaInput from "../../../components/common/textareaInput/TextareaInput";
import BackgroundImageUploader from "../../../components/common/backgroundImageUploader/BackgroundImageUploader";

import { useMutation, useQueryClient } from "react-query";

import Loading from "../../../components/common/loading/Loading";
import ConfirmationPopUp from "../../../components/common/confirmationPopUp/ConfirmationPopUp";
import {
  createNewTerm,
  CreateNewTermPayload,
  useCourseTerms,
} from "../../../api/terms/termsService";
import { useCourse } from "../../../api/course/courseService";

import { UrlParams } from "../../../interfaces/UrlParams";
import { ITerm } from "../../../interfaces/ITerm";

type FormInputs = {
  title: string;
  description: string;
  backgroundImage: File;
};
const schema = yup.object().shape({
  title: yup.string().required("Term title is required!"),
  description: yup.string().required("Term description is required!"),
  backgroundImage: yup.mixed(),
});

const CreateTerm: React.FC = () => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId } = routeParameters;

  const { data: courseData, isLoading: isLoadingCourseData } = useCourse(
    courseId,
    !!courseId
  );

  const { data: termsList, isLoading: isLoadingTerms } = useCourseTerms(
    courseId,
    !!courseId
  );

  const queryClient = useQueryClient();
  const { collapsed } = useContext(CollapsedSidebarContext);
  const navigate = useNavigate();

  // const [courseData, setCourseData] = useState<ICoursePreview | null>(null);
  const [successConfirmation, showSuccessConfirmation] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  ///////////////// handle submit form
  const { handleSubmit, control } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const createNewTermRQ = useMutation(createNewTerm, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("new term created successfully!");
      queryClient.invalidateQueries(["terms", courseId]);
      showSuccessConfirmation(true);
    },
    onError: (err) => {
      console.log("error course created", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });
  const onSubmit = (data: FormInputs) => {
    console.log(data);

    const termIdx =
      termsList?.length === 0
        ? 0
        : Math.max(...termsList?.map((term: ITerm) => term.orderIndex)) + 1;

    if (courseData) {
      const payload: CreateNewTermPayload = {
        ...data,
        courseId,
        semester: "",
        metadata: courseData?.metadata,
        orderIndex: termIdx,
      };
      if (!isLoading) createNewTermRQ.mutate(payload);
    }
  };

  return (
    <div className={cls.root}>
      {successConfirmation && (
        <ConfirmationPopUp
          type='success'
          message='Term created!'
          onClick={() => navigate(`/courses/${courseId}`)}
        />
      )}
      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />

        <div className={cls.container}>
          {(isLoading || isLoadingCourseData || isLoadingTerms) && <Loading />}

          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Create Term</div>
              <div className={cls.subtitle}>{courseData.title}</div>
            </div>
            <MainBtn
              label='Back'
              transparent={true}
              onClick={() => navigate(-1)}
            />
          </div>

          <div className={cls.contents}>
            <div className={cls.form}>
              <form action=''>
                <div className={cls.row}>
                  <div className={cls.title}>Term details</div>
                  <div className={cls.fields}>
                    <div className={cls.field}>
                      <Controller
                        name='title'
                        defaultValue=''
                        control={control}
                        render={({
                          field: { onChange, onBlur, ref },
                          fieldState: { isTouched, isDirty, error },
                        }) => (
                          <FormInput
                            type='text'
                            label='Title'
                            defaultValue=''
                            maxLength={60}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            isTouched={isTouched}
                            isDirty={isDirty}
                            error={error}
                          />
                        )}
                      />
                    </div>
                    <div className={cls.field}>
                      <Controller
                        name='description'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, ref },
                          fieldState: { isTouched, isDirty, error },
                        }) => (
                          <TextareaInput
                            label='Description'
                            defaultValue=''
                            maxLength={1100}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            isTouched={isTouched}
                            isDirty={isDirty}
                            error={error}
                          />
                        )}
                      />
                    </div>
                    <div className={cls.field}>
                      <Controller
                        name='backgroundImage'
                        control={control}
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, ref, value },
                          fieldState: { isTouched, isDirty, error },
                        }) => (
                          <BackgroundImageUploader
                            defaultValue=''
                            label='Background Image'
                            value={value}
                            onChange={(event) => {
                              const val = event.target.files?.[0];
                              return onChange(val);
                            }}
                            onBlur={onBlur}
                            inputRef={ref}
                            isTouched={isTouched}
                            isDirty={isDirty}
                            error={error}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div className={cls.actions}>
                <MainBtn
                  disabled={isLoading}
                  label='Back'
                  transparent={true}
                  width={130}
                  height={44}
                  onClick={() => navigate("/courses")}
                />

                <div
                  className={cls.save}
                  onClick={handleSubmit(onSubmit)}
                  style={{ width: 180, height: 44 }}>
                  Save
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTerm;
