export enum EServiceTier
{
    STANDARD = 'STANDARD',
    PREMIUM = 'PREMIUM',
    EXCLUSIVE = 'EXCLUSIVE'
}

export interface IServiceTier
{
    tier: EServiceTier;
    classCapacity: number;
    priceUSDCents: number;
    numberOfClasses: number;
    remainingSpots: number;
}
