import { QueryFunctionContext, useQuery, useQueryClient } from "react-query";
import { IChatMessage } from "../../interfaces/IChatMessage";
import {
  ISupportTicket,
  SupportTicketCategory,
} from "../../interfaces/ISupportTicket";
import { cacheTimes } from "../cacheTimesRQ";
import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../requestApi";

const fetchAllSupportTickets = () => {
  return requestApi({
    url: ApiEndpoints.GET_SUPPORT_TICKETS_LIST,
    method: "GET",
    data: null,
  });
};

export const useAllSupportTickets = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["support-tickets"], fetchAllSupportTickets, {
    refetchOnWindowFocus: true,
    enabled: enabled,
    cacheTime: cacheTimes.SUPPORT_TICKETS_CACHE,
    staleTime: cacheTimes.SUPPORT_TICKETS_STALE,
    initialData: queryClient.getQueryData(["support-tickets"]),
    select: (data) =>
      data.data.tickets.sort((a: ISupportTicket, b: ISupportTicket) => {
        const statusOrder = {
          OPEN: 1,
          IN_PROGRESS: 2,
          CLOSED: 3,
        };

        if (a.status !== b.status) {
          return statusOrder[a.status] - statusOrder[b.status];
        }

        return a.timestamp - b.timestamp;
      }),
    onError: (err) => {
      console.log(err);
    },
  });
};

// export const saveSupportTicket = (ticket: ISupportTicket) => {
//   return requestApi({
//     url: ApiEndpoints.SAVE_SUPPORT_TICKET,
//     method: "POST",
//     data: { ticket },
//   });
// };

export type UpdateTicketPayload = {
  ticketId: string;
  updateData: Partial<ISupportTicket>;
};

export const updateSupportTicket = (payload: UpdateTicketPayload) => {
  return requestApi({
    url: ApiEndpoints.UPDATE_SUPPORT_TICKET,
    method: "POST",
    data: { ticketId: payload.ticketId, ...payload.updateData },
  });
};

const fetchSupportTicketMessages = ({ queryKey }: QueryFunctionContext) => {
  const ticketId: string = queryKey[1] as string;
  return requestApi({
    url: ApiEndpoints.GET_SUPPORT_TICKET_MESSAGES,
    method: "POST",
    data: { ticketId },
  });
};

export const useSupportTicketMessages = (
  ticketId: string,
  enabled?: boolean
) => {
  const queryClient = useQueryClient();
  return useQuery(["ticket-messages", ticketId], fetchSupportTicketMessages, {
    refetchOnWindowFocus: true,
    enabled: enabled,
    cacheTime: cacheTimes.SUPPORT_TICKET_MESSAGES_CACHE,
    staleTime: cacheTimes.SUPPORT_TICKET_MESSAGES_STALE,
    initialData: queryClient.getQueryData(["ticket-messages", ticketId]),
    select: (data) =>
      data.data.messages.sort(
        (a: IChatMessage, b: IChatMessage) => a.unixTimestamp - b.unixTimestamp
      ),
    onError: (err) => {
      console.log(err);
    },
  });
};

export type ReplyPayload = {
  ticketId: string;
  message: string;
};

export const submitTicketReply = (payload: ReplyPayload) => {
  return requestApi({
    url: ApiEndpoints.SUBMIT_SUPPORT_TICKET_MESSAGE,
    method: "POST",
    data: payload,
  });
};

const fetchSupportCategories = () => {
  return requestApi({
    url: ApiEndpoints.GET_SUPPORT_TICKET_CATEGORIES,
    method: "GET",
    data: {},
  });
};

export const useSupportCategories = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["support-categories"], fetchSupportCategories, {
    refetchOnWindowFocus: false,
    enabled: enabled,
    cacheTime: cacheTimes.SUPPORT_DEPARTMENTS_CACHE,
    staleTime: cacheTimes.SUPPORT_DEPARTMENTS_STALE,
    initialData: queryClient.getQueryData(["support-categories"]),
    select: (data) =>
      data.data.categories.sort(
        (a: SupportTicketCategory, b: SupportTicketCategory) =>
          a.name.localeCompare(b.name)
      ),
    onError: (err) => {
      console.log(err);
    },
  });
};

export const saveSupportTicketCategories = (
  categories: SupportTicketCategory[]
) => {
  return requestApi({
    url: ApiEndpoints.SAVE_SUPPORT_TICKET_CATEGORIES,
    method: "POST",
    data: { categories },
  });
};

export const deleteSupportTicketCategories = (categoryIds: string[]) => {
  return requestApi({
    url: ApiEndpoints.DELETE_SUPPORT_TICKET_CATEGORIES,
    method: "POST",
    data: { categoryIds },
  });
};
