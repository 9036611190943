import cls from "./CategorySelector.module.scss";
import React, { useRef, useState } from "react";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import Icon from "../../../assets/icons/Icon";
import { useAllHelpQuestionCategories } from "../../../api/help/helpService";
import { HelpQuestionCategory } from "../../../interfaces/IHelpQuestion";

type Props = {
  width?: number;
  height?: number;
  onDeny?: () => void;
  onSubmit: (d: HelpQuestionCategory) => void;
  defaultValue: HelpQuestionCategory;
  // error?: FieldError | undefined;
};

const CategorySelector: React.FC<Props> = ({
  width,
  height,
  defaultValue,
  onDeny,
  onSubmit,

  // error,
}) => {
  const refField = useRef(null);
  const [categoryList, showCategoryList] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] =
    useState<HelpQuestionCategory>(defaultValue);

  const { data: allHelpCategories } = useAllHelpQuestionCategories();

  const handleClickOutsideMenu = (e: MouseEvent) => {
    showCategoryList(false);
  };
  useOnClickOutside(refField, handleClickOutsideMenu);

  /////
  const handleShowCategoryList = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    showCategoryList(!categoryList);
  };
  /////
  const handleSelectCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const catId = e.target.value;
    setSelectedCategory(
      allHelpCategories.find((c: HelpQuestionCategory) => c.id === catId)
    );
    showCategoryList(false);
    // onChange(e);
  };

  return (
    <div className={cls.root}>
      <div className={cls.wrapper} style={{ width: width, height: height }}>
        <div className={cls.body} ref={refField}>
          <div className={cls.label}>Select Category</div>

          <div className={cls.select} onClick={handleShowCategoryList}>
            <Icon
              icon='ArrowLeft'
              viewBox='0 0 320 512'
              size={18}
              className={categoryList ? cls.expanded : ""}
            />

            <div className={cls.value}>{selectedCategory.name}</div>
          </div>

          <div className={`${cls.options} ${categoryList ? cls.show : ""}`}>
            {allHelpCategories?.map(
              (category: HelpQuestionCategory, index: number) => {
                return (
                  <div key={index} className={cls.option}>
                    <label htmlFor={category.id}>{category.name}</label>
                    <input
                      name='select'
                      type='radio'
                      value={category.id}
                      id={category.id}
                      onChange={handleSelectCategory}
                    />
                  </div>
                );
              }
            )}
          </div>
          <div className={cls.actions}>
            <button onClick={onDeny}>Back</button>
            <button onClick={() => onSubmit(selectedCategory)}>Save</button>
          </div>
        </div>
      </div>
      {/* <p className={cls.error}>{`${error ? error.message : ""}`}</p> */}
    </div>
  );
};

export default CategorySelector;
