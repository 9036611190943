import React, { useEffect, useState } from "react";
import { FieldError } from "react-hook-form/dist/types";
import { ICourseSyllabus } from "../../../interfaces/ICourseSyllabus";
import cls from "./CourseSyllabusEditor.module.scss";
import { CgRemoveR } from "react-icons/cg";
import QuillEditor from "../../common/quillEditor/QuillEditor";
import AddNewSyllabusCategory from "../addNewSyllabusCategory/AddNewSyllabusCategory";
import CallToActionPopUp from "../../common/callToActionPopUp/CallToActionPopUp";
import EditSyllabusCategory from "../editSyllabusCategory/EditSyllabusCategory";

import StorageService from "../../../api/storage/storageService";
import { useParams } from "react-router-dom";
import { UrlParams } from "../../../interfaces/UrlParams";

type Props = {
  width?: number;
  height?: number;
  syllabusData: ICourseSyllabus[];
  onChange: (e: ICourseSyllabus[]) => void;
  error?: FieldError | undefined;
};

const CourseSyllabusEditor: React.FC<Props> = ({
  width,
  height,
  syllabusData,
  onChange,
  error,
}) => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId } = routeParameters;
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [dragId, setDragId] = useState<number>(0);
  const [dragEnterId, setDragEnterId] = useState<number>(99);
  const [html, setHtml] = useState<string>("");
  const [pdfFiles, setPdfFiles] = useState<string[]>([]);
  const [editingSyllabus, setEditingSyllabus] =
    useState<ICourseSyllabus | null>(null);

  const [addSyllabusPopup, showAddSyllabusPopup] = useState<boolean>(false);
  const [deleteSyllabusPopup, showDeleteSyllabusPopup] =
    useState<boolean>(false);
  const [editSyllabusPopup, showEditSyllabusPopup] = useState<boolean>(false);

  const [syllabus, setSyllabus] = useState<ICourseSyllabus[]>(syllabusData);

  const [uploadPDFDisabled, setUploadPDFDisabled] = useState<boolean>(false);

  // handle drag event
  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    const dragId = parseInt(event.currentTarget.id);
    setDragId(dragId);
  };
  // handle on drag enter event
  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    const dragEnterId = parseInt(event.currentTarget.id);
    setDragEnterId(dragEnterId);
  };
  // handle drop event
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const dropId = parseInt(event.currentTarget.id);
    setDragEnterId(99);

    const dragBox: ICourseSyllabus = syllabus?.find(
      (syllabus: ICourseSyllabus) => syllabus.orderIndex === dragId
    ) as ICourseSyllabus;

    const dropBox: ICourseSyllabus = syllabus?.find(
      (syllabus: ICourseSyllabus) => syllabus.orderIndex === dropId
    ) as ICourseSyllabus;

    const dragBoxOrderIndex = dragBox.orderIndex;
    const dropBoxOrderIndex = dropBox.orderIndex;

    const newSyllabusState: ICourseSyllabus[] = [];
    if (syllabus) {
      for (const ref of syllabus) {
        if (ref.orderIndex === dragId) {
          const result: ICourseSyllabus = {
            ...ref,
            orderIndex: dropBoxOrderIndex,
          };

          newSyllabusState.push(result);
        } else if (ref.orderIndex === dropId) {
          const result: ICourseSyllabus = {
            ...ref,
            orderIndex: dragBoxOrderIndex,
          };
          newSyllabusState.push(result);
        } else {
          newSyllabusState.push(ref);
        }
      }

      setSyllabus(newSyllabusState.sort((a, b) => a.orderIndex - b.orderIndex));

      if (dragId === activeIndex) setActiveIndex(dropBoxOrderIndex);
      if (dropId === activeIndex) setActiveIndex(dragBoxOrderIndex);
    }
  };
  // handle change syllabus category
  const handleChangeCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("handleChangeCategory");
    updateSyllabusState(activeIndex);

    // set new active index
    const categoryIndex = parseInt(e.currentTarget.id);
    setActiveIndex(categoryIndex);

    // set new html state
    const categoryHtml = syllabus?.find(
      (syllabus: ICourseSyllabus) => syllabus.orderIndex === categoryIndex
    );
    setHtml(categoryHtml?.html || "");
    setPdfFiles(categoryHtml?.pdfFiles || []);
  };
  // handle edit syllabus category content
  const handleSyllabusContentEdit = (content: string) => {
    setHtml(content);
  };
  // handle update syllabus category content
  const updateSyllabusState = (activeIndex: number) => {
    const refCategory: ICourseSyllabus = syllabus?.find(
      (syllabus: ICourseSyllabus) => syllabus.orderIndex === activeIndex
    ) as ICourseSyllabus;

    const newCategoryHtml: ICourseSyllabus = { ...refCategory, html: html };

    const newSyllabuses: ICourseSyllabus[] = syllabus.filter(
      (ref: ICourseSyllabus) => ref.orderIndex !== activeIndex
    );

    const newRefState: ICourseSyllabus[] = [
      ...newSyllabuses,
      newCategoryHtml,
    ].sort((a, b) => a.orderIndex - b.orderIndex);

    setSyllabus(newRefState);
  };
  // handle add syllabus category
  const handleAddSyllabusCategory = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const value: string = e.currentTarget.value;
    if (!value) return;

    const newCategory: ICourseSyllabus = {
      orderIndex: syllabus.length,
      html: `<h1>${value}</h1></br><p>Syllabus content description...</p>`,
      title: value,
      pdfFiles: [],
    };
    if (syllabus.length === 0) {
      setHtml(`<h1>${value}</h1></br><p>Syllabus content description...</p>`);
      setPdfFiles([]);
    }

    setSyllabus([...syllabus, newCategory]);
    showAddSyllabusPopup(false);
  };
  // handle show delete category popup
  const handleshowEditSyllabusPopup = (syllabus: ICourseSyllabus) => {
    setEditingSyllabus(syllabus);
    showEditSyllabusPopup(true);
  };
  // handle update syllabus category
  const handleUpdateSyllabusCategory = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const value: string = e.currentTarget.value;
    console.log(value);
    if (!value) return;

    const newSyllabusState = syllabus.map(
      (refCategory: ICourseSyllabus, index: number) => {
        if (refCategory.orderIndex === editingSyllabus?.orderIndex)
          refCategory.title = value;

        return refCategory;
      }
    );
    setSyllabus(newSyllabusState);

    setEditingSyllabus(null);
    showEditSyllabusPopup(false);
  };
  // handle show delete category popup
  const handleshowDeleteSyllabusPopup = (syllabus: ICourseSyllabus) => {
    setEditingSyllabus(syllabus);
    showDeleteSyllabusPopup(true);
  };
  // handle delete syllabus category
  const handleDeleteSyllabusCategory = () => {
    const newSyllabusState = syllabus
      .filter(
        (refCategory: ICourseSyllabus) =>
          refCategory.orderIndex !== editingSyllabus?.orderIndex
      )
      .map((refCategory: ICourseSyllabus, index: number) => {
        refCategory.orderIndex = index;
        return refCategory;
      });

    setSyllabus(newSyllabusState);
    setActiveIndex(0);
    setHtml(newSyllabusState.length > 0 ? newSyllabusState[0].html : "");
    setPdfFiles(
      newSyllabusState.length > 0 ? newSyllabusState[0].pdfFiles : []
    );

    setEditingSyllabus(null);
    showDeleteSyllabusPopup(false);
  };

  // const handleUpdloadSyllabusPDF = async (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   e.preventDefault();
  //   const files = e.target.files;
  //   if (!courseId) return;
  //   if (!files) return;

  //   try {
  //     await StorageService.uploadCourseSyllabusPDFs(files[0], courseId);

  //     const newSyllabusState = syllabus.map(
  //       (refCategory: ICourseSyllabus, index: number) => {
  //         if (refCategory.orderIndex === activeIndex) {
  //           refCategory.pdfFiles = [...refCategory.pdfFiles, files[0].name];
  //           setPdfFiles([...refCategory.pdfFiles]);
  //         }

  //         return refCategory;
  //       }
  //     );
  //     setSyllabus(newSyllabusState);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleUploadSyllabusPDF = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const files = e.target.files;
    if (!courseId) return;
    if (!files || files.length === 0) return;

    try {
      setUploadPDFDisabled(true);
      const uploadedFile = files[0];
      // if (!isValidFileType(uploadedFile)) {
      //   throw new Error('Invalid file type. Please select a PDF file.');
      // }
      // if (!isValidFileSize(uploadedFile)) {
      //   throw new Error('File size exceeds the allowed limit.');
      // }

      await StorageService.uploadCourseSyllabusPDFs(uploadedFile, courseId);

      const newSyllabusState = syllabus.map(
        (refCategory: ICourseSyllabus, index: number) => {
          if (refCategory.orderIndex === activeIndex) {
            const updatedPdfFiles = [
              ...refCategory.pdfFiles,
              uploadedFile.name,
            ];
            return { ...refCategory, pdfFiles: updatedPdfFiles };
          }
          return refCategory;
        }
      );

      setSyllabus(newSyllabusState);
      setPdfFiles([...newSyllabusState[activeIndex].pdfFiles]);
      setUploadPDFDisabled(false);
    } catch (error) {
      console.error(error);
      // Set an error state or show error message in the UI
    }
  };

  useEffect(() => {
    onChange(syllabus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syllabus]);

  useEffect(() => {
    const categoryHtml = syllabus?.find(
      (syllabus: ICourseSyllabus) => syllabus.orderIndex === activeIndex
    );
    setHtml(categoryHtml?.html || "");
    setPdfFiles(categoryHtml?.pdfFiles || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const toolbarContainer = [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ];

  return (
    <div className={cls.root} style={{ width: width, height: height }}>
      {addSyllabusPopup && (
        <AddNewSyllabusCategory
          onConfirm={handleAddSyllabusCategory}
          onDeny={() => showAddSyllabusPopup(false)}
        />
      )}
      {editSyllabusPopup && (
        <EditSyllabusCategory
          name={editingSyllabus?.title || ""}
          onConfirm={handleUpdateSyllabusCategory}
          onDeny={() => [
            showEditSyllabusPopup(false),
            setEditingSyllabus(null),
          ]}
        />
      )}
      {deleteSyllabusPopup && (
        <CallToActionPopUp
          message='Are you sure you want to delete this category?'
          onDeny={() => [
            showDeleteSyllabusPopup(false),
            setEditingSyllabus(null),
          ]}
          onConfirm={handleDeleteSyllabusCategory}
        />
      )}

      <div className={cls.wrapper}>
        <div className={cls.row}>
          <div className={cls.add} onClick={() => showAddSyllabusPopup(true)}>
            <div className={cls.body}>
              <div className={cls.icon}></div>
              <div className={cls.label}>Add New Category</div>
            </div>
          </div>
          {syllabus?.map((syllabus: ICourseSyllabus, index: number) => {
            return (
              <div
                className={`${cls.category} ${
                  activeIndex === syllabus.orderIndex ? cls.active : ""
                }`}
                key={index}
                id={syllabus.orderIndex.toString()}
                draggable={true}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={handleDragEnter}
                onDragStart={handleDrag}
                onDrop={handleDrop}>
                <div
                  className={`${cls.body} ${
                    dragEnterId === syllabus.orderIndex ? cls.hovered : ""
                  }`}>
                  <input
                    name='category'
                    id={syllabus.orderIndex.toString()}
                    type='radio'
                    checked={activeIndex === syllabus.orderIndex}
                    value={syllabus.title}
                    className={`${
                      activeIndex === syllabus.orderIndex ? cls.active : ""
                    }`}
                    onChange={handleChangeCategory}
                  />
                  <label>{syllabus.title}</label>
                  <div className={cls.actions}>
                    <div
                      className={cls.button}
                      onClick={() => handleshowEditSyllabusPopup(syllabus)}>
                      Edit
                    </div>
                    <div
                      className={cls.button}
                      onClick={() => handleshowDeleteSyllabusPopup(syllabus)}>
                      Delete
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={cls.row}>
          {syllabus.length > 0 && (
            <div
              style={{ display: "flex", flexDirection: "column", flex: "1" }}>
              <QuillEditor
                label='Syllabus Editor'
                value={html}
                toolbarContainer={toolbarContainer}
                formats={formats}
                onBlur={() => updateSyllabusState(activeIndex)}
                onChange={handleSyllabusContentEdit}
              />
              <div
                style={{ display: "flex", flexDirection: "column", flex: "1" }}>
                <div
                  style={{
                    background: "#48515d",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <span> Syllabus files</span>

                  <button
                    style={{
                      marginLeft: "auto",
                      width: "100px",
                      height: "30px",
                      borderRadius: "5px",
                      background: "#cc33ff",
                      outline: "none",
                      boxShadow: "none",
                      border: "none",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("pdfFileInput")?.click();
                    }}
                    // htmlFor='fileInput'
                    role='button'
                    disabled={uploadPDFDisabled}
                    // className={`${cls.fileUpload} ${buttonDisabled ? cls.disabled : ""}`}
                  >
                    {uploadPDFDisabled ? "Uploading..." : " PDF +"}
                  </button>
                  <input
                    disabled={uploadPDFDisabled}
                    id='pdfFileInput'
                    type='file'
                    name='upload'
                    accept='application/pdf'
                    style={{ display: "none" }}
                    onChange={handleUploadSyllabusPDF}
                  />
                </div>
                <div
                  style={{
                    background: "#202934",
                    padding: "20px 10px",
                    minHeight: "100px",
                  }}>
                  {/* {syllabusData[activeIndex].pdfFiles?.map((pdfFile, index) => (
                    <div key={index}>{pdfFile}</div>
                  ))} */}

                  {pdfFiles?.map((pdfFile, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        padding: "5px 0",
                      }}>
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          const newPdfFiles = pdfFiles.filter(
                            (pdf) => pdf !== pdfFile
                          );
                          const newSyllabusState = syllabus.map(
                            (refCategory: ICourseSyllabus, index: number) => {
                              if (refCategory.orderIndex === activeIndex) {
                                return {
                                  ...refCategory,
                                  pdfFiles: newPdfFiles,
                                };
                              }
                              return refCategory;
                            }
                          );
                          setSyllabus(newSyllabusState);
                          setPdfFiles([...newPdfFiles]);
                        }}>
                        <CgRemoveR size={22} color='red' />
                      </span>
                      <span>{pdfFile}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <p className={cls.error}>{`${error ? error.message : ""}`}</p>
    </div>
  );
};

export default CourseSyllabusEditor;
