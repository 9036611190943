import cls from "./CreateWeek.module.scss";
import AppHeader from "../../../components/appHeader/AppHeader";
import SidebarMenu from "../../../components/sidebarMenu/SidebarMenu";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { CollapsedSidebarContext } from "../../../context/collapsedSidebarProvider";
import MainBtn from "../../../components/common/buttons/mainBtn/MainBtn";
import FormInput from "../../../components/common/formInput/FormInput";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import Loading from "../../../components/common/loading/Loading";
import ConfirmationPopUp from "../../../components/common/confirmationPopUp/ConfirmationPopUp";
import WeekRichTextEditor from "../../../components/course/weekRichTextEditor/WeekRichTextEditor";
import {
  createNewWeek,
  CreateNewWeekPayload,
  useWeeks,
} from "../../../api/weeks/weeksService";
import { useCourseTerms } from "../../../api/terms/termsService";
import { ITerm } from "../../../interfaces/ITerm";
import { UrlParams } from "../../../interfaces/UrlParams";
import { IWeek } from "../../../interfaces/IWeek";

type FormInputs = {
  title: string;
  overview: string;
  homeworkDescription: string;
};
const schema = yup.object().shape({
  title: yup.string().required("Week title is required!"),
  overview: yup.string(),
  homeworkDescription: yup.string(),
});

const CreateWeek: React.FC = () => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, termId } = routeParameters;
  const queryClient = useQueryClient();
  const { collapsed } = useContext(CollapsedSidebarContext);
  const navigate = useNavigate();

  const { data: termsData, isLoading: isLoadingTerms } =
    useCourseTerms(courseId);
  const { data: weeksData, isLoading: isLoadingWeeks } = useWeeks(
    courseId,
    termId
  );

  const [successConfirmation, showSuccessConfirmation] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  ///////////////// handle submit form
  const { handleSubmit, control } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const createNewWeekRQ = useMutation(createNewWeek, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("new week created successfully!");
      queryClient.invalidateQueries(["weeks", courseId, termId]);
      showSuccessConfirmation(true);
    },
    onError: (err) => {
      console.log("error week created", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });
  const onSubmit = (data: FormInputs) => {
    if (termsData && weeksData) {
      const thisTerm = termsData.find((term: ITerm) => term.id === termId);
      const payload: CreateNewWeekPayload = {
        ...data,
        courseId: courseId,
        termId: thisTerm.id,
        metadata: thisTerm.metadata,
        orderIndex:
          weeksData.length === 0
            ? 0
            : Math.max(...weeksData.map((obj: IWeek) => obj.orderIndex)) + 1,
      };
      //   console.log(payload);
      createNewWeekRQ.mutate(payload);
    }
  };

  return (
    <div className={cls.root}>
      {successConfirmation && (
        <ConfirmationPopUp
          type='success'
          message='Week created!'
          onClick={() => navigate(`/courses/${courseId}/${termId}`)}
        />
      )}
      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />

        <div className={cls.container}>
          {(isLoading || isLoadingTerms || isLoadingWeeks) && <Loading />}

          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Create Week</div>
              <div className={cls.subtitle}>Lorem ipsum dolor amet.</div>
            </div>
            <MainBtn
              label='Back'
              transparent={true}
              onClick={() => navigate(-1)}
            />
          </div>

          <div className={cls.contents}>
            <div className={cls.form}>
              <form action=''>
                <div className={cls.row}>
                  <div className={cls.title}>Week details</div>
                  <div className={cls.fields}>
                    <div className={cls.field}>
                      <Controller
                        name='title'
                        defaultValue=''
                        control={control}
                        render={({
                          field: { onChange, onBlur, ref },
                          fieldState: { isTouched, isDirty, error },
                        }) => (
                          <FormInput
                            type='text'
                            label='Title'
                            defaultValue=''
                            maxLength={60}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            isTouched={isTouched}
                            isDirty={isDirty}
                            error={error}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className={cls.row}>
                  <Controller
                    name='overview'
                    control={control}
                    defaultValue=''
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <WeekRichTextEditor
                        label='Overview Editor'
                        data=''
                        onChange={onChange}
                        error={error}
                      />
                    )}
                  />
                </div>
                <div className={cls.row}>
                  <Controller
                    name='homeworkDescription'
                    control={control}
                    defaultValue=''
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <WeekRichTextEditor
                        label='Assignment Description Editor'
                        data=''
                        onChange={onChange}
                        error={error}
                      />
                    )}
                  />
                </div>
              </form>
              <div className={cls.actions}>
                <MainBtn
                  disabled={isLoading}
                  label='Back'
                  transparent={true}
                  width={130}
                  height={44}
                  onClick={() => navigate(-1)}
                />

                <div
                  className={cls.save}
                  onClick={handleSubmit(onSubmit)}
                  style={{ width: 180, height: 44 }}>
                  Save
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateWeek;
