import { createContext, useEffect, useState, ReactNode } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../api/firebase";
import { User as FirebaseUser } from "firebase/auth";

type AuthContextProps = {
  currentUser: FirebaseUser | null;
  userRole: string | null;
};

const AuthContext = createContext<AuthContextProps | null>(null);

type Props = {
  children: ReactNode;
};

const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<FirebaseUser | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const { claims } = await user.getIdTokenResult();
          console.log("claims", claims);
          setUserRole(claims.role);
        }
        setCurrentUser(user);
        setLoading(false);
      } catch (error) {
        // Handle error
        console.error("Error setting auth context", error);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const authContextValue: AuthContextProps = {
    currentUser,
    userRole,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
