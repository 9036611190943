export enum ApiEndpoints {
  REGISTER = "/public/register",
  ENROLLED_COURSES_PREVIEW = "/user/enrolled-courses",
  MODULES_FOR_COURSE = "/admin/modules",
  USER = "/admin/user",
  PUBLIC_USER = "/public/user",
  SUBMIT_COMMENT = "/user/submit-comment",
  TOGGLE_HOMEWORK_LIKE = "/user/toggle-like",
  GET_NOTIFICATIONS = "/user/notifications",
  ADD_MESSAGE_TO_GLOBAL_CHAT = "/user/global-chat",
  ADD_FEEDBACK_MESSAGE = "/user/homework-feedback",
  ADD_CLASS_QUESTION = "/user/add-question",
  GET_ALL_CLASS_QUESTIONS = "/user/all-class-questions",
  GET_CLASS_QUESTIONS_BY_WEEK = "/admin/get-questions-for-week",
  GET_CLASS_QUESTIONS_COMMENTS = "/user/class-questions-comments",
  UPDATE_USER = "/admin/update-user",
  GET_ALL_USERS = "/admin/get-all-users",
  SAVE_USER = "/admin/save-user",
  DELETE_USER = "/admin/delete-user",
  DISABLE_USER = "/admin/disable-user",
  MUTE_USER = "/admin/set-mute-state",
  ENABLE_USER = "/admin/enable-user",
  CHECK_USER_DISABLED = "/admin/is-user-disabled",
  GET_ALL_COURSES = "/admin/get-all-courses",
  GET_COURSE = "/user/get-course",
  GET_ALL_MODULES = "/admin/get-modules",
  SAVE_COURSE = "/admin/save-course",
  SAVE_MODULE = "/admin/save-term",
  SAVE_WEEK = "/admin/save-week",
  SAVE_LECTURER = "/admin/save-lecturer",
  SAVE_MODULE_TIER = "/admin/save-term-tier",
  SAVE_CLASS = "/admin/save-class",
  SAVE_VIDEO = "/admin/save-week-video",
  GET_WEEKS = "/admin/get-weeks",
  GET_LECTURERS = "/admin/get-lecturers",
  GET_COURSE_TIERS = "/admin/get-course-tiers",
  GET_TIER_CLASSES = "/admin/get-tier-classes",
  GET_ALL_CLASSES = "/admin/get-all-classes",
  GET_VIDEOS = "/admin/get-week-videos",
  GET_ALL_VIDEOS = "/admin/get-all-videos",
  SAVE_VIDEO_THUMBNAIL = "/admin/save-week-video-thumbnail",
  ADD_COMMENT_TO_CLASS_QUESTION = "/user/add-question-answer",
  DELETE_COURSE = "/admin/delete-course",
  DELETE_VIDEO = "/admin/delete-video",
  DELETE_WEEK = "/admin/delete-week",
  DELETE_LECTURER = "/admin/delete-lecturer",
  DELETE_MODULE = "/admin/delete-term",
  DELETE_MODULE_TIER = "/admin/delete-term-tier",
  DELETE_CLASS = "/admin/delete-class",
  VIMEO_ACCESS_TOKEN = "/admin/vimeo-token",

  GET_HELP_QUESTIONS_LIST = "/public/help-questions",
  SAVE_HELP_QUESTION = "/admin/save-help-question",

  DELETE_HELP_QUESTION = "/admin/delete-help-question",
  GET_HELP_QUESTION_CATEGORIES = "/public/help-question-categories",
  SAVE_HELP_QUESTION_CATEGORIES = "/admin/upsert-help-question-categories",
  DELETE_HELP_QUESTION_CATEGORIES = "/admin/delete-help-question-categories",
  UPDATE_SUPPORT_TICKET = "/admin/update-support-ticket",
  GET_SUPPORT_TICKETS_LIST = "/admin/support-tickets",
  SUBMIT_SUPPORT_TICKET_MESSAGE = "/user/submit-support-ticket-message",
  GET_SUPPORT_TICKET_CATEGORIES = "/public/support-ticket-categories",
  SAVE_SUPPORT_TICKET_CATEGORIES = "/admin/upsert-support-ticket-categories",
  DELETE_SUPPORT_TICKET_CATEGORIES = "/admin/delete-support-ticket-categories",
  GET_SUPPORT_TICKET_MESSAGES = "/user/get-support-ticket-messages",
  GET_SUPPORT_TICKET = "/user/get-support-ticket",
  SAVE_SUPPORT_TICKET = "/admin/save-support-ticket",
  DELETE_SUPPORT_TICKET = "/admin/delete-support-ticket",
  GET_SCHEDULE_MAINTENANCE_LIST = "/admin/maintenance-list",
  SAVE_MAINTENANCE = "/admin/save-maintenance",
  DELETE_MAINTENANCE = "/admin/delete-maintenance",
  GET_ROLES = "/admin/roles",
  CREATE_ACCOUNT = "/admin/create-account",
  GET_TRANSACTIONS = "/transaction/get-all-from-user",
  GET_CHANGE_LOGS = "admin/change-logs/list",
  GET_CHANGE_LOGS_BY_ID = "admin/change-logs",
}
