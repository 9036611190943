import cls from "./ComponentSelector.module.scss";
import React, { useRef, useState } from "react";
import { FieldError } from "react-hook-form/dist/types";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import Icon from "../../../assets/icons/Icon";

type Props = {
  width?: number;
  height?: number;
  defaultValue?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: FieldError | undefined;
};

const ComponentSelector: React.FC<Props> = ({
  width,
  height,
  defaultValue,
  onChange,
  error,
}) => {
  const refField = useRef(null);
  const [componentList, showComponentList] = useState<boolean>(false);
  const [selectedComponent, setSelectedComponent] = useState<string>(
    defaultValue || ""
  );

  //   const { data: courseListData } = useAllCourses();

  const handleClickOutsideMenu = (e: MouseEvent) => {
    showComponentList(false);
  };
  useOnClickOutside(refField, handleClickOutsideMenu);

  /////
  const handleShowComponentList = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    showComponentList(!componentList);
  };
  /////
  const handleSelectCourse = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const val = e.target.value;
    // const selectedCourse = courseListData.find(
    //   (course: ICoursePreview) => course.id === id
    // );
    setSelectedComponent(val);
    showComponentList(false);
    onChange(e);
  };

  return (
    <div className={cls.root} style={{ width: width, height: height }}>
      <div className={cls.wrapper} ref={refField}>
        <div
          className={`${cls.select} ${componentList ? cls.expanded : ""}`}
          onClick={handleShowComponentList}>
          <div className={cls.label}>Component</div>
          <Icon
            icon='ArrowLeft'
            viewBox='0 0 320 512'
            size={18}
            className={componentList ? cls.expanded : ""}
          />

          <div className={cls.value}>{selectedComponent}</div>
        </div>

        <div className={`${cls.options} ${componentList ? cls.show : ""}`}>
          {["All Website"].map((val: string, index: number) => {
            return (
              <div key={index} className={cls.option}>
                <label htmlFor={val}>{val}</label>
                <input
                  name='select'
                  type='radio'
                  value={val}
                  id={val}
                  onChange={handleSelectCourse}
                />
              </div>
            );
          })}
        </div>
      </div>
      <p className={cls.error}>{`${error ? error.message : ""}`}</p>
    </div>
  );
};

export default ComponentSelector;
