import { useQuery, useQueryClient, QueryFunctionContext } from "react-query";
import { IVideo } from "../../interfaces/IVideo";
import { cacheTimes } from "../cacheTimesRQ";
import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../requestApi";
import { v4 as uuidV4 } from "uuid";
import VimeoService, { VimeoUploadResponse } from "../vimeo/vimeoService";
import StorageService from "../storage/storageService";

//// GET
const fetchVideos = ({ queryKey }: QueryFunctionContext) => {
  const courseId: string = queryKey[1] as string;
  const termId: string = queryKey[2] as string;
  const weekId: string = queryKey[3] as string;
  return requestApi({
    url: ApiEndpoints.GET_VIDEOS,
    method: "POST",
    data: { courseId, termId, weekId },
  });
};

export const useVideos = (
  courseId: string,
  termId: string,
  weekId: string,
  enabled?: boolean
) => {
  const queryClient = useQueryClient();
  return useQuery(["videos", courseId, termId, weekId], fetchVideos, {
    refetchOnWindowFocus: false,
    enabled: enabled,
    cacheTime: cacheTimes.VIDEO_LIST_CACHE,
    staleTime: cacheTimes.VIDEO_LIST_STALE,
    initialData: queryClient.getQueryData(["videos", courseId, termId, weekId]),
    select: (data) =>
      data.data.videos.sort(
        (a: IVideo, b: IVideo) => a.orderIndex - b.orderIndex
      ),
    onError: (err) => {
      console.log(err);
    },
  });
};
//// SAVE
const saveVideo = (
  video: IVideo,
  courseId: string,
  termId: string,
  weekId: string
) => {
  return requestApi({
    url: ApiEndpoints.SAVE_VIDEO,
    method: "POST",
    data: { ...video, courseId, termId, weekId },
  });
};
//// SAVE ORDER
export type SwitchVideoOrderPayload = {
  video1: IVideo;
  video2: IVideo;
  courseId: string;
  termId: string;
  weekId: string;
};
export const switchVideoOrder = async (payload: SwitchVideoOrderPayload) => {
  await Promise.all([
    await saveVideo(
      payload.video1,
      payload.courseId,
      payload.termId,
      payload.weekId
    ),
    await saveVideo(
      payload.video2,
      payload.courseId,
      payload.termId,
      payload.weekId
    ),
  ]);
};
//// SAVE
export type VideoToSave = {
  videoFile: File;
  duration: number;
  backgroundImgFile: File | null;
  videoTitle: string;
  courseId: string;
  termId: string;
  weekId: string;
  parentFolderUri: string;
};

export const saveMultipleVideos = async (payload: {
  videoList: VideoToSave[];
  startingIndex: number;
}) => {
  console.log("saveMultipleVideos");
  const videoList = payload.videoList;
  const startingIndex = payload.startingIndex;

  return await Promise.all(
    videoList.map(async (video: VideoToSave, index: number) => {
      const id: string = uuidV4();
      const lengthInSeconds: number = video.duration;
      const title: string = video.videoTitle;
      const orderIndex: number = startingIndex + index;
      let thumbnailSource: string = "";

      const vimeoUploadResponse: VimeoUploadResponse =
        await VimeoService.UploadVideo(
          video.videoFile,
          video.videoTitle,
          video.parentFolderUri,
          (percentageCompleted: number) =>
            (index * 100 + percentageCompleted) / videoList.length
        );

      if (video.backgroundImgFile) {
        thumbnailSource = await StorageService.UploadVideoThumbnail(
          video.backgroundImgFile,
          video.courseId,
          video.termId,
          video.weekId,
          vimeoUploadResponse.vimeoVideoId
        );
        await VimeoService.UploadThumbnailToVimeo(
          vimeoUploadResponse.vimeoVideoId,
          video.backgroundImgFile
        );
      }

      if (!video.backgroundImgFile) {
        try {
          const response = await fetch("/placeholder.webp");
          const blob = await response.blob();
          const file = new File([blob], "placeholder.webp", {
            type: "image/webp",
          });

          await VimeoService.UploadThumbnailToVimeo(
            vimeoUploadResponse.vimeoVideoId,
            file
          );
        } catch (error) {
          console.error("Error fetching and creating file to vimeo:", error);
        }
      }

      const videoPayload: IVideo = {
        id,
        lengthInSeconds,
        source: vimeoUploadResponse.embedUrl,
        title,
        thumbnailSource: thumbnailSource,
        orderIndex,
        metadata: {
          vimeoVideoId: vimeoUploadResponse.vimeoVideoId,
        },
      };

      await saveVideo(videoPayload, video.courseId, video.termId, video.weekId);
    })
  );
};
/// UPDATE
export type VideoToUpdate = {
  videoFile: File | null;
  thumbnailImgFile: File | null;
  videoTitle: string | null;
  courseId: string;
  termId: string;
  weekId: string;
  initialVideo: IVideo;
  vimeoVideoId: string;
  parentFolderUri: string;
};

export const updateVideo = async (payload: VideoToUpdate) => {
  let title: string = payload.initialVideo.title;
  let thumbnailSource: string = payload.initialVideo.thumbnailSource;
  let source: string = payload.initialVideo.source;
  let vimeoVideoId: string = payload.vimeoVideoId;

  if (payload.videoTitle) title = payload.videoTitle;
  // upload new video file
  if (payload.videoFile) {
    const uploadData: VimeoUploadResponse = await VimeoService.UploadVideo(
      payload.videoFile,
      title,
      payload.parentFolderUri,
      (percentageUploaded: number) => percentageUploaded
    );

    vimeoVideoId = uploadData.vimeoVideoId;
    source = uploadData.embedUrl;
    thumbnailSource = uploadData.thumbnailUrl;
  }
  // upload new video thumbnail
  if (payload.thumbnailImgFile) {
    // upload to firebase storage
    thumbnailSource = await StorageService.UploadVideoThumbnail(
      payload.thumbnailImgFile,
      payload.courseId,
      payload.termId,
      payload.weekId,
      vimeoVideoId
    );
    // upload to vimeo storage
    await VimeoService.UploadThumbnailToVimeo(
      vimeoVideoId,
      payload.thumbnailImgFile
    );
  }

  // update with new video details
  const videoPayload: IVideo = {
    ...payload.initialVideo,
    title,
    source,
    thumbnailSource,
    metadata: {
      vimeoVideoId,
    },
  };

  await saveVideo(
    videoPayload,
    payload.courseId,
    payload.termId,
    payload.weekId
  );
};

//// DELETE
type DeleteVideoPayload = {
  courseId: string;
  termId: string;
  weekId: string;
  videoId: string;
};

export const deleteVideo = (payload: DeleteVideoPayload) => {
  return requestApi({
    url: ApiEndpoints.DELETE_VIDEO,
    method: "POST",
    data: payload,
  });
};

export type DeleteMultipleVideosPayload = {
  courseId: string;
  termId: string;
  weekId: string;
  videoIds: string[];
};

export const deleteMultipleVideos = async (
  payload: DeleteMultipleVideosPayload
) => {
  if (payload.videoIds.length === 0) return;

  await Promise.all(
    payload.videoIds.map((videoId: string) => {
      const deletePayload: DeleteVideoPayload = {
        courseId: payload.courseId,
        termId: payload.termId,
        weekId: payload.weekId,
        videoId,
      };
      return deleteVideo(deletePayload);
    })
  );
};
