import cls from "./ConfirmationPopUp.module.scss";
type Props = {
  width?: number;
  height?: number;
  type: "success" | "error";
  message: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};
const ConfirmationPopUp: React.FC<Props> = ({
  width,
  height,
  type,
  message,
  onClick,
}) => {
  return (
    <div className={cls.root} style={{ width: width, height: height }}>
      <div
        className={`${cls.wrapper} ${
          type === "success" ? cls.succes : cls.error
        }`}
        style={{ width: width, height: height }}>
        <div className={cls.icon}>
          {type === "success" && <span>&#x2713;</span>}
          {type === "error" && <span> &#43;</span>}
        </div>
        <div className={cls.message}>{message}</div>
        <button onClick={onClick}>Continue</button>
      </div>
    </div>
  );
};

export default ConfirmationPopUp;
