import React, { useEffect, useState } from "react";
import { FieldError } from "react-hook-form/dist/types";
import { ICourseReference } from "../../../interfaces/ICourseReference";
import cls from "./CourseReferenceEditor.module.scss";

import QuillEditor from "../../common/quillEditor/QuillEditor";
import AddNewReferenceCategory from "../addNewReferenceCategory/AddNewReferenceCategory";
import CallToActionPopUp from "../../common/callToActionPopUp/CallToActionPopUp";
import EditReferenceCategory from "../editReferenceCategory/EditReferenceCategory";

type Props = {
  width?: number;
  height?: number;
  referencesData: ICourseReference[];
  onChange: (e: ICourseReference[]) => void;
  error?: FieldError | undefined;
};

const CourseReferenceEditor: React.FC<Props> = ({
  width,
  height,
  referencesData,
  onChange,
  error,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [dragId, setDragId] = useState<number>(0);
  const [dragEnterId, setDragEnterId] = useState<number>(99);
  const [html, setHtml] = useState<string>("");
  const [editingCategoryReference, setEditingCategoryReference] =
    useState<ICourseReference | null>(null);

  const [addCategoryPopup, showAddCategoryPopup] = useState<boolean>(false);
  const [deleteCategoryPopup, showDeleteCategoryPopup] =
    useState<boolean>(false);
  const [editCategoryPopup, showEditCategoryPopup] = useState<boolean>(false);

  const [references, setReferences] =
    useState<ICourseReference[]>(referencesData);

  // handle drag event
  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    const dragId = parseInt(event.currentTarget.id);
    setDragId(dragId);
  };
  // handle on drag enter event
  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    const dragEnterId = parseInt(event.currentTarget.id);
    setDragEnterId(dragEnterId);
  };
  // handle drop event
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const dropId = parseInt(event.currentTarget.id);
    setDragEnterId(99);

    const dragBox: ICourseReference = references?.find(
      (reference: ICourseReference) => reference.orderIndex === dragId
    ) as ICourseReference;

    const dropBox: ICourseReference = references?.find(
      (reference: ICourseReference) => reference.orderIndex === dropId
    ) as ICourseReference;

    const dragBoxOrderIndex = dragBox.orderIndex;
    const dropBoxOrderIndex = dropBox.orderIndex;

    const newReferenceState: ICourseReference[] = [];
    if (references) {
      for (const ref of references) {
        if (ref.orderIndex === dragId) {
          const result: ICourseReference = {
            ...ref,
            orderIndex: dropBoxOrderIndex,
          };

          newReferenceState.push(result);
        } else if (ref.orderIndex === dropId) {
          const result: ICourseReference = {
            ...ref,
            orderIndex: dragBoxOrderIndex,
          };
          newReferenceState.push(result);
        } else {
          newReferenceState.push(ref);
        }
      }

      setReferences(
        newReferenceState.sort((a, b) => a.orderIndex - b.orderIndex)
      );

      if (dragId === activeIndex) setActiveIndex(dropBoxOrderIndex);
      if (dropId === activeIndex) setActiveIndex(dragBoxOrderIndex);
    }
  };
  // handle change reference category
  const handleChangeCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateReferenceState(activeIndex);

    // set new active index
    const categoryIndex = parseInt(e.currentTarget.id);
    setActiveIndex(categoryIndex);

    // set new html state
    const categoryHtml = references?.find(
      (reference: ICourseReference) => reference.orderIndex === categoryIndex
    );
    setHtml(categoryHtml?.html || "");
  };
  // handle edit reference category content
  const handleReferenceContentEdit = (content: string) => {
    setHtml(content);
  };
  // handle update reference category content
  const updateReferenceState = (activeIndex: number) => {
    const refCategory: ICourseReference = references?.find(
      (reference: ICourseReference) => reference.orderIndex === activeIndex
    ) as ICourseReference;

    const newCategoryHtml: ICourseReference = { ...refCategory, html: html };

    const newReferences: ICourseReference[] = references.filter(
      (ref: ICourseReference) => ref.orderIndex !== activeIndex
    );

    const newRefState: ICourseReference[] = [
      ...newReferences,
      newCategoryHtml,
    ].sort((a, b) => a.orderIndex - b.orderIndex);

    setReferences(newRefState);
  };
  // handle add reference category
  const handleAddReferenceCategory = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const value: string = e.currentTarget.value;
    if (!value) return;

    const newCategory: ICourseReference = {
      orderIndex: references.length,
      html: `<h1>${value}</h1></br><p>Reference content description...</p>`,
      title: value,
    };
    if (references.length === 0)
      setHtml(`<h1>${value}</h1></br><p>Reference content description...</p>`);
    setReferences([...references, newCategory]);
    showAddCategoryPopup(false);
  };
  // handle show delete category popup
  const handleShowEditCategoryPopup = (reference: ICourseReference) => {
    setEditingCategoryReference(reference);
    showEditCategoryPopup(true);
  };
  // handle update reference category
  const handleUpdateReferenceCategory = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const value: string = e.currentTarget.value;
    console.log(value);
    if (!value) return;

    const newReferenceState = references.map(
      (refCategory: ICourseReference, index: number) => {
        if (refCategory.orderIndex === editingCategoryReference?.orderIndex)
          refCategory.title = value;

        return refCategory;
      }
    );
    setReferences(newReferenceState);

    setEditingCategoryReference(null);
    showEditCategoryPopup(false);
  };
  // handle show delete category popup
  const handleShowDeleteCategoryPopup = (reference: ICourseReference) => {
    setEditingCategoryReference(reference);
    showDeleteCategoryPopup(true);
  };
  // handle delete reference category
  const handleDeleteReferenceCategory = () => {
    const newReferenceState = references
      .filter(
        (refCategory: ICourseReference) =>
          refCategory.orderIndex !== editingCategoryReference?.orderIndex
      )
      .map((refCategory: ICourseReference, index: number) => {
        refCategory.orderIndex = index;
        return refCategory;
      });

    setReferences(newReferenceState);
    setActiveIndex(0);
    setHtml(newReferenceState.length > 0 ? newReferenceState[0].html : "");

    setEditingCategoryReference(null);
    showDeleteCategoryPopup(false);
  };

  useEffect(() => {
    onChange(references);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [references]);

  useEffect(() => {
    const categoryHtml = references?.find(
      (reference: ICourseReference) => reference.orderIndex === activeIndex
    );
    setHtml(categoryHtml?.html || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const toolbarContainer = [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ];

  return (
    <div className={cls.root} style={{ width: width, height: height }}>
      {addCategoryPopup && (
        <AddNewReferenceCategory
          onConfirm={handleAddReferenceCategory}
          onDeny={() => showAddCategoryPopup(false)}
        />
      )}
      {editCategoryPopup && (
        <EditReferenceCategory
          name={editingCategoryReference?.title || ""}
          onConfirm={handleUpdateReferenceCategory}
          onDeny={() => [
            showEditCategoryPopup(false),
            setEditingCategoryReference(null),
          ]}
        />
      )}
      {deleteCategoryPopup && (
        <CallToActionPopUp
          message='Are you sure you want to delete this category?'
          onDeny={() => [
            showDeleteCategoryPopup(false),
            setEditingCategoryReference(null),
          ]}
          onConfirm={handleDeleteReferenceCategory}
        />
      )}

      <div className={cls.wrapper}>
        <div className={cls.row}>
          <div className={cls.add} onClick={() => showAddCategoryPopup(true)}>
            <div className={cls.body}>
              <div className={cls.icon}></div>
              <div className={cls.label}>Add New Category</div>
            </div>
          </div>
          {references?.map((reference: ICourseReference, index: number) => {
            return (
              <div
                className={`${cls.category} ${
                  activeIndex === reference.orderIndex ? cls.active : ""
                }`}
                key={index}
                id={reference.orderIndex.toString()}
                draggable={true}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={handleDragEnter}
                onDragStart={handleDrag}
                onDrop={handleDrop}>
                <div
                  className={`${cls.body} ${
                    dragEnterId === reference.orderIndex ? cls.hovered : ""
                  }`}>
                  <input
                    name='category'
                    id={reference.orderIndex.toString()}
                    type='radio'
                    checked={activeIndex === reference.orderIndex}
                    value={reference.title}
                    className={`${
                      activeIndex === reference.orderIndex ? cls.active : ""
                    }`}
                    onChange={handleChangeCategory}
                  />
                  <label>{reference.title}</label>
                  <div className={cls.actions}>
                    <div
                      className={cls.button}
                      onClick={() => handleShowEditCategoryPopup(reference)}>
                      Edit
                    </div>
                    <div
                      className={cls.button}
                      onClick={() => handleShowDeleteCategoryPopup(reference)}>
                      Delete
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={cls.row}>
          {references.length > 0 && (
            <QuillEditor
              label='Reference Editor'
              value={html}
              toolbarContainer={toolbarContainer}
              formats={formats}
              onBlur={() => updateReferenceState(activeIndex)}
              onChange={handleReferenceContentEdit}
            />
          )}
        </div>
      </div>

      <p className={cls.error}>{`${error ? error.message : ""}`}</p>
    </div>
  );
};

export default CourseReferenceEditor;
