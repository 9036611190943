import { useNavigate } from "react-router-dom";
import { useCourseTiers } from "../../../api/tiers/tiresService";
import { ICoursePreview } from "../../../interfaces/ICoursePreview";
import LazyImage from "../../common/lazyImage/LazyImage";
import cls from "./CourseListItem.module.scss";

import { FaLockOpen, FaLock } from "react-icons/fa";
import { useAllClasses } from "../../../api/class/classService";
import { IClassQuery } from "../../../interfaces/IClassQuery";
import { useEffect, useState } from "react";
import { useAdminUser } from "../../../api/user/userService";
import Loading from "../../common/loading/Loading";
import { saveCourse } from "../../../api/course/courseService";
import { useQueryClient } from "react-query";

type Props = { data: ICoursePreview };
const CourseListItem: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  useCourseTiers(data.id);
  const { data: userData } = useAdminUser();
  const { data: allClasses } = useAllClasses();

  const [isLocked, setIsLocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigateToEditCourse = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (data?.metadata.isLocked === true) return;
    navigate(`/courses/${data.id}`);
  };

  const handleLockCourse = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (userData?.role.name !== "OWNER") return;

    const lockedCourse = {
      ...data,
      metadata: { ...data.metadata, isLocked: !isLocked },
    };
    setIsLoading(true);
    await saveCourse(lockedCourse)
      .then(() => {
        setIsLocked(!isLocked);
        queryClient.invalidateQueries("all-courses");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });

    console.log("lock course");
  };

  useEffect(() => {
    if (data?.metadata.isLocked === true) {
      setIsLocked(true);
    }
  }, [data]);

  return (
    <div className={cls.root}>
      <div className={cls.wrapper}>
        {isLoading && <Loading />}

        <div className={cls.avatar}>
          <LazyImage
            src={data.thumbnailUrl || "/placeholder.webp"}
            type='course'
          />
        </div>
        <div className={cls.details}>
          <h1 className={cls.title}>{data.title}</h1>
          <div className={cls.description}>{data.description}</div>

          <div className={cls.footer}>
            <div className={cls.stats}>
              <div>
                Nr. of Classes:{" "}
                <b>
                  {
                    allClasses?.filter(
                      (c: IClassQuery) => c.courseId === data.id
                    ).length
                  }
                </b>
              </div>
            </div>

            <div className={cls.actions}>
              <div
                className={`${cls.lockBtn} ${isLocked ? cls.locked : ""}`}
                onClick={handleLockCourse}>
                {isLocked ? <FaLock /> : <FaLockOpen />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cls.navigation}>
        <div
          className={`${cls.button} ${isLocked === true ? cls.disabled : ""}`}
          onClick={navigateToEditCourse}>
          Edit Course
        </div>
        <div
          className={cls.button}
          onClick={() => navigate(`/courses/${data.id}/classes`)}>
          All Classes
        </div>
      </div>
    </div>
  );
};

export default CourseListItem;
