import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "../src/context/authContext";
import { useQueryClient } from "react-query";
import VimeoService from "./api/vimeo/vimeoService";
import { CollapsedSidebarProvider } from "./context/collapsedSidebarProvider";
import Login from "./pages/login/Login";
import Users from "./pages/users/Users";
import Courses from "./pages/courses/Courses";
import Classes from "./pages/classes/Classes";
import Maintenance from "./pages/maintenance/Maintenance";
import Help from "./pages/help/Help";
import Support from "./pages/support/Support";
import History from "./pages/history/History";

import EditUser from "./pages/users/edit/EditUser";
import CreateUser from "./pages/users/create/CreateUser";
import CreateCourse from "./pages/courses/create/CreateCourse";
import EditCourse from "./pages/courses/edit/EditCourse";
import CreateTerm from "./pages/terms/create/CreateTerm";
import EditTerm from "./pages/terms/edit/EditTerm";
import CreateWeek from "./pages/weeks/create/CreateWeek";
import EditWeek from "./pages/weeks/edit/EditWeek";
import AddVideos from "./pages/videos/add/AddVideos";
import EditVideo from "./pages/videos/edit/EditVideo";
import EditClass from "./pages/classes/edit/EditClass";
import EditQuestion from "./pages/help/edit/EditQuestion";
import CreateQuestion from "./pages/help/create/CreateQuestion";

const App: React.FC = () => {
  const authInstance = useContext(AuthContext);
  const queryClient = useQueryClient();

  function InitializeSystems(): void {
    VimeoService.Initialize();
  }

  function UnInitializeSystems(): void {
    queryClient.clear();
  }
  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    return authInstance?.currentUser && authInstance?.userRole !== "USER" ? (
      children
    ) : (
      <Navigate to='/login' />
    );
  };

  const AlreadyAuth = ({ children }: { children: JSX.Element }) => {
    return authInstance?.currentUser && authInstance?.userRole !== "USER" ? (
      <Navigate to='/courses' />
    ) : (
      children
    );
  };
  useEffect(() => {
    if (authInstance?.currentUser && authInstance?.userRole !== "USER") {
      InitializeSystems();
    } else {
      UnInitializeSystems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInstance]);
  return (
    <div className='App'>
      <CollapsedSidebarProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Navigate to='/courses' />} />
            <Route
              path='/login'
              element={
                <AlreadyAuth>
                  <Login />
                </AlreadyAuth>
              }
            />
            <Route
              path='/users'
              element={
                <RequireAuth>
                  <Users />
                </RequireAuth>
              }
            />
            <Route
              path='/users/:userId'
              element={
                <RequireAuth>
                  <EditUser />
                </RequireAuth>
              }
            />
            <Route
              path='/users/create'
              element={
                <RequireAuth>
                  <CreateUser />
                </RequireAuth>
              }
            />

            <Route
              path='/courses'
              element={
                <RequireAuth>
                  <Courses />
                </RequireAuth>
              }
            />
            <Route
              path='/courses/:courseId'
              element={
                <RequireAuth>
                  <EditCourse />
                </RequireAuth>
              }
            />
            <Route
              path='/courses/create'
              element={
                <RequireAuth>
                  <CreateCourse />
                </RequireAuth>
              }
            />
            <Route
              path='/courses/:courseId/terms'
              element={
                <RequireAuth>
                  <CreateTerm />
                </RequireAuth>
              }
            />
            <Route
              path='/courses/:courseId/:termId'
              element={
                <RequireAuth>
                  <EditTerm />
                </RequireAuth>
              }
            />
            <Route
              path='/courses/:courseId/:termId/weeks'
              element={
                <RequireAuth>
                  <CreateWeek />
                </RequireAuth>
              }
            />
            <Route
              path='/courses/:courseId/:termId/:weekId'
              element={
                <RequireAuth>
                  <EditWeek />
                </RequireAuth>
              }
            />
            <Route
              path='/courses/:courseId/:termId/:weekId/videos'
              element={
                <RequireAuth>
                  <AddVideos />
                </RequireAuth>
              }
            />
            <Route
              path='/courses/:courseId/:termId/:weekId/:videoId'
              element={
                <RequireAuth>
                  <EditVideo />
                </RequireAuth>
              }
            />
            <Route
              path='/courses/:courseId/classes'
              element={
                <RequireAuth>
                  <Classes />
                </RequireAuth>
              }
            />
            <Route
              path='/courses/:courseId/classes/:classId'
              element={
                <RequireAuth>
                  <EditClass />
                </RequireAuth>
              }
            />
            {authInstance?.userRole !== "INSTRUCTOR" &&
              authInstance?.userRole !== "USER" && (
                <>
                  {" "}
                  <Route
                    path='/maintenance'
                    element={
                      <RequireAuth>
                        <Maintenance />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/help'
                    element={
                      <RequireAuth>
                        <Help />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/help/create'
                    element={
                      <RequireAuth>
                        <CreateQuestion />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/help/:questionId'
                    element={
                      <RequireAuth>
                        <EditQuestion />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/support'
                    element={
                      <RequireAuth>
                        <Support />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/history'
                    element={
                      <RequireAuth>
                        <History />
                      </RequireAuth>
                    }
                  />
                </>
              )}

            <Route path='*' element='PAGE NOT FOUND - 404' />
          </Routes>
        </BrowserRouter>
      </CollapsedSidebarProvider>
    </div>
  );
};

export default App;
