import cls from "./EditCategory.module.scss";
import FormInput from "../../common/formInput/FormInput";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SupportTicketCategory } from "../../../interfaces/ISupportTicket";

type FormInputs = {
  name: string;
};

const schema = yup.object().shape({
  name: yup.string().required("Category name is required!"),
});

type Props = {
  width?: number;
  height?: number;
  category: SupportTicketCategory | null;
  onSave: (d: SupportTicketCategory) => void;
  onDeny?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};
const EditCategory: React.FC<Props> = ({
  width,
  height,
  category,
  onDeny,
  onSave,
}) => {
  ///////////////// handle submit form
  const { handleSubmit, control } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: FormInputs) => {
    if (category) {
      const payload: SupportTicketCategory = {
        ...category,
        name: data.name,
      };
      onSave(payload);
    }
  };

  return (
    <div className={cls.root}>
      <div className={cls.wrapper} style={{ width: width, height: height }}>
        <div className={cls.title}>Edit Support Category </div>

        {/* <form action=''> */}
        <div className={cls.row}>
          <Controller
            name='name'
            defaultValue={category?.name}
            control={control}
            render={({
              field: { onChange, onBlur, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <FormInput
                type='text'
                label='Category Name'
                defaultValue={category?.name}
                maxLength={60}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                isTouched={isTouched}
                isDirty={isDirty}
                error={error}
              />
            )}
          />
        </div>

        {/* </form> */}

        <div className={cls.actions}>
          <button onClick={onDeny}>Back</button>
          <button onClick={handleSubmit(onSubmit)}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;
