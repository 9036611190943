import { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  saveHelpQuestion,
  useAllHelpQuestionCategories,
  useAllHelpQuestions,
} from "../../../api/help/helpService";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AppHeader from "../../../components/appHeader/AppHeader";
import MainBtn from "../../../components/common/buttons/mainBtn/MainBtn";
import ConfirmationPopUp from "../../../components/common/confirmationPopUp/ConfirmationPopUp";
import FormInput from "../../../components/common/formInput/FormInput";
import Loading from "../../../components/common/loading/Loading";
import TextareaInput from "../../../components/common/textareaInput/TextareaInput";
import SidebarMenu from "../../../components/sidebarMenu/SidebarMenu";
import { CollapsedSidebarContext } from "../../../context/collapsedSidebarProvider";
import {
  HelpQuestionCategory,
  IHelpQuestion,
} from "../../../interfaces/IHelpQuestion";
import { UrlParams } from "../../../interfaces/UrlParams";
import cls from "./EditQuestion.module.scss";
import CategorySelector from "../../../components/help/categorySelector/CategorySelector";

type FormInputs = {
  title: string;
  answer: string;
};

const schema = yup.object().shape({
  title: yup.string().required("Title is required!"),
  answer: yup.string().required("Answer is required!"),
});

const EditQuestion: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { questionId } = routeParameters;
  const { collapsed } = useContext(CollapsedSidebarContext);

  const { data: allHelpQuestions, isLoading: isLoadingAllHelpQuestions } =
    useAllHelpQuestions();
  const { data: allHelpCategories, isLoading: isLoadingAllHelpCategories } =
    useAllHelpQuestionCategories();

  const [questionData, setQuestionData] = useState<IHelpQuestion>();
  const [categoryData, setCategoryData] = useState<HelpQuestionCategory>();
  const [categorySelector, showCategorySelector] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /////////////// POPUPS ////////////////

  const [successConfirmationUpdated, showSuccessConfirmationUpdated] =
    useState<boolean>(false);

  const [errorConfirmationUpdated, showErrorConfirmationUpdated] =
    useState<boolean>(false);

  const handleSelectCategory = (cat: HelpQuestionCategory) => {
    setCategoryData(cat);
    showCategorySelector(false);
  };

  /////// UPDATE QUESTION //////

  const { handleSubmit, control, formState } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const saveQuestionRQ = useMutation(saveHelpQuestion, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("questions updated successfully!");
      queryClient.invalidateQueries(["help-questions"]);
      showSuccessConfirmationUpdated(true);
      // setButtonDisabled(true);
    },
    onError: (err) => {
      console.log("error question update", err);
      setIsLoading(false);
      showErrorConfirmationUpdated(true);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const onSubmit = (data: FormInputs) => {
    if (formState.isDirty) {
      console.log(data);
      if (questionData && categoryData) {
        const payload: IHelpQuestion = {
          ...questionData,
          title: data.title,
          content: data.answer,
          categoryId: categoryData.id,
        };

        saveQuestionRQ.mutate(payload);
      }
    }
  };

  useEffect(() => {
    if (!allHelpQuestions || !allHelpCategories) return;
    const questionData: IHelpQuestion = allHelpQuestions?.find(
      (question: IHelpQuestion) => question.id === questionId
    );
    setQuestionData(questionData);
    console.log(questionData);

    if (questionData.categoryId === null) {
      setCategoryData({
        id: "null",
        name: "No category",
        isArchived: false,
        orderIndex: Infinity,
      });
      return;
    }

    setCategoryData(
      allHelpCategories.find(
        (cat: HelpQuestionCategory) => cat.id === questionData.categoryId
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allHelpQuestions, allHelpCategories]);

  return (
    <div className={cls.root}>
      {successConfirmationUpdated && (
        <ConfirmationPopUp
          type='success'
          message='Question successfully updated!'
          onClick={() => showSuccessConfirmationUpdated(false)}
        />
      )}

      {errorConfirmationUpdated && (
        <ConfirmationPopUp
          type='error'
          message='Something went wrong! Please try again.'
          onClick={() => showErrorConfirmationUpdated(false)}
        />
      )}

      {categorySelector && categoryData && (
        <CategorySelector
          onSubmit={handleSelectCategory}
          onDeny={() => showCategorySelector(false)}
          defaultValue={categoryData}
          // defaultValue={
          //   categoryData.id !== "null"
          //     ? categoryData
          //     : ({} as HelpQuestionCategory)
          // }
        />
      )}

      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />

        <div className={cls.container}>
          {(isLoadingAllHelpQuestions ||
            isLoadingAllHelpCategories ||
            isLoading) && <Loading />}

          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Help Editor</div>
              <div className={cls.subtitle}>{categoryData?.name}</div>
            </div>
          </div>
          <div className={cls.contents}>
            <div className={cls.form}>
              {questionData && (
                <form action=''>
                  <div className={cls.row}>
                    <Controller
                      name='title'
                      defaultValue={questionData.title}
                      control={control}
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, ref },
                        fieldState: { isTouched, isDirty, error },
                      }) => (
                        <FormInput
                          type='text'
                          label='Question Title'
                          defaultValue={questionData.title}
                          maxLength={600}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          isTouched={isTouched}
                          isDirty={isDirty}
                          error={error}
                        />
                      )}
                    />
                  </div>

                  <div className={cls.row}>
                    <Controller
                      name='answer'
                      defaultValue={questionData.content}
                      control={control}
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, ref },
                        fieldState: { isTouched, isDirty, error },
                      }) => (
                        <TextareaInput
                          label='Answer'
                          defaultValue={questionData.content}
                          maxLength={1100}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          isTouched={isTouched}
                          isDirty={isDirty}
                          error={error}
                        />
                      )}
                    />
                  </div>
                  <div className={cls.row}>
                    <div className={cls.categories}>
                      <div className={cls.selected}>
                        <div className={cls.category}>
                          <div className={cls.body}>
                            <div className={cls.label}>
                              {categoryData?.name}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={cls.add}
                        onClick={() => showCategorySelector(true)}>
                        <div className={cls.body}>
                          <div className={cls.icon}>+</div>
                          <div className={cls.label}>Select Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}

              <div className={cls.actions}>
                <MainBtn
                  label='Back'
                  transparent={true}
                  width={130}
                  height={44}
                  onClick={() => navigate("/help")}
                />

                <MainBtn
                  disabled={!formState.isDirty}
                  label='Save'
                  width={180}
                  height={44}
                  onClick={handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditQuestion;
