import * as diff from "diff";

window.Diff = diff;

const styles = {
  added: {
    color: "#1cc01c",
    textDecoration: "underline",
  },
  removed: {
    color: "#fc5a83",
    textDecoration: "line-through",
  },
};

type Props = {
  text1: string;
  text2: string;
  mode: "characters" | "words" | "lines";
};

const Diff: React.FC<Props> = ({ text1, text2, mode }) => {
  let groups: any;

  if (mode === "characters") groups = diff.diffChars(text1, text2);
  if (mode === "words") groups = diff.diffWords(text1, text2);
  if (mode === "lines") groups = diff.diffLines(text1, text2);

  const mappedNodes = groups.map((group: any, i: number) => {
    const { value, added, removed } = group;
    let nodeStyles;
    if (added) nodeStyles = styles.added;
    if (removed) nodeStyles = styles.removed;
    return (
      <span style={nodeStyles} key={i}>
        {value}
      </span>
    );
  });

  return <span>{mappedNodes}</span>;
};

export default Diff;
