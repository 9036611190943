import cls from "./AddCourseStepper.module.scss";

import React, { useEffect, useState } from "react";

import { IUser } from "../../../interfaces/IUser";

import MainBtn from "../../common/buttons/mainBtn/MainBtn";
import { ICoursePreview } from "../../../interfaces/ICoursePreview";
import AddCourse from "./steps/addCourse/AddCourse";
import AddTier from "./steps/addTier/AddTier";
import AddClass from "./steps/addClass/AddClass";
import Review from "./steps/review/Review";
import { useAllClasses } from "../../../api/class/classService";
import { useAllCourses } from "../../../api/course/courseService";
import { IClassQuery } from "../../../interfaces/IClassQuery";

type Props = {
  onClose: () => void;
  user: IUser;
  onSave: (
    course: ICoursePreview,
    tier: string,
    courseClass: IClassQuery
  ) => void;
};

type Steps = "Add Course" | "Select Tier" | "Add Class" | "Review";

const AddCourseStepper: React.FC<Props> = ({ onClose, user, onSave }) => {
  const { data: allCourses } = useAllCourses();
  const { data: allClasses } = useAllClasses();

  const [step, setStep] = useState<Steps>("Add Course");
  /////
  const [course, setCourse] = useState<ICoursePreview>();
  const [tier, setTier] = useState<string>();
  const [courseClass, setCourseClass] = useState<IClassQuery>();
  /////

  const [availableCourses, setAvailableCourses] = useState<ICoursePreview[]>(
    []
  );
  const [availableClasses, setAvailableClasses] = useState<IClassQuery[]>([]);

  const handleSetCourse = (d: ICoursePreview) => {
    if (!d) return;
    setCourse(d);
    setStep(tier ? "Add Class" : "Select Tier");
    // console.log(d);
  };

  const handleSetTier = (e: React.ChangeEvent<HTMLInputElement>) => {
    // e.preventDefault();
    const val = e.target.value;
    if (!val) return;
    setTier(val);
    setStep("Add Class");
    // console.log(e.target.value);
  };

  const handleSetClass = (d: IClassQuery) => {
    if (!d) return;
    setCourseClass(d);
    setStep("Review");
    // console.log(d);
  };

  const handleNavigateBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    switch (step) {
      case "Select Tier":
        setStep("Add Course");
        break;
      case "Add Class":
        setStep(tier ? "Add Course" : "Select Tier");
        break;
      case "Review":
        setStep("Add Class");
        break;
      default:
        onClose();
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (course && tier && courseClass) onSave(course, tier, courseClass);
  };

  useEffect(() => {
    if (allCourses)
      setAvailableCourses(
        allCourses.filter(
          (course: ICoursePreview) =>
            course.id !==
            user.enrolledCourses?.find((d) => d.courseId === course.id)
              ?.courseId
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCourses]);
  useEffect(() => {
    if (tier && course && allClasses)
      setAvailableClasses(
        allClasses.filter(
          (c: IClassQuery) => c.courseId === course.id && c.serviceTier === tier
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tier, course]);

  return (
    <div className={cls.root}>
      <span className={cls.close} onClick={onClose}>
        &#120;
      </span>
      <div className={`${cls.wrapper}`}>
        <div className={cls.header}>
          <div className={cls.title}>{step}</div>
        </div>
        <div className={cls.body}>
          {availableCourses && step === "Add Course" && (
            <AddCourse
              courses={availableCourses}
              selectedCourseId={course?.id}
              onAddCourse={handleSetCourse}
            />
          )}
          {step === "Select Tier" && (
            <AddTier selectedTier={tier} onAddTier={handleSetTier} />
          )}
          {step === "Add Class" && (
            <AddClass
              classes={availableClasses}
              selectedClassId={courseClass?.classId}
              course={course}
              onAddClass={handleSetClass}
            />
          )}
          {step === "Review" && (
            <Review course={course} courseClass={courseClass} tier={tier} />
          )}
        </div>
        <div className={cls.footer}>
          <MainBtn
            label='Back'
            transparent={true}
            width={130}
            height={44}
            onClick={handleNavigateBack}
          />
          {step === "Review" && (
            <MainBtn
              label='Confirm'
              width={180}
              height={44}
              onClick={handleSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddCourseStepper;
