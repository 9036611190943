import { useContext, useEffect, useState } from "react";
import AppHeader from "../../../components/appHeader/AppHeader";
import Loading from "../../../components/common/loading/Loading";
import SidebarMenu from "../../../components/sidebarMenu/SidebarMenu";
import { CollapsedSidebarContext } from "../../../context/collapsedSidebarProvider";
import cls from "./EditUser.module.scss";
import { useParams } from "react-router-dom";
import { IUser } from "../../../interfaces/IUser";
import {
  useAllUsers,
  useRoles,
  // useTransactions,
} from "../../../api/user/userService";

import Profiledetails from "../../../components/userProfile/settings/details/ProfileDetails";

const EditUser: React.FC = () => {
  const { data: userList, isLoading: isLoadingUserList } = useAllUsers();

  const routeParameters = useParams();
  const { userId } = routeParameters;
  const { collapsed } = useContext(CollapsedSidebarContext);

  const [user, setUser] = useState<IUser | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleClickTab = (index: number) => setActiveTab(index);

  useEffect(() => {
    const thisUser: IUser | null = userList?.find(
      (user: IUser) => user?.id === userId
    );
    setUser(thisUser);
  }, [userList, userId]);

  // const { data: tranzakData } = useTransactions(userId || "");
  const { isLoading: isLoadingUserRoles } = useRoles();

  return (
    <div className={cls.root}>
      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />

        <div className={cls.container}>
          {(isLoadingUserList || isLoadingUserRoles || user === null) && (
            <Loading />
          )}
          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>
                {user?.firstName} {user?.lastName}
              </div>
              <div className={cls.subtitle}>Lorem ipsum dolor amet.</div>
            </div>
          </div>

          <div className={cls.contents}>
            <div className={cls.tabs}>
              <div
                className={`${cls.tab} ${activeTab === 0 ? cls.active : ""}`}
                onClick={() => handleClickTab(0)}>
                Profile Details
              </div>
              <div
                className={`${cls.tab} ${activeTab === 1 ? cls.active : ""}`}
                onClick={() => handleClickTab(1)}>
                Invoices
              </div>
            </div>
            {activeTab === 0 && <>{user && <Profiledetails user={user} />}</>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
