import AppHeader from "../../../components/appHeader/AppHeader";
import SidebarMenu from "../../../components/sidebarMenu/SidebarMenu";
import cls from "./EditClass.module.scss";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { CollapsedSidebarContext } from "../../../context/collapsedSidebarProvider";
import Loading from "../../../components/common/loading/Loading";
import { UrlParams } from "../../../interfaces/UrlParams";
import { useAllClasses } from "../../../api/class/classService";
import { IClassQuery } from "../../../interfaces/IClassQuery";
import ClassDetails from "../../../components/class/classDetails/ClassDetails";
import { useAllUsers } from "../../../api/user/userService";
import { useQuestions } from "../../../api/class/classQuestionService";
import ClassQuestions from "../../../components/class/classQuestions/ClassQuestions";
import { useCourse } from "../../../api/course/courseService";

const EditClass: React.FC = () => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, classId } = routeParameters;

  const { collapsed } = useContext(CollapsedSidebarContext);

  const { data: courseData, isLoading: isLoadingCourseData } = useCourse(
    courseId,
    !!courseId
  );

  const { data: classes, isLoading: isLoadingClasses } = useAllClasses();
  const { data: questions, isLoading: isLoadingQuestions } =
    useQuestions(classId);
  const { isLoading: isLoadingAllUsers } = useAllUsers();

  const [classData, setClassData] = useState<IClassQuery | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleClickTab = (index: number) => setActiveTab(index);

  const classQuestionsCount = questions?.filter(
    (classQuestion: any) =>
      !classQuestion.question.lastAnswer ||
      classQuestion.question.lastAnswer === null ||
      classQuestion.question.lastAnswer?.userRole === "USER"
  ).length;

  useEffect(() => {
    if (classes) {
      // console.log("classes", classes);
      const classData = classes.find(
        (classObj: IClassQuery) => classObj.class.id === classId
      );
      setClassData(classData);
    }
  }, [classes, classId]);

  return (
    <div className={cls.root}>
      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />

        <div className={cls.container}>
          {(isLoadingClasses ||
            isLoadingAllUsers ||
            isLoadingQuestions ||
            isLoadingCourseData) && <Loading />}

          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Class Editor</div>
              <div className={cls.subtitle}>Course: {courseData?.title}</div>
            </div>
          </div>

          <div className={cls.contents}>
            <div className={cls.tabs}>
              <div
                className={`${cls.tab} ${activeTab === 0 ? cls.active : ""}`}
                onClick={() => handleClickTab(0)}>
                Class Details
              </div>
              <div
                className={`${cls.tab} ${activeTab === 1 ? cls.active : ""}`}
                onClick={() => handleClickTab(1)}>
                Class Questions
                {questions && classQuestionsCount > 0 && (
                  <div className={cls.questionCount}>{classQuestionsCount}</div>
                )}
              </div>
            </div>
            {activeTab === 0 && (
              <>{classData && <ClassDetails classData={classData} />}</>
            )}

            {activeTab === 1 && (
              <>{classData && <ClassQuestions questions={questions} />}</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditClass;
