import { IClassQuery } from "../../../../../interfaces/IClassQuery";

import { ICoursePreview } from "../../../../../interfaces/ICoursePreview";
import cls from "./AddClass.module.scss";

type Props = {
  classes: IClassQuery[];
  onAddClass: (d: IClassQuery) => void;
  selectedClassId?: string;
  course?: ICoursePreview;
};

const AddClass: React.FC<Props> = ({
  classes,
  onAddClass,
  selectedClassId,
  course,
}) => {
  return (
    <div className={cls.root}>
      <div className={`${cls.container}`}>
        {classes?.map((c: IClassQuery, index: number) => (
          <div
            className={`${cls.classItem} ${
              c.classId === selectedClassId ? cls.selected : ""
            }`}
            key={index}
            onClick={() => onAddClass(c)}>
            <div className={cls.wrapper}>
              <div className={cls.title}>{c.class.name}</div>

              <div className={cls.row}>
                <div className={cls.label}>Users</div>
                <div className={cls.val}>{c.class.enrolledUsers.length}</div>
              </div>
              <div className={cls.row}>
                <div className={cls.label}>Course</div>
                <div className={cls.val}>{course?.title}</div>
              </div>
              <div className={cls.row}>
                <div className={cls.label}>Service Tier</div>
                <div className={cls.val}>{c.serviceTier}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddClass;
