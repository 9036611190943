import { useQuery, useQueryClient, QueryFunctionContext } from "react-query";
import { IWeek } from "../../interfaces/IWeek";
import { cacheTimes } from "../cacheTimesRQ";
import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../requestApi";
import { v4 as uuidV4 } from "uuid";
import { IVimeoMetadata } from "../../interfaces/IVimeoMetadata";
import VimeoService from "../vimeo/vimeoService";

const fetchWeeks = ({ queryKey }: QueryFunctionContext) => {
  const courseId: string = queryKey[1] as string;
  const termId: string = queryKey[2] as string;
  return requestApi({
    url: ApiEndpoints.GET_WEEKS,
    method: "POST",
    data: { courseId, termId },
  });
};

export const useWeeks = (
  courseId: string,
  termId: string,
  enabled?: boolean
) => {
  const queryClient = useQueryClient();
  return useQuery(["weeks", courseId, termId], fetchWeeks, {
    refetchOnWindowFocus: false,
    enabled: enabled,
    cacheTime: cacheTimes.WEEK_LIST_CACHE,
    staleTime: cacheTimes.WEEK_LIST_STALE,
    initialData: queryClient.getQueryData(["weeks", courseId, termId]),
    select: (data) =>
      data.data.weeks.sort((a: IWeek, b: IWeek) => a.orderIndex - b.orderIndex),
    onError: (err) => {
      console.log(err);
    },
  });
};

const saveWeek = (week: IWeek, courseId: string, termId: string) => {
  return requestApi({
    url: ApiEndpoints.SAVE_WEEK,
    method: "POST",
    data: { ...week, courseId, termId },
  });
};

export type CreateNewWeekPayload = {
  title: string;
  overview: string;
  homeworkDescription: string;
  courseId: string;
  termId: string;
  orderIndex: number;
  metadata: IVimeoMetadata;
};

export const createNewWeek = async (payload: CreateNewWeekPayload) => {
  const id: string = uuidV4();
  const homeworkId: string = uuidV4();
  const scheduleId: string = uuidV4();
  const homeworkScheduleId: string = uuidV4();

  // create video folder
  const folderUri: string = (
    await VimeoService.CreateFolder(id, payload.metadata.folderUri)
  ).folderUri;

  const newWeek: IWeek = {
    id,
    orderIndex: payload.orderIndex,
    title: payload.title,
    overview: payload.overview,
    homeworkDescription: payload.homeworkDescription,
    homeworkId,
    scheduleId,
    homeworkScheduleId,
    homeworkDeadlineUnixTimestamp: new Date().getTime(),
    startTimestamp: new Date().getTime(),
    endTimestamp: new Date().getTime(),
    metadata: {
      folderUri,
    },
  };

  // save week
  await saveWeek(newWeek, payload.courseId, payload.termId);
};

export type SwitchWeekOrderPayload = {
  week1: IWeek;
  week2: IWeek;
  courseId: string;
  termId: string;
};
export const switchWeekOrder = async (payload: SwitchWeekOrderPayload) => {
  await Promise.all([
    await saveWeek(payload.week1, payload.courseId, payload.termId),
    await saveWeek(payload.week2, payload.courseId, payload.termId),
  ]);
};

export type UpdateWeekPayload = {
  week: IWeek;
  courseId: string;
  termId: string;
};

export const updateWeek = async (payload: UpdateWeekPayload) => {
  // save week
  await saveWeek(payload.week, payload.courseId, payload.termId);
};

type DeleteWeekPayload = {
  weekId: string;
  courseId: string;
  termId: string;
};

export const deleteWeek = (payload: DeleteWeekPayload) => {
  return requestApi({
    url: ApiEndpoints.DELETE_WEEK,
    method: "POST",
    data: payload,
  });
};
