import { useQuery, useQueryClient, QueryFunctionContext } from "react-query";
import { IServiceTier } from "../../interfaces/IServiceTier";
import { cacheTimes } from "../cacheTimesRQ";

import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../requestApi";

const fetchCourseTiers = ({ queryKey }: QueryFunctionContext) => {
  const courseId: string = queryKey[1] as string;
  return requestApi({
    url: ApiEndpoints.GET_COURSE_TIERS,
    method: "POST",
    data: { courseId: courseId },
  });
};

export const useCourseTiers = (courseId: string, enabled?: boolean) => {
  const queryClient = useQueryClient();

  return useQuery(["tiers", courseId], fetchCourseTiers, {
    cacheTime: cacheTimes.COURSE_TIER_CACHE,
    staleTime: cacheTimes.COURSE_TIER_STALE,
    initialData: queryClient.getQueryData(["tiers", courseId]),
    refetchOnWindowFocus: false,
    enabled: enabled,
    select: (data) => data.data.tiers,
    onError: (err) => {
      console.log(err);
    },
  });
};

export const saveCourseTier = (tier: IServiceTier, courseId: string) => {
  return requestApi({
    url: ApiEndpoints.SAVE_MODULE_TIER,
    method: "POST",
    data: { ...tier, courseId },
  });
};
