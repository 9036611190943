import React, { useContext, useEffect, useState } from "react";
import {
  fetchHistoryLogs,
  IChangeLogRecord,
  // IFiltering,
  ISorting,
} from "../../api/history/historyService";
import AppHeader from "../../components/appHeader/AppHeader";
import Loading from "../../components/common/loading/Loading";
import SearchInput from "../../components/common/searchInput/SearchInput";
import ChangeLogRecord from "../../components/history/changeLogRecord/ChangeLogRecord";
import ResultsPerPageSelector from "../../components/history/resultsPerPageSelector/ResultsPerPageSelector";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";
import { CollapsedSidebarContext } from "../../context/collapsedSidebarProvider";
import cls from "./History.module.scss";

// const _filtering: IFiltering[] = [
//   {
//     field: "actionType",
//     condition: "!=",
//     value: "SAVE_USER_PROGRESS",
//   },
// ];

const _sorting: ISorting = {
  field: "createdAtTimestamp",
  order: "desc",
};

const History: React.FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [resultsPerPage, setResultsPerPage] = useState<number>(5);
  const [historyLogs, setHistoryLogs] = useState<IChangeLogRecord[]>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalpages, setTotalPages] = useState<number>(0);
  const [filter, setFilter] = useState<string>("");

  const { collapsed } = useContext(CollapsedSidebarContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getHistoryLogs = async (pgNr: number, pgSize: number) => {
    try {
      const data = await fetchHistoryLogs({
        pagination: {
          page: pgNr,
          pageSize: pgSize,
        },
        sorting: _sorting,
        // filtering: _filtering,
        includeCount: true,
      });
      setHistoryLogs(data.data.records);
      setTotalCount(data.data.count);
      setTotalPages(Math.ceil(data.data.count / pgSize));
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handlePreviousPage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (pageNumber === 1) return;
    // setFilter("");
    const newPgNr = pageNumber - 1;
    setPageNumber(newPgNr);
    await getHistoryLogs(newPgNr, resultsPerPage);
  };

  const handleNextPage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (pageNumber === totalpages) return;
    // setFilter("");
    const newPgNr = pageNumber + 1;
    setPageNumber(newPgNr);
    await getHistoryLogs(newPgNr, resultsPerPage);
  };

  const handleResultsPerPage = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const val = parseInt(e.target.value);
    // setFilter("");
    setResultsPerPage(val);
    setPageNumber(1);

    await getHistoryLogs(1, val);
  };

  const matchesFilter = (log: IChangeLogRecord, filter: string) => {
    return (
      log.userId.toLowerCase().includes(filter.toLowerCase()) ||
      log.id.toLowerCase().includes(filter.toLowerCase()) ||
      log.actionType.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const handleFilterLogs = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const text = e.target.value;
    console.log(text);
    setFilter(text);
  };

  useEffect(() => {
    let isActive = true;

    if (isActive) {
      setIsLoading(true);
      getHistoryLogs(pageNumber, resultsPerPage);
      setIsLoading(false);
    }

    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={cls.root}>
      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />
        <div className={cls.container}>
          {isLoading && <Loading />}
          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>History</div>
              <div className={cls.subtitle}>Event history log.</div>
            </div>
            <SearchInput
              customPlaceholder={"Search by name / number"}
              width={320}
              height={55}
              onValueChange={handleFilterLogs}
            />
          </div>
          {historyLogs?.length === 0 && (
            <p
              style={{
                textAlign: "center",
                color: "white",
                padding: "30px",
                fontSize: "1.5rem",
              }}>
              No history logs found!
            </p>
          )}
          {historyLogs && (
            <>
              <ResultsPerPageSelector
                defaultValue={resultsPerPage}
                onChange={handleResultsPerPage}
              />
              <div className={cls.contents}>
                {(filter.length > 2
                  ? historyLogs.filter((u: IChangeLogRecord) =>
                      matchesFilter(u, filter)
                    )
                  : historyLogs
                )?.map((record: IChangeLogRecord, index: number) => {
                  // if (record.actionType === "SAVE_USER_PROGRESS") return;
                  return <ChangeLogRecord record={record} key={record.id} />;
                })}
              </div>
              <div className={cls.pagination}>
                <div>
                  <span>
                    {pageNumber * resultsPerPage - resultsPerPage + 1}
                  </span>{" "}
                  -{" "}
                  <span>
                    {pageNumber * resultsPerPage > totalCount
                      ? totalCount
                      : pageNumber * resultsPerPage}
                  </span>{" "}
                  from <span>{totalCount}</span> History Logs
                </div>
                <div>
                  <button
                    disabled={pageNumber === 1}
                    onClick={handlePreviousPage}>
                    Previous
                  </button>
                  <div>
                    {pageNumber} ... {totalpages}
                  </div>

                  <button
                    disabled={pageNumber === totalpages}
                    onClick={handleNextPage}>
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default History;
