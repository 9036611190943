import cls from "./AppHeader.module.scss";
import { useAdminUser, useAllUsers } from "../../api/user/userService";
import ProfileImage from "../common/profileImage/ProfileImage";
import { useRef, useState } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { useQueryClient } from "react-query";
import { auth } from "../../api/firebase";

const AppHeader: React.FC = () => {
  const { data: adminUser } = useAdminUser();
  useAllUsers();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [menu, showMenu] = useState<boolean>(false);
  const menuRef = useRef(null);

  const handleClickOutside = (e: MouseEvent) => {
    showMenu(false);
  };
  useOnClickOutside(menuRef, handleClickOutside);

  const handleLogout = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    await signOut(auth)
      .then(() => {
        navigate("/login");
        queryClient.clear();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={cls.root}>
      <div className={cls.user} onClick={() => showMenu(!menu)} ref={menuRef}>
        <div className={cls.avatar}>
          <ProfileImage
            size={38}
            rounded={false}
            imgUrl={adminUser?.avatarUrl || "/user-avatar.webp"}
          />
        </div>

        <div className={cls.info}>
          <div className={cls.name}>{`${adminUser?.firstName || "First name"} ${
            adminUser?.lastName || "Last name"
          }`}</div>
          <div className={cls.role}>{adminUser?.role.name || "ROLE"}</div>
        </div>
        {menu && (
          <div className={cls.menu}>
            <div
              className={cls.settings}
              onClick={() => navigate(`/users/${adminUser.id}`)}>
              <span className={cls.icon}>&#9861;</span> Account
            </div>
            <div className={cls.logout} onClick={handleLogout}>
              <span className={cls.icon}>&#8612;</span> Log Out
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppHeader;
