import { ChangeEvent, useRef, useState } from "react";
import "./MessageSubmitter.scss";
import EmojiPicker, {
  EmojiStyle,
  Theme,
  EmojiClickData,
  SuggestionMode,
} from "emoji-picker-react";

import useOnClickOutside from "../../../hooks/useOnClickOutside";

import badWords from "bad-words";

type Props = {
  emojiEnabled: boolean;
  submitMessage: (textToSubmit: string) => void;
};

const MessageSubmitter: React.FC<Props> = ({ emojiEnabled, submitMessage }) => {
  const cleanMessage = new badWords();
  const [userMessage, setUserMessage] = useState<string>("");
  const rootRef = useRef<HTMLDivElement>(null);
  const [showEmojiSelector, setShowEmojiSelector] = useState<boolean>(false);
  const [cursorPos, setCursorPos] = useState<number>(0);

  const onChatTextInputValueChanged = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const text: string = event.target.value;
    setCursorPos(event.currentTarget.selectionStart || 0);
    setUserMessage(text);
  };

  const updateSelectionStart = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    setCursorPos(e.currentTarget.selectionStart || 0);
  };

  function onClickEmoji(emojiData: EmojiClickData, event: MouseEvent) {
    const newVal =
      userMessage.slice(0, cursorPos) +
      emojiData.emoji +
      userMessage.slice(cursorPos);
    setUserMessage(newVal);
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const handleSubmitMessage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setShowEmojiSelector(false);
    submitMessage(cleanMessage.clean(userMessage));
    setUserMessage("");
  };
  const handleClickOutsideMenu = (e: MouseEvent) => {
    setShowEmojiSelector(false);
  };
  useOnClickOutside(rootRef, handleClickOutsideMenu);
  return (
    <div className='message-submitter' ref={rootRef}>
      {showEmojiSelector && (
        <EmojiPicker
          onEmojiClick={onClickEmoji}
          autoFocusSearch={false}
          theme={Theme.DARK}
          height={300}
          width='100%'
          emojiVersion='5.0'
          lazyLoadEmojis={true}
          previewConfig={{
            showPreview: false,
          }}
          suggestedEmojisMode={SuggestionMode.RECENT}
          skinTonesDisabled
          searchPlaceHolder='Search your emoji'
          emojiStyle={EmojiStyle.NATIVE}
        />
      )}

      <form action='' onSubmit={handleSubmitMessage}>
        {emojiEnabled && (
          <span
            className='emojiShow'
            onClick={(e) => setShowEmojiSelector(!showEmojiSelector)}>
            &#x1F600;
          </span>
        )}

        <input
          type='text'
          value={userMessage}
          maxLength={1100}
          placeholder='Send a message'
          onClick={updateSelectionStart}
          onKeyDown={handleKeyPress}
          onChange={onChatTextInputValueChanged}></input>

        <button type='submit' className='submitBtn'>
          <svg
            data-v-77e9226e=''
            aria-hidden='true'
            focusable='false'
            data-prefix='fas'
            data-icon='paper-plane'
            role='img'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 512 512'>
            <path
              data-v-77e9226e=''
              fill='white'
              d='M511.6 36.86l-64 415.1c-1.5 9.734-7.375 18.22-15.97 23.05c-4.844 2.719-10.27 4.097-15.68 4.097c-4.188 0-8.319-.8154-12.29-2.472l-122.6-51.1l-50.86 76.29C226.3 508.5 219.8 512 212.8 512C201.3 512 192 502.7 192 491.2v-96.18c0-7.115 2.372-14.03 6.742-19.64L416 96l-293.7 264.3L19.69 317.5C8.438 312.8 .8125 302.2 .0625 289.1s5.469-23.72 16.06-29.77l448-255.1c10.69-6.109 23.88-5.547 34 1.406S513.5 24.72 511.6 36.86z'></path>
          </svg>
        </button>
      </form>
    </div>
  );
};

export default MessageSubmitter;
