import { useQuery, useQueryClient } from "react-query";
import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../requestApi";
import { v4 as uuidV4 } from "uuid";
import { cacheTimes } from "../cacheTimesRQ";
import { ICourseClass } from "../../interfaces/ICourseClass";
import { EServiceTier } from "../../interfaces/IServiceTier";

const fetchClasses = () => {
  return requestApi({
    url: ApiEndpoints.GET_ALL_CLASSES,
    method: "post",
    data: {},
  });
};
export const useAllClasses = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["all-classes"], fetchClasses, {
    cacheTime: cacheTimes.CLASS_LIST_DATA_CACHE,
    staleTime: cacheTimes.CLASS_LIST_DATA_STALE,
    initialData: queryClient.getQueryData("all-classes"),
    refetchOnWindowFocus: false,
    enabled: enabled,
    select: (data) => data.data.classes,
    onError: (err) => {
      console.log(err);
    },
  });
};

export const saveClass = (classData: ICourseClass) => {
  return requestApi({
    url: ApiEndpoints.SAVE_CLASS,
    method: "post",
    data: classData,
  });
};

export type CreateNewClassPayload = {
  name: string;
  courseId: string;
};

export const createNewClass = async (payload: CreateNewClassPayload) => {
  const id: string = uuidV4();
  const serviceTier: EServiceTier = EServiceTier.STANDARD;

  const newClass: ICourseClass = {
    id,
    name: payload.name,
    courseId: payload.courseId,
    tier: serviceTier,
    enrolledUsers: [],
    instructors: [],
    scheduledQAs: {},
  };

  // save class
  await saveClass(newClass);
};

export type DeleteClassPayload = {
  classId: string;
  courseId: string;
  serviceTier: EServiceTier;
};

export const deleteClass = (payload: DeleteClassPayload) => {
  return requestApi({
    url: ApiEndpoints.DELETE_CLASS,
    method: "POST",
    data: payload,
  });
};
