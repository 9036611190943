import { useEffect, useState } from "react";
import cls from "./FormInput.module.scss";
import { FieldError } from "react-hook-form";

type Props = {
  type: "text" | "email" | "password" | "date";
  label?: string;
  width?: number;
  height?: number;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  maxLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isTouched?: boolean;
  isDirty?: boolean;
  error?: FieldError | undefined;
};

const FormInput: React.FC<Props> = ({
  type,
  width,
  height,
  defaultValue,
  label,
  placeholder,
  disabled,
  id,
  inputRef,
  maxLength,
  onChange,
  onBlur,
  isTouched,
  isDirty,
  error,
}) => {
  const [defaultVal, setDefaultVal] = useState<string>();

  useEffect(() => {
    setDefaultVal(defaultValue || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);
  return (
    <div className={cls.root} style={{ width: width, height: height }}>
      {!placeholder && <label className={cls.label}>{label}</label>}

      <input
        id={id}
        type={type}
        ref={inputRef}
        maxLength={maxLength}
        defaultValue={defaultVal}
        className={cls.input}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />

      <p className={cls.error}>{`${error ? error.message : ""}`}</p>
    </div>
  );
};

export default FormInput;
