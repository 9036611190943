import { useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import QuillEditor from "../../common/quillEditor/QuillEditor";
import cls from "./WeekRichTextEditor.module.scss";

type Props = {
  width?: number;
  height?: number;
  label?: string;
  data: string;
  onChange: (e: string) => void;
  error?: FieldError | undefined;
};

const WeekRichTextEditor: React.FC<Props> = ({
  width,
  height,
  label,
  data,
  onChange,
  error,
}) => {
  const [html, setHtml] = useState<string>(data);

  // handle edit content
  const handleEditContent = (content: string) => {
    setHtml(content);
  };

  const toolbarContainer = [
    ["bold", "italic", "underline"], // toggled buttons
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
  ];

  useEffect(() => {
    onChange(html);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html]);

  return (
    <div className={cls.root} style={{ width: width, height: height }}>
      <div className={cls.wrapper}>
        <div className={cls.row}>
          <QuillEditor
            label={label}
            toolbarContainer={toolbarContainer}
            value={html}
            onChange={handleEditContent}
          />
        </div>
      </div>

      <p className={cls.error}>{`${error ? error.message : ""}`}</p>
    </div>
  );
};

export default WeekRichTextEditor;
