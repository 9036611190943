import { useEffect, useRef, useState } from "react";

import { useRoles } from "../../api/user/userService";
import Icon from "../../assets/icons/Icon";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { IRole } from "../../interfaces/IRole";
import cls from "./RolePicker.module.scss";
import { FieldError } from "react-hook-form";

type Props = {
  width?: number;
  height?: number;
  defaultValue: string;
  loggedUserRole: string | undefined | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: FieldError | undefined;
};

const RolePicker: React.FC<Props> = ({
  width,
  height,
  onChange,
  error,
  defaultValue,
  loggedUserRole,
}) => {
  const { data: userRoles } = useRoles();

  const [roleList, setRoleList] = useState<IRole[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>(defaultValue);

  const [active, setIsActive] = useState<boolean>(false);

  const refField = useRef(null);

  const handleSwitchActive = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsActive(!active);
  };

  const handleClickOutsideMenu = (e: MouseEvent) => {
    setIsActive(false);
  };
  useOnClickOutside(refField, handleClickOutsideMenu);

  const handleRoleChange = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    const val = e.currentTarget.value;
    if (val === selectedRole) return;
    console.log(val);
    setSelectedRole(val);
    setIsActive(false);
  };

  const canAssignRole = (targetRole: string) => {
    if (!loggedUserRole) return;

    if (loggedUserRole === "OWNER") return true;

    return loggedUserRole === "ADMIN" && targetRole === "USER";
  };

  useEffect(() => {
    let list: IRole[] = [];
    if (userRoles) for (const role of userRoles) list.push(role);
    setRoleList(list);
  }, [userRoles]);

  return (
    <div
      className={cls.root}
      style={{ width: width, height: height }}
      ref={refField}>
      <div className={cls.select} onClick={handleSwitchActive}>
        <div className={cls.value}>{selectedRole}</div>
        <Icon
          icon='ArrowLeft'
          viewBox='0 0 320 512'
          size={18}
          className={active ? cls.expanded : ""}
        />
      </div>
      <label className={cls.label}>Role*</label>
      <div className={`${cls.options} ${active ? cls.show : ""}`}>
        {roleList?.map((role: IRole, index: number) => {
          return (
            <div
              className={`${cls.option} ${
                role.name === selectedRole ? cls.selected : ""
              } ${!canAssignRole(role.name) ? cls.disabled : ""}`}
              key={index}>
              <label htmlFor={role.name}>{role.name}</label>
              <input
                type='radio'
                name='role'
                id={role.name}
                hidden
                value={role.name}
                disabled={!canAssignRole(role.name)}
                onClick={handleRoleChange}
                onChange={onChange}
              />
            </div>
          );
        })}
      </div>
      <p className={cls.error}>{`${error ? error.message : ""}`}</p>
    </div>
  );
};

export default RolePicker;
