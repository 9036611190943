import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import {
  EEntityType,
  IChange,
  IChangeLogRecord,
  useLogData,
} from "../../../api/history/historyService";
import { fetchPublicUser } from "../../../api/user/userService";
import Icon from "../../../assets/icons/Icon";
import { IUser } from "../../../interfaces/IUser";
import cls from "./ChangeLogRecord.module.scss";

import {
  detailedDiff,
  // diff,
  //   addedDiff,
  //   deletedDiff,
  //   updatedDiff,
  //   detailedDiff,
} from "deep-object-diff";

import Diff from "../../common/diffText/DiffText";

interface IFormattedLogData {
  before: { [prop: string]: any };
  after: { [prop: string]: any };
  entityId: string;
  entityType: EEntityType;
  documentPath: string;
}

type Props = { record: IChangeLogRecord };

const excludedChangeKeys = [
  "featuresFlagMap",
  "metadata",
  "scheduledQAs",
  "meetings",
  "messageHistory",
  "scheduleId",
  "progress",
];

const ChangeLogRecord: React.FC<Props> = ({ record }) => {
  const { data: logData, isLoading: isLoadingLogData } = useLogData(record.id);

  const [userProfile, setUserProfile] = useState<IUser | null>(null);
  const [formattedLogData, setFormattedLogData] = useState<IFormattedLogData[]>(
    []
  );

  const [expandedDetails, setExpandedDetails] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const loadUserProfile = async (userId: string) => {
    if (!userId) return;
    setIsLoading(true);
    const userList: any = queryClient.getQueryData(["user-list"]);

    if (userList) {
      const currentUser = userList.data.users.find(
        (user: IUser) => user.id === userId
      );

      setUserProfile(currentUser);
    } else {
      const user = await fetchPublicUser(userId);

      setUserProfile(user.data.user);
    }
    setIsLoading(false);
  };

  const loadFormattedLogData = (recordChanges: IChange[]) => {
    if (!logData) return;
    setIsLoading(true);
    let formattedData: IFormattedLogData[] = [];

    recordChanges.forEach((change: IChange) => {
      console.log("%c...before...", "color: red", change.before);
      console.log("%c...after...", "color:red", change.after);
      console.log(
        "%c...diff...",
        "color:yellow",
        detailedDiff(change.before || {}, change.after || {})
      );

      if (!change.before && !change.after) return;
      if (isSameObject(change.before, change.after)) return;

      let newBefore: { [key: string]: any } = {},
        newAfter: { [key: string]: any } = {};

      if (!change.before && change.after) {
        // newBefore = {};
        newAfter = change.after;
      } else if (change.before && !change.after) {
        newBefore = change.before;
        // newAfter = {};
      } else if (change.before && change.after) {
        const keysBefore = Object.keys(change.before),
          keysAfter = Object.keys(change.after);

        for (const key of keysAfter) {
          // TO DO => REFINE THIS LOGIC

          // check if key exists in both objects
          if (!keysBefore.includes(key)) {
            newBefore[key] = null;
            newAfter[key] = change.after[key];
          } else {
            // check if both values are objects
            if (
              typeof change.before[key] === "object" &&
              typeof change.after[key] === "object"
            ) {
              // check if both objects are equal
              if (!isSameObject(change.before[key], change.after[key])) {
                newBefore[key] = change.before[key];
                newAfter[key] = change.after[key];
              }

              // check if both values are arrays
            } else if (
              Array.isArray(change.before[key]) &&
              Array.isArray(change.after[key])
            ) {
              if (isSameArray(change.before[key], change.after[key])) {
                newBefore[key] = change.before[key];
                newAfter[key] = change.after[key];
              }
              // check if values are equal
            } else {
              if (change.before[key] !== change.after[key]) {
                newBefore[key] = change.before[key];
                newAfter[key] = change.after[key];
              }
            }
          }
        }
      }

      newBefore = removePropsFromObject(excludedChangeKeys, newBefore);
      newAfter = removePropsFromObject(excludedChangeKeys, newAfter);

      if (!isSameObject(newBefore, newAfter))
        formattedData.push({ ...change, before: newBefore, after: newAfter });
    });

    setFormattedLogData(formattedData);
    setIsLoading(false);
  };

  const handleExpandDetails = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setExpandedDetails(!expandedDetails);
  };

  const timeFormatter = (tm: number) => {
    const d = new Date(tm);
    const output =
      d.getDate() +
      "/" +
      (d.getMonth() + 1) +
      "/" +
      d.getFullYear() +
      " at " +
      d.getHours() +
      ":" +
      d.getMinutes();
    return output.toString();
  };

  const isSameArray = (array1: Array<any>, array2: Array<any>) => {
    // console.log("comparing values of array type...");
    if (!Array.isArray(array1) || !Array.isArray(array2)) {
      return false;
    }
    if (array1.length !== array2.length) {
      return false;
    }
    for (let i = 0; i < array1.length; i++) {
      const item1 = array1[i];
      const item2 = array2[i];

      if (Array.isArray(item1) && Array.isArray(item2)) {
        if (!isSameArray(item1, item2)) {
          return false;
        }
      } else if (typeof item1 === "object" && typeof item2 === "object") {
        if (!isSameObject(item1, item2)) {
          return false;
        }
      } else if (item1 !== item2) {
        return false;
      }
    }
    return true;
  };

  const isSameObject = (
    a: { [key: string]: any } | null,
    b: { [key: string]: any } | null
  ) => {
    // console.log("comparing before and after onjects...");
    if (a === b) return true;

    if (
      typeof a !== "object" ||
      typeof b !== "object" ||
      a === null ||
      b === null
    )
      return false;

    const keysA = Object.keys(a),
      keysB = Object.keys(b);

    if (keysA.length !== keysB.length) return false;

    if (keysA.length === 0 && keysB.length === 0) return true;

    for (const key of keysA) {
      // check if key exists in both objects
      if (!keysB.includes(key)) return false;
      // check if value is same type
      if (typeof a[key] !== typeof b[key]) return false;
      // check if value is function
      if (typeof a[key] === "function" || typeof b[key] === "function") {
        if (a[key].toString() !== b[key].toString()) return false;
        //check if value is array
      } else if (Array.isArray(a[key]) || Array.isArray(b[key])) {
        if (!isSameArray(a[key], b[key])) return false;
      } else {
        if (!isSameObject(a[key], b[key])) return false;
      }
    }

    return true;
  };

  const removePropsFromObject = (
    props: string[],
    obj: { [prop: string]: any }
  ) => {
    const newObj = { ...obj };
    props.forEach((prop) => delete newObj[prop]);
    return newObj;
  };

  const renderDiffHtml = (recordChanges: IFormattedLogData[]) => {
    if (recordChanges.length === 0)
      return <div style={{ textAlign: "center" }}>No changes found!</div>;

    return (
      <table>
        {/* HEADER */}
        <thead>
          <tr>
            <th>Entity</th>
            <th>Before</th>
            <th>After</th>
          </tr>
        </thead>
        {/* CONTENT */}
        <tbody>
          {recordChanges.map((change: IFormattedLogData, index: number) => {
            return (
              <tr key={index}>
                {/* entity column */}

                <td>{change.entityType}</td>

                {/* before column */}
                <td>
                  {Object.keys(change.before).map((prop: string, i: number) => {
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}>
                        {renderChangedData(prop, change.before[prop])}
                      </div>
                    );
                  })}
                </td>
                {/* after column */}
                <td>
                  {Object.keys(change.after).map((prop: string, i: number) => {
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}>
                        {renderChangedData(
                          prop,
                          change.after[prop],
                          change.before[prop]
                        )}
                      </div>
                    );
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderChangedData = (prop: string, value: any, comparedTo?: any) => {
    // TODO GLOBAL STYLing
    switch (prop) {
      case "description":
      case "title":
      case "name":
        return (
          <>
            {value !== null && value !== undefined && (
              <>
                <div style={{ color: "grey", padding: "10px 0" }}>{prop}:</div>
                {!comparedTo && <div>{JSON.stringify(value)}</div>}

                {comparedTo && (
                  <div>
                    {<Diff text1={comparedTo} text2={value} mode='words' />}
                  </div>
                )}
              </>
            )}
          </>
        );

      case "thumbnailUrl":
      case "thumbnailSource":
        return (
          <>
            {value !== null && value !== undefined && (
              <>
                <div style={{ color: "grey", padding: "10px 0" }}>{prop}:</div>
                <img
                  src={value}
                  alt=''
                  style={{
                    width: "100%",
                    height: 200,
                    objectFit: "cover",
                    borderRadius: 12,
                  }}
                />
              </>
            )}
          </>
        );

      case "avatarUrl":
        return (
          <>
            {value !== null && value !== undefined && (
              <>
                <div style={{ color: "grey", padding: "10px 0" }}>{prop}:</div>
                <img
                  src={value}
                  alt=''
                  style={{
                    width: 120,
                    height: 120,
                    objectFit: "cover",
                    borderRadius: 12,
                  }}
                />
              </>
            )}
          </>
        );

      case "startTimestamp":
      case "endTimestamp":
      case "unixTimestamp":
      case "timestamp":
        return (
          <>
            {value !== null && value !== undefined && (
              <>
                <div style={{ color: "grey", padding: "10px 0" }}>{prop}:</div>
                <div>{timeFormatter(value)}</div>
              </>
            )}
          </>
        );
      case "role":
        return (
          <>
            {value !== null && value !== undefined && (
              <>
                <div style={{ color: "grey", padding: "10px 0" }}>{prop}:</div>
                <div>{value.name}</div>
              </>
            )}
          </>
        );
      case "birthday":
        return (
          <>
            {value !== null && value !== undefined && (
              <>
                <div style={{ color: "grey", padding: "10px 0" }}>{prop}:</div>
                <div>{`${value.day}/${value.month}/${value.year}`}</div>
              </>
            )}
          </>
        );

      case "reference":
        return (
          <>
            {value !== null && value !== undefined && (
              <>
                <div style={{ color: "grey", padding: "10px 0" }}>{prop}:</div>
                <div>
                  {value.map((ref: { [key: string]: any }, i: number) => (
                    <div
                      style={{ borderBottom: "1px solid #3a3939" }}
                      key={i}
                      dangerouslySetInnerHTML={{ __html: ref.html }}></div>
                  ))}
                </div>
              </>
            )}
          </>
        );

      default:
        return (
          <>
            {value !== null && value !== undefined && (
              <>
                <div style={{ color: "grey", padding: "10px 0" }}>{prop}:</div>
                <div>{JSON.stringify(value)}</div>
              </>
            )}
          </>
        );
    }
  };
  ///////
  useEffect(() => {
    if (record) loadUserProfile(record.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  useEffect(() => {
    if (logData) loadFormattedLogData(logData.changes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logData]);

  if (isLoading || isLoadingLogData)
    return (
      <div className={`${cls.root} ${cls.loader}`}>
        <div className={cls.header}></div>
      </div>
    );

  return (
    <div className={cls.root}>
      <div
        className={`${cls.header} ${expandedDetails ? cls.expanded : ""}`}
        onClick={handleExpandDetails}>
        <span className={cls.userName}>{`${
          userProfile ? userProfile.firstName : "AUTOMATED"
        } ${userProfile ? userProfile.lastName : "SYSTEM"}`}</span>
        <span className={cls.userRole}>
          {userProfile ? userProfile.role?.name : "-"}
        </span>
        <span className={cls.userEmail}>
          {userProfile ? userProfile.email : "-"}
        </span>
        <span className={cls.usePhoneNr}>
          {userProfile ? userProfile.phoneNumber : "-"}
        </span>

        <span className={cls.lastUpdate}>
          {timeFormatter(record.createdAtTimestamp)}
        </span>
        <span className={cls.recordId}>{record.id.substring(0, 8)}</span>
        <span className={cls.recordCategory}>{record.actionType}</span>
        <span className={cls.icon}>
          <Icon icon='ArrowLeft' className={cls.arrow} viewBox='0 0 320 512' />
        </span>
      </div>
      {expandedDetails && (
        <div className={cls.body} style={{ padding: "0 20px 20px 20px" }}>
          {renderDiffHtml(formattedLogData)}
        </div>
      )}
    </div>
  );
};

export default ChangeLogRecord;
