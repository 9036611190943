import { ICourseReference } from "./ICourseReference";
import { ILecturerDetails } from "./ILecturerDetails";
import { EServiceTier } from "./IServiceTier";
import { ICourseSyllabus } from "./ICourseSyllabus";

export interface ICoursePreview {
  id: string;
  title: string;
  description: string;
  thumbnailUrl: string;
  lecturerIds: string[];
  scheduleId: string;
  startsAtUnixTimestamp: number;
  endsAtUnixTimestamp: number;
  year: number;
  metadata: {
    folderUri: string;
    isLocked: boolean;
  };
  reference: ICourseReference[];
  syllabus: ICourseSyllabus[];
  lecturers?: ILecturerDetails[];
  featuresFlagMap: Record<EServiceTier, EFeatureFlag[]>;
  orderIndex: number;
}

export enum EFeatureFlag {
  HOMEWORKS = "HOMEWORKS",
  ASSIGNMENT_DESCRIPTION = "ASSIGNMENT_DESCRIPTION",
  STUDENT_LIST = "STUDENT_LIST",
  LIVE_QA = "LIVE_QA",
}

export interface CourseCreationData {
  title: string;
  description: string;
  lecturerIds?: string[];
  year: number;
  startingDate?: Date | null;
  endingDate?: Date | null;
  backgroundImage: File | null;
  featuresFlagMap: Record<EServiceTier, EFeatureFlag[]>;
  reference?: ICourseReference[];
  syllabus?: ICourseSyllabus[];
  orderIndex: number;
}
