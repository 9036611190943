import cls from "./StudentPicker.module.scss";

import React, { useEffect, useState } from "react";

import SearchInput from "../../common/searchInput/SearchInput";
import { IUser } from "../../../interfaces/IUser";
import { useAllUsers } from "../../../api/user/userService";
import MainBtn from "../../common/buttons/mainBtn/MainBtn";
import { IClassQuery } from "../../../interfaces/IClassQuery";

type Props = {
  onClose?: () => void;
  enrolledStudents: IUser[];
  classData: IClassQuery;
  onAddStudents: (activeStudents: IUser[]) => void;
};

const StudentPicker: React.FC<Props> = ({
  onClose,
  enrolledStudents,
  classData,
  onAddStudents,
}) => {
  const { data: allUsers } = useAllUsers();
  const [studentList, setStudentList] = useState<IUser[]>([]);
  const [activeStudents, setActiveStudents] =
    useState<IUser[]>(enrolledStudents);
  const [filter, setFilter] = useState<string>("");

  const handleFilterUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const text = e.target.value;
    setFilter(text);
  };

  const isUserEnrolled = (userId: string): boolean => {
    const enrolled: IUser | undefined = activeStudents.find(
      (student: IUser) => student.id === userId
    );
    return enrolled ? true : false;
  };

  const matchesFilter = (user: IUser, filter: string) => {
    return (
      user.email.toLowerCase().includes(filter.toLowerCase()) ||
      user.firstName?.toLowerCase().includes(filter.toLowerCase()) ||
      user.lastName?.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const handleSelectStudent = (e: React.ChangeEvent<HTMLInputElement>) => {
    // e.preventDefault();
    const studentId = e.target.value;
    const checked = e.target.checked;

    if (checked)
      setActiveStudents([
        ...activeStudents,
        allUsers.find((student: IUser) => student.id === studentId),
      ]);

    if (!checked)
      setActiveStudents(
        activeStudents.filter((student: IUser) => student.id !== studentId)
      );
  };

  useEffect(() => {
    if (allUsers) {
      const studentList: IUser[] = [
        ...allUsers?.filter(
          (user: IUser) =>
            user.role.name === "USER" &&
            !user.isDisabled &&
            !user.enrolledCourses?.some(
              (obj) => obj.courseId === classData.courseId
            )
        ),
        ...allUsers?.filter(
          (user: IUser) =>
            user.enrolledCourses?.some(
              (obj) => obj.classId === classData.classId
            ) && user.role.name === "USER"
        ),
      ];

      setStudentList(studentList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers, classData]);

  return (
    <div className={cls.root}>
      <span className={cls.close} onClick={onClose}>
        &#120;
      </span>
      <div className={`${cls.wrapper}`}>
        <div className={cls.header}>
          <div className={cls.title}>Add Students</div>
          <SearchInput
            customPlaceholder={"Search student"}
            width={320}
            height={55}
            onValueChange={handleFilterUsers}
          />
        </div>
        <div className={cls.body}>
          {(filter.length > 2
            ? studentList.filter((u: IUser) => matchesFilter(u, filter))
            : studentList
          )?.map((user: IUser, idx: number) => (
            <div key={idx} className={cls.studentItem}>
              <div className={cls.studentItemWrapper}>
                <div className={cls.left}>
                  <input
                    type='checkbox'
                    id={user.id}
                    value={user.id}
                    hidden
                    onChange={handleSelectStudent}
                    onBlur={() => console.log("blur")}
                    checked={isUserEnrolled(user.id)}
                  />
                  <label htmlFor={user.id}></label>
                </div>

                <div className={cls.right}>
                  <div className={cls.row}>
                    <img src={user.avatarUrl || "/user-avatar.webp"} alt='' />
                    <div className={cls.details}>
                      <div className={cls.name}>
                        <span>{`${user.firstName} ${user.lastName}`}</span>
                      </div>
                      <div className={cls.role}>{`${user.role.name}`}</div>
                    </div>
                  </div>

                  <div className={cls.row}>
                    <span className={cls.timestamp}>Added 04.04.2022</span>
                    <span className={cls.payment}>Payment Completed</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={cls.footer}>
          <MainBtn
            label='Back'
            transparent={true}
            width={130}
            height={44}
            onClick={onClose}
          />

          <MainBtn
            label='Add Students'
            width={180}
            height={44}
            onClick={() => onAddStudents(activeStudents)}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentPicker;
