import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  switchVideoOrder,
  SwitchVideoOrderPayload,
} from "../../../api/videos/videosService";

import { IVideo } from "../../../interfaces/IVideo";

import ConfirmationPopUp from "../../common/confirmationPopUp/ConfirmationPopUp";
import Loading from "../../common/loading/Loading";
import cls from "./WeekVideosEditor.module.scss";

type Props = {
  width?: number;
  height?: number;
  videoListData: IVideo[];
  courseId: string;
  termId: string;
  weekId: string;
  onSelect?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const WeekVideosEditor: React.FC<Props> = ({
  width,
  height,
  courseId,
  termId,
  weekId,
  videoListData,
  onSelect,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [videoListState, setVideoListState] = useState<IVideo[]>(videoListData);
  const [dragId, setDragId] = useState<number>(0);
  const [dragEnterId, setDragEnterId] = useState<number>(Infinity);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successConfirmationUpdateOrder, showSuccessConfirmationUpdateOrder] =
    useState<boolean>(false);

  // handle drag event
  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    const dragId = parseInt(event.currentTarget.id);
    setDragId(dragId);
  };
  // handle on drag enter event
  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    const dragEnterId = parseInt(event.currentTarget.id);
    setDragEnterId(dragEnterId);
  };
  // handle drop event
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const dropId = parseInt(event.currentTarget.id);
    setDragEnterId(Infinity);

    const dragBox: IVideo = videoListData?.find(
      (video: IVideo) => video.orderIndex === dragId
    ) as IVideo;

    const dropBox: IVideo = videoListData?.find(
      (video: IVideo) => video.orderIndex === dropId
    ) as IVideo;

    const dragBoxOrderIndex = dragBox.orderIndex;
    const dropBoxOrderIndex = dropBox.orderIndex;

    const newVideoListState: IVideo[] = [];
    if (videoListData) {
      for (const video of videoListData) {
        if (video.orderIndex === dragId) {
          const result: IVideo = {
            ...video,
            orderIndex: dropBoxOrderIndex,
          };
          // console.log(result);
          newVideoListState.push(result);
        } else if (video.orderIndex === dropId) {
          const result: IVideo = {
            ...video,
            orderIndex: dragBoxOrderIndex,
          };

          // console.log(result);
          newVideoListState.push(result);
        } else {
          newVideoListState.push(video);
        }
      }
      console.log({ dragBoxOrderIndex, dropBoxOrderIndex });

      if (dragBoxOrderIndex !== dropBoxOrderIndex) {
        const video1: IVideo = {
          ...dragBox,
          thumbnailSource: dragBox.thumbnailSource
            ? dragBox.thumbnailSource
            : "",
          orderIndex: dropBoxOrderIndex,
        };
        const video2: IVideo = {
          ...dropBox,
          thumbnailSource: dropBox.thumbnailSource
            ? dropBox.thumbnailSource
            : "",
          orderIndex: dragBoxOrderIndex,
        };

        const payload: SwitchVideoOrderPayload = {
          video1,
          video2,
          courseId,
          termId,
          weekId,
        };

        switchVideosRQ.mutate(payload);
      }

      setVideoListState(
        newVideoListState.sort((a, b) => a.orderIndex - b.orderIndex)
      );
    }
  };
  // handle switch video order
  const switchVideosRQ = useMutation(switchVideoOrder, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("video order updated successfully!");
      queryClient.invalidateQueries(["videos", courseId, termId, weekId]);
      showSuccessConfirmationUpdateOrder(true);
    },
    onError: (err) => {
      console.log("error updating video order...", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  // video duration converter
  const durationConverter = (seconds: number | undefined): string => {
    if (seconds) {
      var h = Math.floor(seconds / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      var s = Math.floor((seconds % 3600) % 60);

      return h === 0
        ? `${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`
        : `${h}:${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`;
    }

    return "00:00:00";
  };

  useEffect(() => {
    if (videoListData) setVideoListState(videoListData);
  }, [videoListData]);

  return (
    <div className={cls.root} style={{ width: width, height: height }}>
      {successConfirmationUpdateOrder && (
        <ConfirmationPopUp
          type='success'
          message='Video order saved!'
          onClick={() => showSuccessConfirmationUpdateOrder(false)}
        />
      )}
      {isLoading && <Loading />}

      <div className={cls.add} onClick={() => navigate("videos")}>
        <div className={cls.body}>
          <div className={cls.icon}></div>
          <div className={cls.label}>Add New Video</div>
        </div>
      </div>

      {videoListState?.map((video: IVideo, index: number) => {
        return (
          <div
            className={cls.video}
            key={index}
            id={video.orderIndex.toString()}
            draggable={true}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={handleDragEnter}
            onDragStart={handleDrag}
            onDrop={handleDrop}>
            <div
              className={`${cls.body} ${
                dragEnterId === video.orderIndex ? cls.hovered : ""
              }`}>
              <div className={cls.orderHandler}>
                <span className={cls.icon}></span>
                <span className={cls.label}>Drag & Drop to change order</span>
              </div>
              <div
                className={cls.thumbnail}
                onClick={() =>
                  navigate(
                    `/courses/${courseId}/${termId}/${weekId}/${video.id}`
                  )
                }>
                <img
                  src={video.thumbnailSource || "/placeholder.webp"}
                  alt=''
                />
              </div>

              <div className={cls.subtitle}>
                Duration: {durationConverter(video.lengthInSeconds)}
              </div>
              <div className={cls.title}>{video.title}</div>
              <div className={cls.footer}>
                <input type='checkbox' value={video.id} onChange={onSelect} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WeekVideosEditor;
