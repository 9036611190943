import { useQuery, useQueryClient, QueryFunctionContext } from "react-query";
import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../requestApi";
import { auth } from "../firebase";

import { IUser, NewUser } from "../../interfaces/IUser";

import { cacheTimes } from "../cacheTimesRQ";
import { sendPasswordResetEmail } from "firebase/auth";

const fetchAdminUser = () => {
  return requestApi({
    url: ApiEndpoints.USER,
    method: "get",
  });
};

export const useAdminUser = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["user-data"], fetchAdminUser, {
    cacheTime: cacheTimes.USER_DATA_CACHE,
    staleTime: cacheTimes.USER_DATA_STALE,
    initialData: queryClient.getQueryData("user-data"),
    refetchOnWindowFocus: false,
    enabled: enabled,
    select: (data) => data.data.user,
    onError: (err) => {
      console.log(err);
    },
  });
};

export const updateUserAccount = (user: IUser) => {
  return requestApi({
    url: ApiEndpoints.SAVE_USER,
    method: "post",
    data: { user },
  });
};

export const createUserAccount = (payload: NewUser) => {
  return requestApi({
    url: ApiEndpoints.CREATE_ACCOUNT,
    method: "post",
    data: payload,
  }).then(() => {
    sendPasswordResetEmail(auth, payload.email);
  });
};

export const enableUserAccount = (userId: string) => {
  return requestApi({
    url: ApiEndpoints.ENABLE_USER,
    method: "post",
    data: { userId },
  });
};

export const disableUserAccount = (userId: string) => {
  return requestApi({
    url: ApiEndpoints.DISABLE_USER,
    method: "post",
    data: { userId },
  });
};
/////
export const muteUserAccount = (userId: string) => {
  return requestApi({
    url: ApiEndpoints.MUTE_USER,
    method: "post",
    data: { userId, isMuted: true },
  });
};

export const unmuteUserAccount = (userId: string) => {
  return requestApi({
    url: ApiEndpoints.MUTE_USER,
    method: "post",
    data: { userId, isMuted: false },
  });
};

export const deleteUserAccount = (userId: string) => {
  return requestApi({
    url: ApiEndpoints.DELETE_USER,
    method: "post",
    data: { userId },
  });
};

const fetchAllUsers = () => {
  return requestApi({
    url: ApiEndpoints.GET_ALL_USERS,
    method: "post",
    data: {},
  });
};

export const useAllUsers = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["user-list"], fetchAllUsers, {
    cacheTime: cacheTimes.USER_LIST_DATA_CACHE,
    staleTime: cacheTimes.USER_LIST_DATA_STALE,
    initialData: queryClient.getQueryData("user-list"),
    refetchOnWindowFocus: true,
    enabled: enabled,
    select: (data) => data.data.users,
    onError: (err) => {
      console.log(err);
    },
  });
};

const fetchUserRoles = () => {
  return requestApi({
    url: ApiEndpoints.GET_ROLES,
    method: "get",
  });
};

export const useRoles = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["user-roles"], fetchUserRoles, {
    cacheTime: cacheTimes.USER_ROLES_DATA_CACHE,
    staleTime: cacheTimes.USER_ROLES_DATA_STALE,
    initialData: queryClient.getQueryData("user-roles"),
    refetchOnWindowFocus: false,
    enabled: enabled,
    select: (data) => data.data.roles,
    onError: (err) => {
      console.log(err);
    },
  });
};

const fetchUserTransactions = ({ queryKey }: QueryFunctionContext) => {
  const userId: string = queryKey[1] as string;
  return requestApi({
    url: ApiEndpoints.GET_TRANSACTIONS,
    method: "post",
    data: { userId },
  });
};

export const useTransactions = (userId: string, enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["transactions", userId], fetchUserTransactions, {
    refetchOnWindowFocus: true,
    enabled: enabled,
    //   cacheTime: cacheTimes.COURSE_TERMS_CACHE,
    //   staleTime: cacheTimes.COURSE_TERMS_STALE,
    initialData: queryClient.getQueryData(["transactions", userId]),
    select: (data) => data.data.transactions.reverse(),
    onError: (err) => {
      console.log(err);
    },
  });
};

//

// export const fetchListOfUsers = (usersToFetch: string[]) => {
//   if (usersToFetch.length === 0) return [];

//   return requestApi({
//     url: ApiEndpoints.PUBLIC_MULTIPLE_USERS,
//     method: "post",
//     data: { users: usersToFetch },
//   });
// };

// // public user
// // export const fetchPublicUserRQ = ({ queryKey }: QueryFunctionContext) => {
// //   const userId = queryKey[1];

// //   return requestApi({
// //     url: ApiEndpoints.PUBLIC_USER,
// //     method: "post",
// //     data: { userId: userId },
// //   });
// // };

export const fetchPublicUser = (userId: string) => {
  return requestApi({
    url: ApiEndpoints.PUBLIC_USER,
    method: "post",
    data: { userId: userId },
  });
};
