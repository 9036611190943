import axios, { AxiosError, AxiosResponse } from "axios";
import {
  // API_URL,
  auth,
} from "./firebase";
import { User } from "firebase/auth";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL!,
});

export const requestApi = async ({ ...options }) => {
  const user: User | null = auth.currentUser;

  if (user)
    client.defaults.headers.common.Authorization = `Bearer ${await user.getIdToken()}`;

  const onSuccess = (response: AxiosResponse) => response;
  const onError = (error: AxiosError) => {
    console.error("Request Failed:", error.message);
    throw new Error(error.message);
    // return error;
  };

  return client(options).then(onSuccess).catch(onError);
};
