import { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import {
  createNewClass,
  CreateNewClassPayload,
  useAllClasses,
} from "../../api/class/classService";
import { useCourse } from "../../api/course/courseService";
import AppHeader from "../../components/appHeader/AppHeader";
import AddNewClass from "../../components/class/addNewClass/AddNewClass";
import ClassListItem from "../../components/class/classListItem/ClassListItem";
import ConfirmationPopUp from "../../components/common/confirmationPopUp/ConfirmationPopUp";
import Loading from "../../components/common/loading/Loading";
import SearchInput from "../../components/common/searchInput/SearchInput";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";
import { CollapsedSidebarContext } from "../../context/collapsedSidebarProvider";
import { IClassQuery } from "../../interfaces/IClassQuery";
import { UrlParams } from "../../interfaces/UrlParams";
import cls from "./Classes.module.scss";

const Classes: React.FC = () => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId } = routeParameters;

  const queryClient = useQueryClient();
  // const navigate = useNavigate();
  const { collapsed } = useContext(CollapsedSidebarContext);
  const { data: allClasses, isLoading: isLoadingAllClasses } = useAllClasses();

  const { data: courseData, isLoading: isLoadingCourseData } = useCourse(
    courseId,
    !!courseId
  );

  const [classList, setClassList] = useState<IClassQuery[]>();

  const [filter, setFilter] = useState<string>("");

  const [addNewClassForm, showAddNewClassForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successConfirmation, showSuccessConfirmation] =
    useState<boolean>(false);

  const handleFilterClasses = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const text = e.target.value;
    setFilter(text);
  };

  const matchesFilter = (classQuery: IClassQuery, filter: string) => {
    return (
      classQuery.classId.includes(filter) ||
      classQuery.courseId.includes(filter) ||
      classQuery.serviceTier.includes(filter) ||
      classQuery.class.enrolledUsers.some((userId) =>
        userId.includes(filter)
      ) ||
      classQuery.class.instructors.some((userId) => userId.includes(filter)) ||
      classQuery.class.tier.includes(filter) ||
      classQuery.class.name.includes(filter) ||
      classQuery.course.title.includes(filter)
    );
  };

  const handleSuccessConfirmation = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    showSuccessConfirmation(false);
  };

  const createNewClassRQ = useMutation(createNewClass, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("new class created successfully!");
      queryClient.invalidateQueries(["all-classes"]);
      showSuccessConfirmation(true);
    },
    onError: (err) => {
      console.log("error class created", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const handleOnSaveClass = (data: CreateNewClassPayload) => {
    // console.log(data);
    showAddNewClassForm(false);
    setIsLoading(true);
    createNewClassRQ.mutate(data);
  };

  useEffect(() => {
    if (allClasses) {
      setClassList(
        allClasses.filter((c: IClassQuery) => c.courseId === courseId)
      );
    }
  }, [allClasses, courseId]);

  return (
    <div className={cls.root}>
      {addNewClassForm && (
        <AddNewClass
          width={570}
          onSave={handleOnSaveClass}
          onDeny={() => showAddNewClassForm(false)}
        />
      )}

      {successConfirmation && (
        <ConfirmationPopUp
          type='success'
          message='Class was created sucessfully!'
          onClick={handleSuccessConfirmation}
        />
      )}

      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />
        <div className={cls.container}>
          {(isLoadingAllClasses || isLoadingCourseData || isLoading) && (
            <Loading />
          )}
          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>All Classes</div>
              <div className={cls.subtitle}>{courseData?.title}</div>
            </div>
            <SearchInput
              customPlaceholder={"Search Classes"}
              width={320}
              height={55}
              onValueChange={handleFilterClasses}
            />
          </div>
          {classList?.length === 0 && (
            <p
              style={{
                textAlign: "center",
                color: "white",
                padding: "30px",
                fontSize: "1.5rem",
              }}>
              No classes found!
            </p>
          )}

          <div className={cls.contents}>
            <div className={cls.add} onClick={() => showAddNewClassForm(true)}>
              <div className={cls.body}>
                <div className={cls.icon}></div>
                <div className={cls.label}>Add New Class</div>
              </div>
            </div>
            {(filter.length > 2
              ? classList?.filter((u: IClassQuery) => matchesFilter(u, filter))
              : classList
            )?.map((classItem: IClassQuery, index: number) => (
              <ClassListItem data={classItem} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Classes;
