import { useQuery, useQueryClient, QueryFunctionContext } from "react-query";
import { IChatMessage } from "../../interfaces/IChatMessage";
import {
  IClassQuestionTopic,
  IClassQuestionWithIdentifier,
} from "../../interfaces/IClassQuestionTopic";
import { cacheTimes } from "../cacheTimesRQ";
import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../requestApi";

const fetchQuestions = ({ queryKey }: QueryFunctionContext) => {
  const classId: string = queryKey[1] as string;

  return requestApi({
    url: ApiEndpoints.GET_ALL_CLASS_QUESTIONS,
    method: "POST",
    data: { classId },
  });
};

export const useQuestions = (classId: string, enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["questions", classId], fetchQuestions, {
    refetchOnWindowFocus: true,
    enabled: enabled,
    cacheTime: cacheTimes.CLASS_QUESTIONS_CACHE,
    staleTime: cacheTimes.CLASS_QUESTIONS_STALE,
    initialData: queryClient.getQueryData(["questions", classId]),
    select: (data) =>
      data.data.questions.sort(
        (a: IClassQuestionWithIdentifier, b: IClassQuestionWithIdentifier) =>
          a.question.unixTimestamp - b.question.unixTimestamp
      ),
    onError: (err) => {
      console.log(err);
    },
  });
};

const fetchComments = ({ queryKey }: QueryFunctionContext) => {
  const weekId: string = queryKey[1] as string;
  const termId: string = queryKey[2] as string;
  const classId: string = queryKey[3] as string;
  const courseId: string = queryKey[4] as string;
  const questionId: string = queryKey[5] as string;

  return requestApi({
    url: ApiEndpoints.GET_CLASS_QUESTIONS_COMMENTS,
    method: "POST",
    data: { weekId, termId, classId, courseId, questionId },
  });
};

export const useComments = (
  weekId: string,
  termId: string,
  classId: string,
  courseId: string,
  questionId: string,
  enabled?: boolean
) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["comments", weekId, termId, classId, courseId, questionId],
    fetchComments,
    {
      refetchOnWindowFocus: true,
      enabled: enabled,
      cacheTime: cacheTimes.CLASS_QUESTION_COMMENTS_CACHE,
      staleTime: cacheTimes.CLASS_QUESTION_COMMENTS_STALE,
      initialData: queryClient.getQueryData([
        "comments",
        weekId,
        termId,
        classId,
        courseId,
        questionId,
      ]),
      select: (data) =>
        data.data.comments.sort(
          (a: IChatMessage, b: IChatMessage) =>
            a.unixTimestamp - b.unixTimestamp
        ),
      onError: (err) => {
        console.log(err);
      },
    }
  );
};

export type AddCommentToQuestionPayload = {
  weekId: string;
  classId: string;
  courseId: string;
  termId: string;
  questionId: string;
  comment: string;
};

export const addCommentToQuestion = (payload: AddCommentToQuestionPayload) => {
  return requestApi({
    url: ApiEndpoints.ADD_COMMENT_TO_CLASS_QUESTION,
    method: "POST",
    data: payload,
  });
};
