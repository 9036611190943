import { useContext } from "react";
import cls from "./MenuList.module.scss";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../context/authContext";

type Props = {
  collapsed: boolean;
};

const MenuList: React.FC<Props> = ({ collapsed }) => {
  const authInstance = useContext(AuthContext);
  return (
    <div className={`${cls.root} ${collapsed ? cls.collapsed : ""}`}>
      <NavLink
        to='/users'
        className={(navLink) =>
          cls.item + " " + (navLink.isActive ? cls.active : "")
        }>
        <div className={`${cls.icon} ${cls.users}`}></div>
        <div className={cls.name}>All Users</div>
      </NavLink>
      <NavLink
        to='/courses'
        className={(navLink) =>
          cls.item + " " + (navLink.isActive ? cls.active : "")
        }>
        <div className={`${cls.icon} ${cls.courses}`}></div>
        <div className={cls.name}>All Courses</div>
      </NavLink>
      {/* <NavLink
        to='/classes'
        className={(navLink) =>
          cls.item + " " + (navLink.isActive ? cls.active : "")
        }>
        <div className={`${cls.icon} ${cls.classes}`}></div>
        <div className={cls.name}>All Classes</div>
      </NavLink> */}

      {authInstance?.userRole !== "INSTRUCTOR" &&
        authInstance?.userRole !== "USER" && (
          <>
            <NavLink
              to='/maintenance'
              className={(navLink) =>
                cls.item + " " + (navLink.isActive ? cls.active : "")
              }>
              <div className={`${cls.icon} ${cls.maintenance}`}></div>
              <div className={cls.name}>Maintenance</div>
            </NavLink>
            <NavLink
              to='/help'
              className={(navLink) =>
                cls.item + " " + (navLink.isActive ? cls.active : "")
              }>
              <div className={`${cls.icon} ${cls.help}`}></div>
              <div className={cls.name}>Help Editor</div>
            </NavLink>
            <NavLink
              to='/support'
              className={(navLink) =>
                cls.item + " " + (navLink.isActive ? cls.active : "")
              }>
              <div className={`${cls.icon} ${cls.support}`}></div>
              <div className={cls.name}>Support</div>
            </NavLink>
            <NavLink
              to='/history'
              className={(navLink) =>
                cls.item + " " + (navLink.isActive ? cls.active : "")
              }>
              <div className={`${cls.icon} ${cls.history}`}></div>
              <div className={cls.name}>History</div>
            </NavLink>
          </>
        )}
    </div>
  );
};

export default MenuList;
