import React, { useState } from "react";
import FormInput from "../../common/formInput/FormInput";
import cls from "./EditSyllabusCategory.module.scss";

type Props = {
  width?: number;
  height?: number;
  name: string;
  onConfirm?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDeny?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};
const EditSyllabusCategory: React.FC<Props> = ({
  width,
  height,
  name,
  onDeny,
  onConfirm,
}) => {
  const [categoryName, setCategoryName] = useState<string>(name);
  const handleCategoryNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;
    setCategoryName(value);
  };

  return (
    <div className={cls.root}>
      <div className={cls.wrapper} style={{ width: width, height: height }}>
        <div className={cls.title}>Syllabus Category</div>

        <FormInput
          label='Syllabus name'
          type='text'
          maxLength={40}
          width={330}
          defaultValue={categoryName}
          onChange={handleCategoryNameChange}
        />
        <div className={cls.actions}>
          <button onClick={onDeny}>Back</button>
          <button onClick={onConfirm} value={categoryName}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSyllabusCategory;
