import { useContext } from "react";
import { useAllCourses } from "../../api/course/courseService";
import AppHeader from "../../components/appHeader/AppHeader";
import MainBtn from "../../components/common/buttons/mainBtn/MainBtn";
import Loading from "../../components/common/loading/Loading";
import CourseListItem from "../../components/course/courseListItem/CourseListItem";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";
import { useNavigate } from "react-router-dom";
import { CollapsedSidebarContext } from "../../context/collapsedSidebarProvider";
import { ICoursePreview } from "../../interfaces/ICoursePreview";
import cls from "./Courses.module.scss";

const Courses: React.FC = () => {
  const { collapsed } = useContext(CollapsedSidebarContext);
  const { data: courseList, isLoading: isLoadingCourseList } = useAllCourses();
  const navigate = useNavigate();
  return (
    <div className={cls.root}>
      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />
        <div className={cls.container}>
          {isLoadingCourseList && <Loading />}
          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>All Courses</div>
              <div className={cls.subtitle}>
                Lorem ipsum dolor amet. Lorem ipsum dolor amet.
              </div>
            </div>
            <MainBtn
              label='Create a new course'
              onClick={() => navigate("/courses/create")}
            />
          </div>
          {courseList?.length === 0 && (
            <p
              style={{
                textAlign: "center",
                color: "white",
                padding: "30px",
                fontSize: "1.5rem",
              }}>
              No courses found!
            </p>
          )}
          <div className={cls.contents}>
            {courseList?.map((course: ICoursePreview, index: number) => (
              <CourseListItem data={course} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
