import * as tus from "tus-js-client";

export function TusUpload(
  file: File,
  url: string,
  onProgress: (percentageUploaded: number) => void
): Promise<void> {
  return new Promise(
    (resolve: (value?: void) => void, reject: (error: Error) => void) => {
      const upload: tus.Upload = new tus.Upload(file, {
        uploadUrl: url,
        onError: (error: Error) => {
          console.log("Failed because: " + error);
          reject(error);
        },
        onSuccess: () => {
          console.log("Download %s from %s", file.name, upload.url);
          resolve();
        },
        onProgress: (bytesSent: number, totalBytesToSend) =>
          onProgress((bytesSent / totalBytesToSend) * 100),
      });

      upload.start();
    }
  );
}
