// import {  useQueryClient } from "react-query";
// import { useEffect } from "react";
import { useState } from "react";
import { useAllUsers } from "../../../api/user/userService";
import { IClassQuestionWithIdentifier } from "../../../interfaces/IClassQuestionTopic";
import { IUser } from "../../../interfaces/IUser";
import TermWeekSelector from "../termWeekSelector/TermWeekSelector";

// import MessageSubmitter from "../../common/messageSubmitter/MessageSubmitter";
import cls from "./ClassQuestions.module.scss";
import ClassQuestionTopic from "./ClassQuestionTopic";

type Props = {
  questions: IClassQuestionWithIdentifier[];
};

const ClassQuestions: React.FC<Props> = ({ questions }) => {
  // const queryClient = useQueryClient();

  const { data: users } = useAllUsers();

  const [selectedWeek, setSelectedWeek] = useState<string>();

  // const addQuestionRQ = useMutation(addNewQuestion, {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries([
  //       "class-questions",
  //       weekId,
  //       termId,
  //       enrolledClass.id,
  //       enrolledClass.courseId,
  //       enrolledClass.tier,
  //     ]);
  //   },
  //   onError: (err) => {
  //     console.log("addQuestionRQ", err);
  //   },
  //   // onSettled: () => {
  //   //   setSubmitButtonEnabled(false);
  //   //   setQuestionDescription("");
  //   // },
  // });

  // const handleSubmitQuestion = (textToSubmit: string) => {
  //   // e.preventDefault();
  //   if (textToSubmit.length === 0) return;
  //   setSubmitButtonEnabled(true);
  //   const payload = {
  //     weekId: weekId ? weekId : "",
  //     serviceTier: enrolledClass.tier,
  //     classId: enrolledClass.id,
  //     courseId: enrolledClass.courseId,
  //     title: "",
  //     termId: termId ? termId : "",
  //     description: textToSubmit,
  //   };
  //   return addQuestionRQ.mutate(payload);
  //   console.log(textToSubmit);
  // };

  const handleSelectWeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSelectedWeek(e.target.value);
  };

  return (
    <div className={cls.root}>
      {/* <div className={cls.messageComposer}>
        <MessageSubmitter
          emojiEnabled={true}
          submitMessage={handleSubmitQuestion}
        />
      </div> */}
      <div className={cls.wrapper}>
        {questions?.length === 0 && (
          <p
            style={{
              color: "white",
              fontSize: "1.3125rem",
              letterSpacing: -0.33,
              lineHeight: "1.5625rem",
              marginBottom: 24,
            }}>
            No questions for this class.
          </p>
        )}
        <div className={cls.weekSelector}>
          <TermWeekSelector onSelect={handleSelectWeek} />
        </div>

        {(selectedWeek
          ? questions?.filter(
              (q: IClassQuestionWithIdentifier) =>
                q.identifier.weekId === selectedWeek
            )
          : questions
        )?.map((question: IClassQuestionWithIdentifier, index: number) => {
          const user: IUser = users?.find(
            (u: IUser) => u.id === question.question.userId
          ) as IUser;

          return (
            <ClassQuestionTopic
              key={question.question.id}
              user={user}
              question={question}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ClassQuestions;
