import { RefObject, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import StorageService from "../../../api/storage/storageService";
import { UrlParams } from "../../../interfaces/UrlParams";
import Loading from "../loading/Loading";
import "./QuillEditor.scss";

type Props = {
  width?: number;
  height?: number;
  label?: string;
  value?: string;
  toolbarContainer?: any;
  formats?: string[];
  onChange: (data: string) => void;
  onBlur?: () => void;
};

const QuillEditor: React.FC<Props> = ({
  width,
  height,
  label,
  value,
  toolbarContainer,
  formats,
  onBlur,
  onChange,
}) => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId } = routeParameters;

  const quillRef = useRef() as RefObject<ReactQuill>;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const quillImageCallback = async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;

      const quillObj = quillRef?.current?.getEditor();
      const range = quillObj?.getSelection();

      if (file) {
        setIsLoading(true);
        const thumbnailUrl = await StorageService.UploadCourseReferences(
          file,
          courseId
        );

        quillObj?.insertEmbed(range?.index || 0, "image", thumbnailUrl);
      }
      setIsLoading(false);
    };
  };
  const moduless = useMemo(
    () => ({
      toolbar: {
        container: toolbarContainer,
        handlers: {
          image: quillImageCallback,
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className='root' style={{ width: width, height: height }}>
      {isLoading && <Loading />}
      {label && <div className='title'>{label}</div>}

      <div className='wrapper'>
        <ReactQuill
          placeholder='Editor'
          ref={quillRef}
          value={value}
          preserveWhitespace
          onChange={onChange}
          onBlur={onBlur}
          modules={moduless}
          formats={formats}
        />
      </div>
    </div>
  );
};

export default QuillEditor;
