import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  deleteClass,
  DeleteClassPayload,
  saveClass,
} from "../../../api/class/classService";
import { useAllUsers } from "../../../api/user/userService";
import { IClassQuery } from "../../../interfaces/IClassQuery";
import { ICourseClass } from "../../../interfaces/ICourseClass";
import { IUser } from "../../../interfaces/IUser";
import MainBtn from "../../common/buttons/mainBtn/MainBtn";
import CallToActionPopUp from "../../common/callToActionPopUp/CallToActionPopUp";
import ConfirmationPopUp from "../../common/confirmationPopUp/ConfirmationPopUp";
import FormInput from "../../common/formInput/FormInput";
import Loading from "../../common/loading/Loading";
import StudentPicker from "../studentPicker/StudentPicker";
import cls from "./ClassDetails.module.scss";

type Props = {
  classData: IClassQuery;
};

const ClassDetails: React.FC<Props> = ({ classData }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: allUsers, isLoading: isLoadingAllUsers } = useAllUsers();

  const [className, setClassName] = useState<string>(classData?.class.name);
  const [enrolledUsers, setEnrolledUsers] = useState<IUser[]>([]);
  const [studentPicker, showStudentPicker] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /////////////// POPUPS ////////////////

  const [successConfirmationUpdated, showSuccessConfirmationUpdated] =
    useState<boolean>(false);
  const [successConfirmationDeleted, showSuccessConfirmationDeleted] =
    useState<boolean>(false);

  const [confirmationPopUpDelete, showConfirmationPopUpDelete] =
    useState<boolean>(false);

  const [errorConfirmation, showErrorConfirmation] = useState<boolean>(false);

  const handleOnClassNameEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value: string = e.target.value;
    setClassName(value);
    setButtonDisabled(false);
  };

  const handleAddStudents = (data: IUser[]) => {
    setEnrolledUsers(data);

    showStudentPicker(false);
    setButtonDisabled(false);
  };
  /////// UPDATE CLASS //////
  const updateClassRQ = useMutation(saveClass, {
    onMutate: () => setIsLoading(true),
    onSuccess: (s) => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries("user-list");
      queryClient.invalidateQueries("all-classes");

      showSuccessConfirmationUpdated(true);
      setButtonDisabled(true);
    },
    onError: (err) => {
      console.log("error class updated", err);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const handleUpdateClass = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (className) {
      const payload: ICourseClass = {
        ...classData.class,
        name: className,
        enrolledUsers: enrolledUsers.map((user: IUser) => user.id),
      };
      updateClassRQ.mutate(payload);

      //   console.log({ className, studentList });
    }
  };

  /////// DELETE CLASS //////
  const deleteClassRQ = useMutation(deleteClass, {
    onMutate: () => setIsLoading(true),
    onSuccess: (s) => {
      // Invalidates cache and refetch
      //   queryClient.invalidateQueries("all-classes");
      showSuccessConfirmationDeleted(true);
    },
    onError: (err) => {
      console.log("error class updated", err);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const handleDeleteClass = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    showConfirmationPopUpDelete(false);
    const payload: DeleteClassPayload = {
      classId: classData.classId,
      courseId: classData.courseId,
      serviceTier: classData.class.tier,
    };
    deleteClassRQ.mutate(payload);
  };

  const handleDenyConfirmation = () => {
    showConfirmationPopUpDelete(false);
  };

  // const handleDeleteUserFromClass = (userId: string) => {
  //     const newEnrolledUsers = enrolledUsers.filter(
  //       (user: IUser) => user.id !== userId
  //     );
  //     setEnrolledUsers(newEnrolledUsers);
  //     setButtonDisabled(false);
  //   };

  useEffect(() => {
    if (allUsers && classData) {
      const enrolledUsers: IUser[] = allUsers?.filter((user: IUser) =>
        classData.class.enrolledUsers.find((id: string) => id === user.id)
      );
      setEnrolledUsers(enrolledUsers);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers, classData]);
  return (
    <div className={cls.root}>
      {successConfirmationUpdated && (
        <ConfirmationPopUp
          type='success'
          message='Class successfully updated!'
          onClick={() => showSuccessConfirmationUpdated(false)}
        />
      )}
      {successConfirmationDeleted && (
        <ConfirmationPopUp
          type='success'
          message='Class was deleted!'
          onClick={() => [
            queryClient.invalidateQueries("all-classes"),
            navigate(`/courses/${classData.courseId}/classes`),
          ]}
        />
      )}
      {confirmationPopUpDelete && (
        <CallToActionPopUp
          message='Are you sure you want to delete this class?'
          onConfirm={handleDeleteClass}
          onDeny={() => handleDenyConfirmation()}
        />
      )}
      {errorConfirmation && (
        <ConfirmationPopUp
          type='error'
          message='Could not update the class!'
          onClick={() => showErrorConfirmation(false)}
        />
      )}

      {studentPicker && (
        <StudentPicker
          classData={classData}
          enrolledStudents={enrolledUsers}
          onAddStudents={handleAddStudents}
          onClose={() => showStudentPicker(false)}
        />
      )}

      {(isLoading || isLoadingAllUsers) && <Loading />}

      <div className={cls.form}>
        <form action=''>
          <div className={cls.row}>
            <FormInput
              type='text'
              label='Class Name'
              maxLength={38}
              onChange={handleOnClassNameEdit}
              defaultValue={classData?.class.name}
            />
          </div>

          <div className={cls.row}>
            <div className={cls.title}>Student</div>
            <div className={cls.studentList}>
              {enrolledUsers?.map((user: IUser, idx: number) => (
                <div
                  key={idx}
                  className={`${cls.studentItem} ${
                    user.isDisabled === true && cls.isDisabled
                  }`}>
                  {/* <div
                    className={cls.delete}
                    onClick={(e) => {
                      e.preventDefault();
                      setEnrolledUsers(
                        enrolledUsers.filter((u) => u.id !== user.id)
                      );
                    }}>
                    X
                  </div> */}
                  <div
                    className={cls.wrapper}
                    onClick={() => navigate(`/users/${user.id}`)}>
                    <div className={cls.row}>
                      <img src={user.avatarUrl || "/user-avatar.webp"} alt='' />
                      <div className={cls.details}>
                        <div className={cls.name}>
                          <span>{`${user.firstName} ${user.lastName}`}</span>
                        </div>
                        <div className={cls.role}>{`${user.role.name}`}</div>
                      </div>
                    </div>

                    <div className={cls.row}>
                      <span className={cls.timestamp}>Added 04.04.2022</span>
                      <span className={cls.payment}>Payment Completed</span>
                    </div>
                  </div>
                </div>
              ))}

              <div className={cls.add} onClick={() => showStudentPicker(true)}>
                <div className={cls.body}>
                  <div className={cls.icon}>+</div>
                  <div className={cls.label}>Add New Student</div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className={cls.actions}>
          <MainBtn
            label='Back'
            transparent={true}
            width={130}
            height={44}
            onClick={() => navigate(-1)}
          />
          <MainBtn
            label='Delete Class'
            transparent={true}
            width={180}
            height={44}
            onClick={() => showConfirmationPopUpDelete(true)}
          />

          <MainBtn
            disabled={buttonDisabled}
            label='Save Changes'
            width={180}
            height={44}
            onClick={handleUpdateClass}
          />
        </div>
      </div>
    </div>
  );
};

export default ClassDetails;
