import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  deleteWeek,
  switchWeekOrder,
  SwitchWeekOrderPayload,
} from "../../../api/weeks/weeksService";
import { IWeek } from "../../../interfaces/IWeek";
import CallToActionPopUp from "../../common/callToActionPopUp/CallToActionPopUp";
import ConfirmationPopUp from "../../common/confirmationPopUp/ConfirmationPopUp";
import Loading from "../../common/loading/Loading";
import cls from "./TermWeeksEditor.module.scss";

type Props = {
  width?: number;
  height?: number;
  courseId: string;
  termId: string;
  weeksData: IWeek[];
};

const TermWeeksEditor: React.FC<Props> = ({
  width,
  height,
  courseId,
  termId,
  weeksData,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [weekState, setWeekState] = useState<IWeek[]>(weeksData);
  const [dragId, setDragId] = useState<number>(0);
  const [dragEnterId, setDragEnterId] = useState<number>(Infinity);

  const [deleteWeekPopup, showDeleteWeekPopup] = useState<boolean>(false);
  const [weekToDelete, setWeekToDelete] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successConfirmationDelete, showSuccessConfirmationDelete] =
    useState<boolean>(false);
  const [successConfirmationUpdateOrder, showSuccessConfirmationUpdateOrder] =
    useState<boolean>(false);

  // handle drag event
  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    const dragId = parseInt(event.currentTarget.id);
    setDragId(dragId);
  };
  // handle on drag enter event
  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    const dragEnterId = parseInt(event.currentTarget.id);
    setDragEnterId(dragEnterId);
  };
  // handle drop event
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const dropId = parseInt(event.currentTarget.id);
    setDragEnterId(Infinity);

    const dragBox: IWeek = weeksData?.find(
      (week: IWeek) => week.orderIndex === dragId
    ) as IWeek;

    const dropBox: IWeek = weeksData?.find(
      (week: IWeek) => week.orderIndex === dropId
    ) as IWeek;

    const dragBoxOrderIndex = dragBox.orderIndex;
    const dropBoxOrderIndex = dropBox.orderIndex;

    const newWeekState: IWeek[] = [];
    if (weeksData) {
      for (const week of weeksData) {
        if (week.orderIndex === dragId) {
          const result: IWeek = {
            ...week,
            orderIndex: dropBoxOrderIndex,
          };

          newWeekState.push(result);
        } else if (week.orderIndex === dropId) {
          const result: IWeek = {
            ...week,
            orderIndex: dragBoxOrderIndex,
          };
          newWeekState.push(result);
        } else {
          newWeekState.push(week);
        }
      }

      if (dragBoxOrderIndex !== dropBoxOrderIndex) {
        const week1: IWeek = { ...dragBox, orderIndex: dropBoxOrderIndex };
        const week2: IWeek = { ...dropBox, orderIndex: dragBoxOrderIndex };
        const payload: SwitchWeekOrderPayload = {
          week1,
          week2,
          courseId,
          termId,
        };
        switchWeeksRQ.mutate(payload);
      }

      setWeekState(newWeekState.sort((a, b) => a.orderIndex - b.orderIndex));
    }
  };
  // handle switch week order
  const switchWeeksRQ = useMutation(switchWeekOrder, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("weeks order updated successfully!");
      queryClient.invalidateQueries(["weeks", courseId, termId]);
      showSuccessConfirmationUpdateOrder(true);
    },
    onError: (err) => {
      console.log("error updating term...", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  // handle show delete category popup
  const handleShowDeleteWeekPopup = (week: IWeek) => {
    showDeleteWeekPopup(true);
    setWeekToDelete(week.id);
  };
  // handle delete week
  const deleteTermRQ = useMutation(deleteWeek, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("week deleted successfully!");
      queryClient.invalidateQueries(["weeks", courseId, termId]);
      showSuccessConfirmationDelete(true);
    },
    onError: (err) => {
      console.log("error deleting course", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setWeekToDelete("");
      setIsLoading(false);
    },
  });
  // handle confirm delete week
  const handleConfirmDeleteWeek = () => {
    deleteTermRQ.mutate({ weekId: weekToDelete, termId, courseId });
    showDeleteWeekPopup(false);
  };
  const getStartingDate = (time: number) => {
    const date = new Date(time);
    return `Starting date: ${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`;
  };
  useEffect(() => {
    if (weeksData) setWeekState(weeksData);
  }, [weeksData]);

  return (
    <div className={cls.root} style={{ width: width, height: height }}>
      {deleteWeekPopup && (
        <CallToActionPopUp
          message='Are you sure you want to delete this week?'
          onDeny={() => [showDeleteWeekPopup(false), setWeekToDelete("")]}
          onConfirm={handleConfirmDeleteWeek}
        />
      )}
      {successConfirmationDelete && (
        <ConfirmationPopUp
          type='success'
          message='Week deleted!'
          onClick={() => showSuccessConfirmationDelete(false)}
        />
      )}
      {successConfirmationUpdateOrder && (
        <ConfirmationPopUp
          type='success'
          message='Week order saved!'
          onClick={() => showSuccessConfirmationUpdateOrder(false)}
        />
      )}
      {isLoading && <Loading />}

      <div className={cls.add} onClick={() => navigate("weeks")}>
        <div className={cls.body}>
          <div className={cls.icon}></div>
          <div className={cls.label}>Add New Week</div>
        </div>
      </div>

      {weekState?.map((week: IWeek, index: number) => {
        return (
          <div
            className={cls.week}
            key={index}
            id={week.orderIndex.toString()}
            draggable={true}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={handleDragEnter}
            onDragStart={handleDrag}
            onDrop={handleDrop}>
            <div
              className={`${cls.body} ${
                dragEnterId === week.orderIndex ? cls.hovered : ""
              }`}>
              <div className={cls.orderHandler}>
                <span className={cls.icon}></span>
                <span className={cls.label}>Drag & Drop to change order</span>
              </div>

              <div className={cls.subtitle}>
                {getStartingDate(week.startTimestamp)}
              </div>
              <div className={cls.title}>{week.title}</div>
              <div className={cls.description}>{week.overview}</div>
              <div className={cls.actions}>
                <div className={cls.button} onClick={() => navigate(week.id)}>
                  Details
                </div>
                <div
                  className={cls.button}
                  onClick={() => handleShowDeleteWeekPopup(week)}>
                  Delete
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TermWeeksEditor;
