import cls from "./Login.module.scss";
import LoginBg from "../../assets/images/login-bg.jpeg";
import Logo from "../../assets/images/logo@2x.png";
// import { useState } from "react";
import LoginForm from "../../components/auth/loginForm/LoginForm";
// import { useContext, useEffect } from "react";
// import { AuthContext } from "../../context/authContext";
// import { useNavigate } from "react-router-dom";

const Login: React.FC = () => {
  // const authInstance = useContext(AuthContext);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (authInstance?.currentUser) navigate("/users");
  //   // try {

  //   //   if (authInstance.userRole === "USER") {
  //   //     navigate("/login");
  //   //   } else {
  //   //     navigate("/users");
  //   //   }
  //   // } catch (error) {
  //   //   console.error("Error checking user role:", error);
  //   // }
  // }, [authInstance, navigate]);

  return (
    <div className={cls.root}>
      <div className={cls.left}>
        <img src={LoginBg} alt='Visign' />
        <div className={cls.title}>
          Welcome to <br /> Visign
        </div>
      </div>
      <div className={cls.right}>
        <img className={cls.logo} src={Logo} alt='Visign Logo' />

        <div className={cls.form}>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
