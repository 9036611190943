import FormInput from "../../common/formInput/FormInput";
import cls from "./AddNewLecturer.module.scss";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextareaInput from "../../../components/common/textareaInput/TextareaInput";
import ProfileImageUploader from "../../common/profileImageUploader/ProfileImageUploader";

type FormInputs = {
  firstName: string;
  lastName: string;
  description: string;
  avatar: File | null;
};

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required!"),
  lastName: yup.string().required("Last name is required!"),
  description: yup.string(),
  avatar: yup.mixed(),
  // .required("Course background image is required!")
  // .test(
  //   "type",
  //   "Only image files are accepted: .jpeg, .jpg, .png and .bmp!",
  //   (value) => {
  //     return (
  //       value &&
  //       (value.type === "image/jpeg" ||
  //         value.type === "image/bmp" ||
  //         value.type === "image/png" ||
  //         value.type === "image/jpg")
  //     );
  //   }
  // ),
});

type Props = {
  width?: number;
  height?: number;
  // onConfirm?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onSave: (d: FormInputs) => void;
  onDeny?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};
const AddNewLecturer: React.FC<Props> = ({
  width,
  height,
  onDeny,
  onSave,
  // onConfirm,
}) => {
  ///////////////// handle submit form
  const { handleSubmit, control } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: FormInputs) => {
    onSave(data);
  };

  return (
    <div className={cls.root}>
      <div className={cls.wrapper} style={{ width: width, height: height }}>
        <div className={cls.title}>Add New Lecturer</div>

        {/* <form action=''> */}
        <div className={cls.row}>
          <Controller
            name='avatar'
            control={control}
            rules={{ required: false }}
            render={({
              field: { onChange, onBlur, ref, value },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <ProfileImageUploader
                defaultValue=''
                label='Profile picture'
                value={value}
                onChange={(event) => {
                  const val = event.target.files?.[0];
                  return onChange(val);
                }}
                onBlur={onBlur}
                imgRef={ref}
                isTouched={isTouched}
                isDirty={isDirty}
                error={error}
              />
            )}
          />
        </div>
        <div className={cls.row}>
          <Controller
            name='firstName'
            defaultValue=''
            control={control}
            render={({
              field: { onChange, onBlur, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <FormInput
                type='text'
                label='First Name'
                maxLength={20}
                defaultValue=''
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                isTouched={isTouched}
                isDirty={isDirty}
                error={error}
              />
            )}
          />

          <Controller
            name='lastName'
            defaultValue=''
            control={control}
            render={({
              field: { onChange, onBlur, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <FormInput
                type='text'
                label='Last Name'
                maxLength={20}
                defaultValue=''
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                isTouched={isTouched}
                isDirty={isDirty}
                error={error}
              />
            )}
          />
        </div>
        <div className={cls.row}>
          <Controller
            name='description'
            defaultValue=''
            control={control}
            rules={{ required: true }}
            render={({
              field: { onChange, onBlur, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <TextareaInput
                label='About the lecturer'
                defaultValue=''
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                isTouched={isTouched}
                isDirty={isDirty}
                error={error}
              />
            )}
          />
        </div>
        {/* </form> */}

        <div className={cls.actions}>
          <button onClick={onDeny}>Back</button>
          <button onClick={handleSubmit(onSubmit)}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default AddNewLecturer;
