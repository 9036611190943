import cls from "./BackgroundImageUploader.module.scss";
import { FieldError } from "react-hook-form";
import { useEffect, useState } from "react";

type Props = {
  label?: string;
  width?: number;
  height?: number;
  defaultValue?: string;
  value: File | null;
  disabled?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isTouched?: boolean;
  isDirty?: boolean;
  error?: FieldError | undefined;
};

const BackgroundImageUploader: React.FC<Props> = ({
  width,
  height,
  defaultValue,
  value,
  label,
  disabled,
  inputRef,
  onChange,
  onBlur,
  isTouched,
  isDirty,
  error,
}) => {
  const [imgUrl, setImgUrl] = useState<any>();

  const readURL = (file: File) => {
    // to do => improve file reader
    let url: string | ArrayBuffer | null | undefined = "";
    if (file) {
      var reader = new FileReader();

      reader.onload = function (e) {
        setImgUrl(e.target?.result);
        url = e.target?.result || undefined;
      };
      reader.readAsDataURL(file);
    }
    return url;
  };

  useEffect(() => {
    if (value) {
      readURL(value);
      console.log(value);
    }
  }, [value]);
  return (
    <div className={cls.root}>
      <div className={cls.label}>{label}</div>
      <div className={cls.wrapper}>
        <label
          htmlFor='fileInput'
          role='button'
          className={`${cls.fileUpload} ${disabled ? cls.disabled : ""}`}>
          Click to upload file
        </label>
        <input
          disabled={disabled}
          ref={inputRef}
          id='fileInput'
          type='file'
          name='fileInput'
          multiple={false}
          accept='image/*'
          onBlur={onBlur}
          onChange={onChange}
        />

        {defaultValue && !value && <img src={defaultValue} alt='' />}

        {value && <img src={imgUrl} alt='' />}
      </div>

      {value && <p>{value.name}</p>}

      <p className={cls.error}>{`${error ? error.message : ""}`}</p>
    </div>
  );
};

export default BackgroundImageUploader;
