import { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import {
  deleteSupportTicketCategories,
  saveSupportTicketCategories,
  useAllSupportTickets,
  useSupportCategories,
} from "../../api/support/supportService";
import { useAllUsers } from "../../api/user/userService";
import AppHeader from "../../components/appHeader/AppHeader";
import CallToActionPopUp from "../../components/common/callToActionPopUp/CallToActionPopUp";
import ConfirmationPopUp from "../../components/common/confirmationPopUp/ConfirmationPopUp";
import Loading from "../../components/common/loading/Loading";
import SearchInput from "../../components/common/searchInput/SearchInput";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";
import AddNewCategory from "../../components/support/addNewCategory/AddNewCategory";
import EditCategory from "../../components/support/editCategory/EditCategory";
import TicketListItem from "../../components/support/ticketListItem/TicketListItem";
import { CollapsedSidebarContext } from "../../context/collapsedSidebarProvider";

import {
  ISupportTicket,
  SupportTicketCategory,
} from "../../interfaces/ISupportTicket";
import cls from "./Support.module.scss";

const Support: React.FC = () => {
  const { collapsed } = useContext(CollapsedSidebarContext);
  const queryClient = useQueryClient();

  const { data: allTickets, isLoading: isLoadingAllTickets } =
    useAllSupportTickets();
  const { data: categories, isLoading: isLoadingCategories } =
    useSupportCategories();
  const { data: userList } = useAllUsers();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");

  const [activeCategory, setActiveCategory] = useState<SupportTicketCategory>();

  const [categoryToEdit, setCategoryToEdit] =
    useState<SupportTicketCategory | null>(null);

  const [ticketsData, setTicketsData] = useState<ISupportTicket[]>([]);

  const [addCategoryForm, showAddCategoryForm] = useState<boolean>(false);
  const [editCategoryForm, showEditCategoryForm] = useState<boolean>(false);

  const [successConfirmationSaveCategory, showSuccessConfirmationSaveCategory] =
    useState<boolean>(false);
  const [confirmationDeleteCategory, showConfirmationDeleteCategory] =
    useState<boolean>(false);

  const [
    successConfirmationDeleteCategory,
    showSuccessConfirmationDeleteCategory,
  ] = useState<boolean>(false);

  const handleFilterTickets = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const text = e.target.value;
    setFilter(text);
  };

  const matchesFilter = (ticket: ISupportTicket, filter: string) => {
    // console.log(ticket);
    return (
      ticket.message.toLowerCase().includes(filter) ||
      // ticket.category.includes(filter) ||
      ticket.userId.toLowerCase().includes(filter) ||
      ticket.status.toLowerCase().includes(filter)
    );
  };

  ///////////
  const createNewCategoryRQ = useMutation(saveSupportTicketCategories, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("Support category was saved sucessfully!");
      queryClient.invalidateQueries(["support-categories"]);
      showSuccessConfirmationSaveCategory(true);
    },
    onError: (err) => {
      console.log("error categorey created", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const handleSaveNewCategory = (data: { name: string }) => {
    const payload: SupportTicketCategory[] = [
      ...categories,
      { name: data.name },
    ];
    createNewCategoryRQ.mutate(payload);
    console.log(data.name);
    showAddCategoryForm(false);
  };

  const handleEditCategory = (data: SupportTicketCategory) => {
    if (!categoryToEdit) return;

    console.log(data);
    // let payload: SupportTicketCategory[] = categories.filter(
    //   (c: SupportTicketCategory) => c.id !== data.id
    // );
    // payload = [...payload, data];
    // console.log(payload);
    createNewCategoryRQ.mutate([data]);
    showEditCategoryForm(false);
  };

  /////////////////

  const deleteCategoryRQ = useMutation(deleteSupportTicketCategories, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log(" category deleted successfully!");
      queryClient.invalidateQueries(["support-categories"]);
      queryClient.invalidateQueries(["support-tickets"]);
      showSuccessConfirmationDeleteCategory(true);
    },
    onError: (err) => {
      console.log("error categorey delete", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setCategoryToEdit(null);
      setIsLoading(false);
    },
  });

  const handleDeleteCategory = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!categoryToEdit) return;

    const payload: string[] = [categoryToEdit.id];

    deleteCategoryRQ.mutate(payload);
    showConfirmationDeleteCategory(false);
  };

  useEffect(() => {
    if (categories) setActiveCategory(categories[0]);
  }, [categories]);

  useEffect(() => {
    if (activeCategory && allTickets)
      if (activeCategory.id === "null") {
        console.log("null");
        setTicketsData(
          allTickets.filter(
            (ticket: ISupportTicket) => ticket.categoryId === null
          )
        );
      } else {
        setTicketsData(
          allTickets.filter(
            (ticket: ISupportTicket) => ticket.categoryId === activeCategory.id
          )
        );
      }

    // setFilter("");
  }, [activeCategory, allTickets]);

  return (
    <div className={cls.root}>
      {confirmationDeleteCategory && (
        <CallToActionPopUp
          message='Are you sure you want to delete this category?'
          onConfirm={handleDeleteCategory}
          onDeny={() => [
            setCategoryToEdit(null),
            showConfirmationDeleteCategory(false),
          ]}
        />
      )}
      {editCategoryForm && (
        <EditCategory
          width={400}
          category={categoryToEdit}
          onSave={handleEditCategory}
          onDeny={() => showEditCategoryForm(false)}
        />
      )}
      {addCategoryForm && (
        <AddNewCategory
          width={400}
          onSave={handleSaveNewCategory}
          onDeny={() => showAddCategoryForm(false)}
        />
      )}
      {successConfirmationSaveCategory && (
        <ConfirmationPopUp
          type='success'
          message='Support category was saved sucessfully!'
          onClick={() => showSuccessConfirmationSaveCategory(false)}
        />
      )}

      {successConfirmationDeleteCategory && (
        <ConfirmationPopUp
          type='success'
          message='Support category was deleted sucessfully!'
          onClick={() => showSuccessConfirmationDeleteCategory(false)}
        />
      )}

      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />
        <div className={cls.container}>
          {(isLoadingAllTickets || isLoadingCategories || isLoading) && (
            <Loading />
          )}
          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Support Tickets</div>
              <div className={cls.subtitle}>
                Lorem ipsum dolor amet. Lorem ipsum dolor amet.
              </div>
            </div>
            <SearchInput
              customPlaceholder={"Search case number"}
              onValueChange={handleFilterTickets}
              width={320}
              height={55}
            />
          </div>

          <div className={cls.contents}>
            <div className={cls.categories}>
              <div className={cls.row}>
                <div
                  className={cls.add}
                  onClick={() => showAddCategoryForm(true)}>
                  <div className={cls.body}>
                    <div className={cls.icon}></div>
                    <div className={cls.label}>Add New Category</div>
                  </div>
                </div>
              </div>
              <div className={cls.row}>
                {categories?.map(
                  (category: SupportTicketCategory, index: number) => (
                    <div
                      key={index + 1}
                      className={`${cls.item} ${
                        category.id === activeCategory?.id ? cls.active : ""
                      }`}>
                      <div className={cls.body}>
                        <div
                          className={cls.label}
                          onClick={() => setActiveCategory(category)}>
                          {category.name}
                        </div>
                        <div className={cls.actions}>
                          <span
                            onClick={() => [
                              setCategoryToEdit(category),
                              showEditCategoryForm(true),
                            ]}>
                            Edit
                          </span>
                          <span
                            onClick={() => [
                              setCategoryToEdit(category),
                              showConfirmationDeleteCategory(true),
                            ]}>
                            Delete
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                )}
                <div
                  key={0}
                  className={`${cls.item} ${
                    "null" === activeCategory?.id ? cls.active : ""
                  }`}>
                  <div className={cls.body}>
                    <div
                      className={cls.label}
                      onClick={() =>
                        setActiveCategory({
                          id: "null",
                          name: "NO CATEGORY",
                          isArchived: false,
                        })
                      }>
                      NO CATEGORY
                    </div>
                    <div className={cls.actions}>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {ticketsData.length > 0 && (
              <div className={cls.tickets}>
                <div className={cls.filterBar}>
                  <div className={cls.headRow}>
                    <span className={cls.caseId}>Case id</span>
                    <span className={cls.user}>
                      <span>User</span>
                    </span>
                    <span className={cls.subject}>Subject</span>
                  </div>
                  <div className={cls.headRow}>
                    <span className={cls.status}>Status</span>
                    <span className={cls.priority}>Priority</span>
                    <span className={cls.department}>Department</span>
                    <span className={cls.date}>Date</span>
                  </div>
                  <span className={cls.icon}></span>
                </div>

                {(filter.length > 2
                  ? ticketsData.filter((ticket: ISupportTicket) =>
                      matchesFilter(ticket, filter.toLowerCase())
                    )
                  : ticketsData
                )?.map((ticket: ISupportTicket, index: number) => (
                  <TicketListItem
                    key={ticket.id}
                    data={ticket}
                    userList={userList}
                    departments={categories}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
