import cls from "./AddVideos.module.scss";
import AppHeader from "../../../components/appHeader/AppHeader";
import SidebarMenu from "../../../components/sidebarMenu/SidebarMenu";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CollapsedSidebarContext } from "../../../context/collapsedSidebarProvider";
import MainBtn from "../../../components/common/buttons/mainBtn/MainBtn";
import FormInput from "../../../components/common/formInput/FormInput";
import DeleteIcon from "../../../assets/icons/delete@2x.png";
import { useMutation, useQueryClient } from "react-query";
import Loading from "../../../components/common/loading/Loading";
import ConfirmationPopUp from "../../../components/common/confirmationPopUp/ConfirmationPopUp";

import { UrlParams } from "../../../interfaces/UrlParams";
import {
  saveMultipleVideos,
  useVideos,
  VideoToSave,
} from "../../../api/videos/videosService";
import { useWeeks } from "../../../api/weeks/weeksService";
import { IWeek } from "../../../interfaces/IWeek";

const AddVideos: React.FC = () => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, termId, weekId } = routeParameters;
  const queryClient = useQueryClient();
  const { collapsed } = useContext(CollapsedSidebarContext);
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const videoInputRef = useRef(null);
  const { data: weeksData } = useWeeks(courseId, termId);
  const { data: videosData } = useVideos(courseId, termId, weekId);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [currentWeekData, setCurrentWeekData] = useState<IWeek | null>(null);
  const [videosToSave, setvideosToSave] = useState<VideoToSave[]>(
    [] as VideoToSave[]
  );
  const [successConfirmation, showSuccessConfirmation] =
    useState<boolean>(false);

  const saveVideosRQ = useMutation(saveMultipleVideos, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("videos saved successfully!");
      setvideosToSave([]);
      queryClient.invalidateQueries(["videos", courseId, termId, weekId]);
      showSuccessConfirmation(true);
    },
    onError: (err) => {
      console.log("error videos saved", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  //get video duration
  const getVideoDuration = async (file: File) => {
    const fileCallbackToPromise = (fileObj: HTMLVideoElement) => {
      return Promise.race([
        new Promise((resolve: (val: unknown) => void) => {
          if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve;
          else fileObj.onloadedmetadata = resolve;
        }),
      ]);
    };
    const objectUrl: string = URL.createObjectURL(file);
    const videoElem: HTMLVideoElement = document.createElement("video");
    videoElem.src = objectUrl;

    await fileCallbackToPromise(videoElem);

    const duration: number = videoElem.duration;
    videoElem.remove();
    return duration;
  };

  // handle input videos
  const handleOnchangeInputVideos = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setIsLoading(true);
    const files = event.target.files;
    if (files && currentWeekData) {
      const videoFiles = Array.from(files);

      let newvideosToSave: VideoToSave[] = [];

      for (const file of videoFiles) {
        const duration = await getVideoDuration(file);

        const videoToSave: VideoToSave = {
          videoFile: file,
          duration: Math.floor(duration),
          backgroundImgFile: null,
          videoTitle: file.name,
          courseId,
          termId,
          weekId,
          parentFolderUri: currentWeekData.metadata.folderUri,
        };

        newvideosToSave.push(videoToSave);
      }

      setvideosToSave([...videosToSave, ...newvideosToSave]);
      setIsLoading(false);
    }
  };
  // handle video thimbnails
  const handleOnchangeVideoThumbnails = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const files = event.target.files;

    if (files) {
      const index = parseInt(event.currentTarget.id);
      const file = files[0];

      setvideosToSave(
        videosToSave.map((video: VideoToSave, i: number) => {
          if (i === index) return { ...video, backgroundImgFile: file };
          return video;
        })
      );
    }
  };
  // handle video titles
  const handleChangeVideoTitle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const videoIdx = parseInt(event.target.id.split("_")[1]);
    const textValue: string = event.target.value;
    setvideosToSave(
      videosToSave.map((video: VideoToSave, i: number) => {
        if (i === videoIdx) return { ...video, videoTitle: textValue };
        return video;
      })
    );
  };
  // handle delete video from list to upload
  const handleDeleteVideoFromList = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const videoIdx: number = parseInt(e.currentTarget.id.split("_")[1]);

    const newvideosToSaveState = videosToSave.filter(
      (video, i) => i !== videoIdx
    );

    setvideosToSave(newvideosToSaveState);
  };

  const handleSaveVideos = (
    e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    e.preventDefault();

    if (videosToSave.length !== 0 && videosData) {
      setIsLoading(true);
      const payload = {
        videoList: videosToSave,
        startingIndex: videosData.length,
      };
      console.log(payload);
      saveVideosRQ.mutate(payload);
    }
  };

  useEffect(() => {
    if (weeksData) {
      const currentWeekData = weeksData.find(
        (week: IWeek) => week.id === weekId
      );
      setCurrentWeekData(currentWeekData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeksData]);

  return (
    <div className={cls.root}>
      {successConfirmation && (
        <ConfirmationPopUp
          type='success'
          message='Videos saved!'
          onClick={() => navigate(`/courses/${courseId}/${termId}/${weekId}`)}
        />
      )}
      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />

        <div className={cls.container}>
          {isLoading && <Loading />}

          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Add Videos</div>
              <div className={cls.subtitle}>Lorem ipsum dolor amet.</div>
            </div>
            <MainBtn
              label='Save'
              transparent={false}
              onClick={handleSaveVideos}
            />
          </div>

          <div className={cls.contents}>
            <div className={cls.form}>
              <form action=''>
                <div className={cls.row}>
                  <div className={cls.title}>Upload Video/s</div>
                  <div className={cls.fields}>
                    <div className={cls.field}>
                      <div className={cls.videoUploader}>
                        <label
                          htmlFor='videoFileInput'
                          role='button'
                          className={cls.fileUpload}>
                          Click to upload one or multiple videos
                        </label>
                        <input
                          // disabled={disabled}
                          ref={videoInputRef}
                          id='videoFileInput'
                          type='file'
                          name='videos'
                          multiple={true}
                          accept='video/mp4, video/mov'
                          onChange={handleOnchangeInputVideos}
                        />
                      </div>
                      {/* <p className={cls.error}>{`${
                        errors ? "Video files are required!" : ""
                      }`}</p> */}
                    </div>
                  </div>
                </div>
              </form>

              <div className={cls.videoList}>
                {videosToSave.map((video: VideoToSave, index: number) => {
                  inputRefs.current[index] =
                    inputRefs.current[index] || React.createRef();
                  const videoSrc = URL.createObjectURL(video.videoFile);

                  return (
                    <div key={index} className={cls.video}>
                      <div className={cls.heading}>{`Video  ${index + 1}`}</div>
                      <div className={cls.body}>
                        <div className={cls.left}>
                          <div
                            className={cls.delete}
                            id={`id_${index}`}
                            onClick={handleDeleteVideoFromList}>
                            <img src={DeleteIcon} alt='' />
                          </div>
                          <video src={videoSrc} />
                        </div>
                        <div className={cls.right}>
                          <div className={cls.videoName}>
                            <FormInput
                              key={index}
                              id={`videoTitle_${index}`}
                              maxLength={60}
                              type='text'
                              label='title'
                              // onChange={handleChangeVideoTitle}
                              onBlur={handleChangeVideoTitle}
                              defaultValue={video.videoFile.name}
                            />
                          </div>
                          <div className={cls.bgImg}>
                            <div className={cls.label}>Background Image</div>
                            <label
                              htmlFor={`${index}`}
                              role='button'
                              className={cls.fileUpload}>
                              Click to upload file
                            </label>
                            <input
                              ref={inputRefs.current[index]}
                              id={`${index}`}
                              type='file'
                              name={`${index}`}
                              multiple={false}
                              accept='image/*'
                              // onBlur={onBlur}
                              onChange={handleOnchangeVideoThumbnails}
                            />

                            {video.backgroundImgFile && (
                              <img
                                src={URL.createObjectURL(
                                  video.backgroundImgFile
                                )}
                                alt=''
                              />
                            )}

                            <div className={cls.thumbnailName}>
                              {video.backgroundImgFile
                                ? video.backgroundImgFile.name
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className={cls.actions}>
                <MainBtn
                  disabled={isLoading}
                  label='Back'
                  transparent={true}
                  width={130}
                  height={44}
                  onClick={() =>
                    navigate(`/courses/${courseId}/${termId}/${weekId}`)
                  }
                />

                <div
                  className={cls.save}
                  onClick={handleSaveVideos}
                  style={{ width: 180, height: 44 }}>
                  Save
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVideos;
