import cls from "./EditTerm.module.scss";
import AppHeader from "../../../components/appHeader/AppHeader";
import SidebarMenu from "../../../components/sidebarMenu/SidebarMenu";

import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { CollapsedSidebarContext } from "../../../context/collapsedSidebarProvider";
import MainBtn from "../../../components/common/buttons/mainBtn/MainBtn";
import FormInput from "../../../components/common/formInput/FormInput";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextareaInput from "../../../components/common/textareaInput/TextareaInput";
import BackgroundImageUploader from "../../../components/common/backgroundImageUploader/BackgroundImageUploader";

import { useMutation, useQueryClient } from "react-query";

import Loading from "../../../components/common/loading/Loading";
import ConfirmationPopUp from "../../../components/common/confirmationPopUp/ConfirmationPopUp";
import {
  deleteTerm,
  updateTerm,
  UpdateTermPayload,
  useCourseTerms,
} from "../../../api/terms/termsService";

import { ITerm } from "../../../interfaces/ITerm";
import CallToActionPopUp from "../../../components/common/callToActionPopUp/CallToActionPopUp";
import { useWeeks } from "../../../api/weeks/weeksService";
import TermWeeksEditor from "../../../components/course/termWeeksEditor/TermWeeksEditor";
import { UrlParams } from "../../../interfaces/UrlParams";

type FormInputs = {
  title: string;
  description: string;
  backgroundImage: File;
};
const schema = yup.object().shape({
  title: yup.string().required("Course title is required!"),
  description: yup.string().required("Course description is required!"),
  backgroundImage: yup.mixed(),
});

const EditTerm: React.FC = () => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, termId } = routeParameters;

  const { data: terms, isLoading: isLoadingTerms } = useCourseTerms(courseId);
  const { data: weeks, isLoading: isLoadingWeeks } = useWeeks(courseId, termId);
  const queryClient = useQueryClient();
  const { collapsed } = useContext(CollapsedSidebarContext);
  const navigate = useNavigate();

  const [termData, setTermData] = useState<ITerm | null>(null);

  const [deleteTermPopup, showDeleteTermPopup] = useState<boolean>(false);
  const [successConfirmationDelete, showSuccessConfirmationDelete] =
    useState<boolean>(false);
  const [successConfirmationUpdate, showSuccessConfirmationUpdate] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  ///////////////// handle submit form
  const { handleSubmit, control, reset } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const updateTermRQ = useMutation(updateTerm, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("term updated successfully!");
      queryClient.invalidateQueries(["terms", courseId]);
      showSuccessConfirmationUpdate(true);
      reset();
    },
    onError: (err) => {
      console.log("error updating term...", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });
  const onSubmit = (data: FormInputs) => {
    console.log(data);

    if (termData) {
      const payload: UpdateTermPayload = {
        term: data,
        existingTerm: termData,
        courseId,
        orderIndex: termData.orderIndex,
      };
      updateTermRQ.mutate(payload);
    }
  };

  // handle delete term
  const deleteTermRQ = useMutation(deleteTerm, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("term deleted successfully!");
      queryClient.invalidateQueries(["terms", courseId]);
      showSuccessConfirmationDelete(true);
    },
    onError: (err) => {
      console.log("error deleting course", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const handleConfirmDeleteTerm = () => {
    if (termData) {
      deleteTermRQ.mutate({ courseId, termId: termData.id });
      showDeleteTermPopup(false);
    }
  };

  useEffect(() => {
    if (terms) {
      const termData = terms.find((term: ITerm) => term.id === termId);
      setTermData(termData);
    }
  }, [terms, termId]);

  return (
    <div className={cls.root}>
      {deleteTermPopup && (
        <CallToActionPopUp
          message='Are you sure you want to delete this term?'
          onDeny={() => showDeleteTermPopup(false)}
          onConfirm={() => handleConfirmDeleteTerm()}
        />
      )}
      {successConfirmationUpdate && (
        <ConfirmationPopUp
          type='success'
          message='Term updated!'
          onClick={() => showSuccessConfirmationUpdate(false)}
        />
      )}
      {successConfirmationDelete && (
        <ConfirmationPopUp
          type='success'
          message='Term deleted!'
          onClick={() => navigate(-1)}
        />
      )}
      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />

        <div className={cls.container}>
          {(isLoading || isLoadingTerms || isLoadingWeeks) && <Loading />}

          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Edit Term</div>
              <div className={cls.subtitle}>{termData?.title}</div>
            </div>
            <MainBtn
              label='Back'
              transparent={true}
              onClick={() => navigate(-1)}
            />
          </div>

          <div className={cls.contents}>
            {termData && (
              <div className={cls.form}>
                <form action=''>
                  <div className={cls.row}>
                    <div className={cls.title}>Term details</div>
                    <div className={cls.fields}>
                      <div className={cls.field}>
                        <Controller
                          name='title'
                          defaultValue={termData.title}
                          control={control}
                          render={({
                            field: { onChange, onBlur, ref },
                            fieldState: { isTouched, isDirty, error },
                          }) => (
                            <FormInput
                              type='text'
                              label='Title'
                              defaultValue={termData.title}
                              maxLength={60}
                              onChange={onChange}
                              onBlur={onBlur}
                              inputRef={ref}
                              isTouched={isTouched}
                              isDirty={isDirty}
                              error={error}
                            />
                          )}
                        />
                      </div>
                      <div className={cls.field}>
                        <Controller
                          name='description'
                          defaultValue={termData.description}
                          control={control}
                          rules={{ required: true }}
                          render={({
                            field: { onChange, onBlur, ref },
                            fieldState: { isTouched, isDirty, error },
                          }) => (
                            <TextareaInput
                              label='Description'
                              defaultValue={termData.description}
                              maxLength={1100}
                              onChange={onChange}
                              onBlur={onBlur}
                              inputRef={ref}
                              isTouched={isTouched}
                              isDirty={isDirty}
                              error={error}
                            />
                          )}
                        />
                      </div>
                      <div className={cls.field}>
                        <Controller
                          name='backgroundImage'
                          control={control}
                          defaultValue={undefined}
                          rules={{ required: true }}
                          render={({
                            field: { onChange, onBlur, ref, value },
                            fieldState: { isTouched, isDirty, error },
                          }) => (
                            <BackgroundImageUploader
                              defaultValue={termData.thumbnailUrl}
                              label='Background Image'
                              value={value}
                              onChange={(event) => {
                                const val = event.target.files?.[0];
                                return onChange(val);
                              }}
                              onBlur={onBlur}
                              inputRef={ref}
                              isTouched={isTouched}
                              isDirty={isDirty}
                              error={error}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </form>

                <div className={cls.weeks}>
                  <div className={cls.title}>Weeks</div>
                  <TermWeeksEditor
                    weeksData={weeks}
                    courseId={courseId}
                    termId={termData.id}
                  />
                </div>
                <div className={cls.actions}>
                  <MainBtn
                    disabled={isLoading}
                    label='Back'
                    transparent={true}
                    width={130}
                    height={44}
                    onClick={() => navigate(`/courses/${courseId}`)}
                  />

                  <div
                    className={cls.delete}
                    onClick={() => showDeleteTermPopup(true)}
                    style={{ width: 180, height: 44 }}>
                    Delete
                  </div>
                  <div
                    className={cls.save}
                    onClick={handleSubmit(onSubmit)}
                    style={{ width: 180, height: 44 }}>
                    Save
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTerm;
