import cls from "./TermWeekSelector.module.scss";

import React, { useRef, useState } from "react";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import Icon from "../../../assets/icons/Icon";
import { useParams } from "react-router-dom";
import { UrlParams } from "../../../interfaces/UrlParams";
import { ITerm } from "../../../interfaces/ITerm";
import WeekList from "./WeekList";
import { useCourseTerms } from "../../../api/terms/termsService";

type Props = {
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const TermWeekSelector: React.FC<Props> = ({ onSelect }) => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId } = routeParameters;

  const { data: terms, isLoading: isLoadingTerms } = useCourseTerms(courseId);

  const refField = useRef(null);
  const [weekList, showWeekList] = useState<boolean>(false);
  const [selectedWeek, setSelectedWeek] = useState<string>();

  const handleClickOutsideMenu = (e: MouseEvent) => {
    showWeekList(false);
  };
  useOnClickOutside(refField, handleClickOutsideMenu);

  /////
  const handleShowWeekList = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    showWeekList(!weekList);
  };
  /////
  const handleSelectTermWeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    showWeekList(false);
    setSelectedWeek(e.target.id);
    onSelect(e);
  };

  // useEffect(() => {
  //   showWeekList(false);
  //   if (weeks && weeks.length > 0 && weekId)
  //     setSelectedWeek(weeks?.find((week: IWeek) => week.id === weekId)?.title);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [weekId, weeks]);

  // if (isLoadingTerms) return null;

  return (
    <div className={cls.root} ref={refField}>
      <div className={cls.select} onClick={handleShowWeekList}>
        <Icon
          icon='ArrowLeft'
          viewBox='0 0 320 512'
          size={18}
          className={weekList ? cls.expanded : ""}
        />
        <label className={cls.label}>Select Term/Week</label>
        <div className={cls.value}>{selectedWeek}</div>
      </div>

      <div className={`${cls.options} ${weekList ? cls.show : ""}`}>
        {terms?.map((term: ITerm, termIdx: number) => {
          return (
            <div key={termIdx} className={cls.termTitle}>
              <div>{term.title}</div>
              <WeekList
                _termId={term.id}
                selectedWeek={selectedWeek}
                onSelect={handleSelectTermWeek}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TermWeekSelector;
