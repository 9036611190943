import { useQuery, useQueryClient, QueryFunctionContext } from "react-query";
import { ITerm } from "../../interfaces/ITerm";

import { cacheTimes } from "../cacheTimesRQ";
import { v4 as uuidV4 } from "uuid";
import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../requestApi";
import StorageService from "../storage/storageService";
import VimeoService from "../vimeo/vimeoService";
import { IVimeoMetadata } from "../../interfaces/IVimeoMetadata";

const fetchCourseTerms = ({ queryKey }: QueryFunctionContext) => {
  const courseId: string = queryKey[1] as string;
  return requestApi({
    url: ApiEndpoints.MODULES_FOR_COURSE,
    method: "POST",
    data: { courseId: courseId },
  });
};

export const useCourseTerms = (courseId: string, enabled?: boolean) => {
  const queryClient = useQueryClient();

  return useQuery(["terms", courseId], fetchCourseTerms, {
    cacheTime: cacheTimes.COURSE_TERMS_CACHE,
    staleTime: cacheTimes.COURSE_TERMS_STALE,
    initialData: queryClient.getQueryData(["terms", courseId]),
    refetchOnWindowFocus: false,
    enabled: enabled,
    select: (data) =>
      data.data.modules.sort(
        (a: ITerm, b: ITerm) => a.orderIndex - b.orderIndex
      ),
    onError: (err) => {
      console.log(err);
    },
  });
};

const saveTerm = (term: ITerm, courseId: string) => {
  return requestApi({
    url: ApiEndpoints.SAVE_MODULE,
    method: "POST",
    data: { ...term, courseId },
  });
};

export type CreateNewTermPayload = {
  backgroundImage: File | undefined;
  title: string;
  description: string;
  semester: string;
  courseId: string;
  metadata: IVimeoMetadata;
  orderIndex: number;
};

export const createNewTerm = async (payload: CreateNewTermPayload) => {
  const termId: string = uuidV4();
  let thumbnailUrl: string = "";

  // upload term background image
  if (payload.backgroundImage)
    thumbnailUrl = await StorageService.UploadTermThumbnail(
      payload.backgroundImage,
      payload.courseId,
      termId
    );

  // create term video folder
  const folderUri: string = (
    await VimeoService.CreateFolder(termId, payload.metadata.folderUri)
  ).folderUri;

  const newTerm: ITerm = {
    id: termId,
    description: payload.description,
    title: payload.title,
    semester: payload.semester,
    thumbnailUrl,
    metadata: {
      folderUri,
    },
    orderIndex: payload.orderIndex,
  };

  // save term
  await saveTerm(newTerm, payload.courseId);
};

export type UpdateTermPayload = {
  term: {
    backgroundImage: File | undefined;
    description: string;
    title: string;
  };
  existingTerm: ITerm;
  courseId: string;
  orderIndex: number;
};

export const updateTerm = async (payload: UpdateTermPayload) => {
  let thumbnailUrl: string = payload.existingTerm.thumbnailUrl;

  // upload course background image if is new one
  if (payload.term.backgroundImage)
    thumbnailUrl = await StorageService.UploadTermThumbnail(
      payload.term.backgroundImage,
      payload.courseId,
      payload.existingTerm.id
    );

  // create new update term obj
  const newTerm: ITerm = {
    id: payload.existingTerm.id,
    description: payload.term.description,
    title: payload.term.title,
    semester: payload.existingTerm.semester,
    thumbnailUrl,
    metadata: payload.existingTerm.metadata,
    orderIndex: payload.orderIndex,
  };

  // save term
  await saveTerm(newTerm, payload.courseId);
};

type DeleteTermPayload = {
  courseId: string;
  termId: string;
};

export const deleteTerm = (payload: DeleteTermPayload) => {
  return requestApi({
    url: ApiEndpoints.DELETE_MODULE,
    method: "POST",
    data: payload,
  });
};
