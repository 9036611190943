import { useQuery, useQueryClient } from "react-query";
import { ILecturerDetails } from "../../interfaces/ILecturerDetails";
import { cacheTimes } from "../cacheTimesRQ";
import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../requestApi";
import { v4 as uuidV4 } from "uuid";
import StorageService from "../storage/storageService";

const fetchLecturers = () => {
  return requestApi({
    url: ApiEndpoints.GET_LECTURERS,
    method: "POST",
    data: {},
  });
};

export const useLecturers = (enabled?: boolean) => {
  const queryClient = useQueryClient();

  return useQuery(["lecturers"], fetchLecturers, {
    cacheTime: cacheTimes.LECTURERS_DATA_CACHE,
    staleTime: cacheTimes.LECTURERS_DATA_STALE,
    initialData: queryClient.getQueryData(["lecturers"]),
    refetchOnWindowFocus: true,
    enabled: enabled,
    select: (data) => data.data.lecturers,
    onError: (err) => {
      console.log(err);
    },
  });
};

export type CreateNewLecturerPayload = {
  firstName: string;
  lastName: string;
  description: string;
  avatar: File | null;
};

export const createNewLecturer = async (payload: CreateNewLecturerPayload) => {
  const id: string = uuidV4();
  let lecturerAvatarUrl: string = "";

  // upload lecturer image
  if (payload.avatar)
    lecturerAvatarUrl = await StorageService.UploadLecturerAvatar(
      payload.avatar,
      id
    );

  const newLecturer: ILecturerDetails = {
    name: `${payload.firstName} ${payload.lastName}`,
    avatarUrl: lecturerAvatarUrl,
    id,
  };

  // save term
  await saveLecturer(newLecturer);
};

const saveLecturer = (lecturer: ILecturerDetails) => {
  return requestApi({
    url: ApiEndpoints.SAVE_LECTURER,
    method: "POST",
    data: lecturer,
  });
};

// const deleteLecturer = (lecturerId: string) => {
//   return requestApi({
//     url: ApiEndpoints.DELETE_LECTURER,
//     method: "POST",
//     data: { lecturerId },
//   });
// };
