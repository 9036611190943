import {
  getDownloadURL,
  ref,
  StorageReference,
  uploadBytes,
  UploadMetadata,
  UploadResult,
} from "firebase/storage";

import { storage, auth } from "../firebase";
import { v4 as uuidV4 } from "uuid";

class StorageServiceInstance {
  public async UploadProfilePicture(
    picture: File,
    userId: string
  ): Promise<string> {
    const fileMetadata: UploadMetadata = {
      customMetadata: {
        userId: userId,
        usedFor: "PROFILE_PICTURE",
      },
      cacheControl: "public,max-age=604800",
      contentType: picture.type,
    };

    const homeworkRef: StorageReference = ref(
      storage,
      `Users/ProfilePictures/${userId}/${uuidV4()}`
    );

    const uploadResult: UploadResult = await uploadBytes(
      homeworkRef,
      picture,
      fileMetadata
    );

    return getDownloadURL(uploadResult.ref);
  }

  public async UploadLecturerAvatar(
    lecturerBackgroundImage: File,
    lecturerId: string
  ): Promise<string> {
    const fileMetadata: UploadMetadata = {
      customMetadata: {
        userId: auth.currentUser?.uid as string,
        usedFor: "LECTURER_AVATAR",
      },
      cacheControl: "public,max-age=604800",
      contentType: lecturerBackgroundImage.type,
    };

    const lecturerAvatarRef: StorageReference = ref(
      storage,
      `Users/LecturerAvatars/${lecturerId}/${uuidV4()}`
    );

    const uploadResult: UploadResult = await uploadBytes(
      lecturerAvatarRef,
      lecturerBackgroundImage,
      fileMetadata
    );

    return getDownloadURL(uploadResult.ref);
  }

  public async UploadCourseThumbnail(
    imageFile: File,
    courseId: string
  ): Promise<string> {
    const fileMetadata: UploadMetadata = {
      customMetadata: {
        uploadedBy: auth.currentUser?.uid as string,
        timestamp: String(new Date().getTime()),
        courseId,
        usedFor: "COURSE_THUMBNAIL",
      },
      cacheControl: "public,max-age=604800",
      contentType: imageFile.type,
    };

    const courseRef: StorageReference = ref(
      storage,
      `Thumbnails/Courses/${courseId}/${uuidV4()}`
    );

    const storageReference: UploadResult = await uploadBytes(
      courseRef,
      imageFile,
      fileMetadata
    );

    return getDownloadURL(storageReference.ref);
  }

  public async UploadCourseReferences(
    imageFile: File,
    courseId: string
  ): Promise<string> {
    const fileMetadata: UploadMetadata = {
      customMetadata: {
        uploadedBy: auth.currentUser?.uid as string,
        timestamp: String(new Date().getTime()),
        courseId,
        usedFor: "COURSE_REFERENCES",
      },
      cacheControl: "public,max-age=604800",
      contentType: imageFile.type,
    };

    const courseRef: StorageReference = ref(
      storage,
      `Thumbnails/Courses/${courseId}/References/${uuidV4()}`
    );

    const storageReference: UploadResult = await uploadBytes(
      courseRef,
      imageFile,
      fileMetadata
    );

    return getDownloadURL(storageReference.ref);
  }

  // public async UploadCourseSyllabusPDFs(
  //   pdfFile: File,
  //   courseId: string
  // ): Promise<void> {
  //   try {

  //     const fileMetadata: UploadMetadata = {
  //       customMetadata: {
  //         uploadedBy: auth.currentUser?.uid as string,
  //         timestamp: String(new Date().getTime()),
  //         courseId,
  //         usedFor: "COURSE_SYLLABUS",
  //       },
  //       cacheControl: "public,max-age=604800",
  //       contentType: pdfFile.type,
  //     };

  //     const courseRef: StorageReference = ref(
  //       storage,
  //       `Syllabuses/${courseId}/${pdfFile.name}`
  //     );

  //     await uploadBytes(courseRef, pdfFile, fileMetadata);

  //   } catch (error) {}
  // }

  public async uploadCourseSyllabusPDFs(
    pdfFile: File,
    courseId: string
  ): Promise<void> {
    try {
      if (!pdfFile || !courseId) {
        throw new Error("Missing required parameters");
      }

      const fileMetadata: UploadMetadata = {
        customMetadata: {
          uploadedBy: auth.currentUser?.uid as string,
          timestamp: String(new Date().getTime()),
          courseId,
          usedFor: "COURSE_SYLLABUS",
        },
        cacheControl: "public,max-age=604800",
        contentType: pdfFile.type || "application/pdf",
      };

      const courseRef: StorageReference = ref(
        storage,
        `Syllabuses/${courseId}/${pdfFile.name}`
      );

      await uploadBytes(courseRef, pdfFile, fileMetadata);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async UploadTermThumbnail(
    imageFile: File,
    courseId: string,
    termId: string
  ): Promise<string> {
    const fileMetadata: UploadMetadata = {
      customMetadata: {
        uploadedBy: auth.currentUser?.uid as string,
        timestamp: String(new Date().getTime()),
        courseId,
        termId,
        usedFor: "MODULE_THUMBNAIL",
      },
      cacheControl: "public,max-age=604800",
      contentType: imageFile.type,
    };

    const moduleRef: StorageReference = ref(
      storage,
      `Thumbnails/Courses/${courseId}/Terms/${termId}/${uuidV4()}`
    );

    const storageReference: UploadResult = await uploadBytes(
      moduleRef,
      imageFile,
      fileMetadata
    );

    return getDownloadURL(storageReference.ref);
  }

  public async UploadVideoThumbnail(
    imageFile: File,
    courseId: string,
    termId: string,
    weekId: string,
    videoId: string
  ): Promise<string> {
    const fileMetadata: UploadMetadata = {
      customMetadata: {
        uploadedBy: auth.currentUser?.uid as string,
        timestamp: String(new Date().getTime()),
        courseId,
        termId,
        weekId,
        videoId,
        usedFor: "VIDEO_THUMBNAIL",
      },
      cacheControl: "public,max-age=604800",
      contentType: imageFile.type,
    };

    const videoRef: StorageReference = ref(
      storage,
      `Thumbnails/Courses/${courseId}/Terms/${termId}/Weeks/${weekId}/Videos/${videoId}/${uuidV4()}`
    );

    const storageReference: UploadResult = await uploadBytes(
      videoRef,
      imageFile,
      fileMetadata
    );

    return getDownloadURL(storageReference.ref);
  }
}

const StorageService: StorageServiceInstance = new StorageServiceInstance();

export default StorageService;
