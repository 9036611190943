import { useQuery, useQueryClient } from "react-query";
import { IMaintenance } from "../../interfaces/IMaintenance";

import { cacheTimes } from "../cacheTimesRQ";
import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../requestApi";

const fetchScheduledMaintenance = () => {
  return requestApi({
    url: ApiEndpoints.GET_SCHEDULE_MAINTENANCE_LIST,
    method: "GET",
    data: null,
  });
};

export const useScheduledMaintenance = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["scheduled-maintenance"], fetchScheduledMaintenance, {
    refetchOnWindowFocus: false,
    enabled: enabled,
    cacheTime: cacheTimes.SCHEDULED_MAINTENANCE_CACHE,
    staleTime: cacheTimes.SCHEDULED_MAINTENANCE_STALE,
    initialData: queryClient.getQueryData(["scheduled-maintenance"]),
    select: (data) =>
      data.data.maintenance.sort(
        (a: IMaintenance, b: IMaintenance) => a.startTime - b.startTime
      ),
    onError: (err) => {
      console.log(err);
    },
  });
};

export const saveMaintenance = (maintenance: IMaintenance) => {
  return requestApi({
    url: ApiEndpoints.SAVE_MAINTENANCE,
    method: "POST",
    data: { maintenance },
  });
};

export const deleteMaintenance = (maintenanceId: string) => {
  return requestApi({
    url: ApiEndpoints.DELETE_MAINTENANCE,
    method: "post",
    data: { maintenanceId },
  });
};
