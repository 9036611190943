import cls from "./ResultsPerPageSelector.module.scss";
import React, { useRef, useState } from "react";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import Icon from "../../../assets/icons/Icon";

type Props = {
  width?: number;
  height?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue: number;
};

const ResultsPerPageSelector: React.FC<Props> = ({
  width,
  height,
  onChange,
  defaultValue,
}) => {
  const refField = useRef(null);
  const [list, showList] = useState<boolean>(false);

  const handleClickOutsideMenu = (e: MouseEvent) => {
    showList(false);
  };
  useOnClickOutside(refField, handleClickOutsideMenu);

  /////
  const handleShowCourseList = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    showList(!list);
  };
  /////
  const handleSelectVal = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    showList(false);
    onChange(e);
  };

  return (
    <div className={cls.root} style={{ width: width, height: height }}>
      <div className={cls.label}>Results per page</div>
      <div className={cls.wrapper} ref={refField}>
        <div className={cls.select} onClick={handleShowCourseList}>
          <Icon
            icon='ArrowLeft'
            viewBox='0 0 320 512'
            size={18}
            className={list ? cls.expanded : ""}
          />

          <div className={cls.value}>{defaultValue}</div>
        </div>

        <div className={`${cls.options} ${list ? cls.show : ""}`}>
          {[5, 10, 15, 20].map((val: number, index: number) => {
            return (
              <div key={index} className={cls.option}>
                <label htmlFor={val.toString()}>{val}</label>
                <input
                  name='select'
                  type='radio'
                  value={val}
                  id={val.toString()}
                  onChange={handleSelectVal}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ResultsPerPageSelector;
