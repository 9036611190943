import React, { useEffect, useRef, useState } from "react";
import { FieldError } from "react-hook-form/dist/types";
import cls from "./LecturerEditor.module.scss";
import { ILecturerDetails } from "../../../interfaces/ILecturerDetails";
import {
  createNewLecturer,
  useLecturers,
} from "../../../api/lecturer/lecturerService";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import Icon from "../../../assets/icons/Icon";
import AddNewLecturer from "../addNewLecturer/AddNewLecturer";
import { useMutation, useQueryClient } from "react-query";
import Loading from "../../common/loading/Loading";
import ConfirmationPopUp from "../../common/confirmationPopUp/ConfirmationPopUp";

type Props = {
  width?: number;
  height?: number;
  selectedLecturers: string[];
  onChange: (e: string[]) => void;
  error?: FieldError | undefined;
};

type FormInputs = {
  firstName: string;
  lastName: string;
  description: string;
  avatar: File | null;
};
const LecturersEditor: React.FC<Props> = ({
  width,
  height,
  selectedLecturers,
  onChange,
  error,
}) => {
  const refField = useRef(null);
  const queryClient = useQueryClient();
  const { data: lecturersData } = useLecturers();
  const [lecturerList, showLecturerList] = useState<boolean>(false);
  const [addNewLecturerForm, showAddNewLecturerForm] = useState<boolean>(false);
  const [successConfirmation, showSuccessConfirmation] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [activeLecturers, setActiveLecturers] =
    useState<string[]>(selectedLecturers);
  /////
  const handleClickOutsideMenu = (e: MouseEvent) => {
    showLecturerList(false);
  };
  useOnClickOutside(refField, handleClickOutsideMenu);
  /////
  const getLecturerById = (lecturerId: string) => {
    const lecturer = lecturersData?.find(
      (lecturer: ILecturerDetails) => lecturer.id === lecturerId
    );
    return lecturer;
  };
  /////
  const handleShowLecturerList = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    showLecturerList(!lecturerList);
  };
  /////
  const handleSelectLecturer = (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    const currentId = e.currentTarget.id;

    if (activeLecturers.indexOf(currentId) > -1) {
      const newActiveLecturersState = activeLecturers.filter(
        (id: string) => id !== currentId
      );
      setActiveLecturers(newActiveLecturersState);
    } else {
      setActiveLecturers([...activeLecturers, currentId]);
    }
  };

  const createNewLecturerRQ = useMutation(createNewLecturer, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("course updated successfully!");
      queryClient.invalidateQueries("lecturers");
      showSuccessConfirmation(true);
      // reset();
    },
    onError: (err) => {
      console.log("error creating lecturer...", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const handleSaveLecturer = (data: FormInputs) => {
    showAddNewLecturerForm(false);
    console.log(data);
    createNewLecturerRQ.mutate(data);
  };

  useEffect(() => {
    onChange(activeLecturers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLecturers]);

  return (
    <div className={cls.root} style={{ width: width, height: height }}>
      {successConfirmation && (
        <ConfirmationPopUp
          type='success'
          message='Lecturer created!'
          onClick={() => showSuccessConfirmation(false)}
        />
      )}

      {addNewLecturerForm && (
        <AddNewLecturer
          width={570}
          onSave={handleSaveLecturer}
          onDeny={() => showAddNewLecturerForm(false)}
        />
      )}

      {isLoading && <Loading />}
      {selectedLecturers && lecturersData && (
        <div className={cls.wrapper}>
          <div className={cls.row}>
            <div className={cls.lecturerSelect} ref={refField}>
              <div className={cls.select} onClick={handleShowLecturerList}>
                <Icon
                  icon='ArrowLeft'
                  viewBox='0 0 320 512'
                  size={18}
                  className={lecturerList ? cls.expanded : ""}
                />
                <label className={cls.label}>Select Lecturers</label>
                <div className={cls.value}>
                  {activeLecturers?.map((lecturerId: string, index: number) => (
                    <div key={index} className={cls.valueItem}>
                      <img
                        src={getLecturerById(lecturerId)?.avatarUrl || ""}
                        alt=''
                      />
                      {/* <span>{}</span> */}
                      {getLecturerById(lecturerId)?.name}
                    </div>
                  ))}
                </div>
              </div>

              <ul
                className={`${cls.options} ${lecturerList ? cls.show : ""}`}
                defaultValue={activeLecturers}>
                {lecturersData?.map(
                  (lecturer: ILecturerDetails, index: number) => {
                    return (
                      <li
                        className={`${cls.option} ${
                          activeLecturers?.indexOf(lecturer.id) > -1
                            ? cls.active
                            : ""
                        }`}
                        key={index}
                        id={lecturer.id}
                        onClick={handleSelectLecturer}>
                        <label htmlFor={lecturer.id}>
                          <img src={lecturer.avatarUrl || ""} alt='' />
                          {lecturer.name}
                        </label>
                        {/* <input
                        type='checkbox'
                        multiple
                        name='role'
                        id={lecturer.id}
                        value={lecturer.id}
                        // disabled={!canAssignRole(role.name)}
                        // onClick={() => handleRoleChange(role.name)}
                        onChange={() => console.log(activeLecturers)}
                      /> */}
                      </li>
                    );
                  }
                )}
              </ul>
              <p className={cls.error}>{`${error ? error.message : ""}`}</p>
            </div>

            <div
              className={cls.add}
              onClick={() => showAddNewLecturerForm(true)}>
              <div className={cls.body}>
                <div className={cls.icon}></div>
                <div className={cls.label}>Add New Lecturer</div>
              </div>
            </div>
          </div>
        </div>
      )}

      <p className={cls.error}>{`${error ? error.message : ""}`}</p>
    </div>
  );
};

export default LecturersEditor;
