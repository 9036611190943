import cls from "./Loading.module.scss";

const Loading: React.FC = () => {
  return (
    <div className={cls.root}>
      {/* <svg
        className={cls.loader}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 340 340'>
        <circle cx='170' cy='170' r='160' stroke='#cc33ff' />
        <circle cx='170' cy='170' r='135' stroke='#9d9ca0' />
        <circle cx='170' cy='170' r='110' stroke='#cc33ff' />
        <circle cx='170' cy='170' r='85' stroke='#9d9ca0' />
      </svg> */}
      <figure>
        <div className={`${cls.dot} ${cls.white}`}></div>
        <div className={cls.dot}></div>
        <div className={cls.dot}></div>
        <div className={cls.dot}></div>
        <div className={cls.dot}></div>
      </figure>
    </div>
  );
};

export default Loading;
