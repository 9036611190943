import React, { useContext, useState } from "react";
import AppHeader from "../../components/appHeader/AppHeader";
import MainBtn from "../../components/common/buttons/mainBtn/MainBtn";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";

import { CollapsedSidebarContext } from "../../context/collapsedSidebarProvider";
import cls from "./Maintenance.module.scss";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "../../components/common/formInput/FormInput";
// import DatePicker from "../../components/common/datePicker/DatePicker";
import TextareaInput from "../../components/common/textareaInput/TextareaInput";
import ComponentSelector from "../../components/maintenance/componentSelector/ComponentSelector";
import { useNavigate } from "react-router-dom";
import {
  deleteMaintenance,
  saveMaintenance,
  useScheduledMaintenance,
} from "../../api/maintenance/maintenanceService";
import { IMaintenance } from "../../interfaces/IMaintenance";
import Loading from "../../components/common/loading/Loading";
import CallToActionPopUp from "../../components/common/callToActionPopUp/CallToActionPopUp";
import ConfirmationPopUp from "../../components/common/confirmationPopUp/ConfirmationPopUp";
import { useMutation, useQueryClient } from "react-query";
import { v4 as uuidV4 } from "uuid";
import MaintenanceEditor from "../../components/maintenance/maintenanceEditor/MaintenanceEditor";
import LmsDatePicker from "../../components/common/lmsDatePicker/LmsDatePicker";

type FormInputs = {
  startDate: string;
  endDate: string;
  component: string;
  notes: string;
  message: string;
  isUndeterminedEndTime: boolean;
};

const schema = yup.object().shape({
  startDate: yup.string().required("Starting date is required!"),
  endDate: yup.string().when("isUndeterminedEndTime", {
    is: false,
    then: yup.string().required("Ending date is required!"),
    otherwise: yup.string(),
  }),
  component: yup.string().required("Component date is required!"),
  notes: yup.string().required("Notes are required!"),
  message: yup.string().required("Message is required!"),
  isUndeterminedEndTime: yup.boolean(),
});

const Maintenance: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { collapsed } = useContext(CollapsedSidebarContext);

  const [isUndeterminedEndTime, setIsUndeterminedEndTime] =
    useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [editMaintenanceForm, showEditMaintenanceForm] =
    useState<boolean>(false);
  const [maintenanceToEdit, setMaintenanceToEdit] = useState<string | null>(
    null
  );

  const [maintenanceToDelete, setMaintenanceToDelete] = useState<string | null>(
    null
  );

  const { data: maintenanceSchedules, isLoading: isLoadingMaintenance } =
    useScheduledMaintenance();

  const [
    successConfirmationCreateMaintenance,
    showSuccessConfirmationCreateMaintenance,
  ] = useState<boolean>(false);

  const [confirmationDeleteMaintenance, showConfirmationDeleteMaintenance] =
    useState<boolean>(false);
  const [
    successConfirmationDeleteMaintenance,
    showSuccessConfirmationDeleteMaintenance,
  ] = useState<boolean>(false);

  const timeFormatter = (tm: number | undefined) => {
    if (tm) {
      const d = new Date(tm);
      const output =
        d.getDate() +
        "/" +
        (d.getMonth() + 1) +
        "/" +
        d.getFullYear() +
        " at " +
        d.getHours() +
        ":" +
        (d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes());
      return output.toString();
    }

    return "undetermined";
  };

  ///////////////// handle submit form
  const { handleSubmit, control, register, watch, setValue } =
    useForm<FormInputs>({
      resolver: yupResolver(schema),
      defaultValues: {
        isUndeterminedEndTime: true,
      },
    });

  // const handleToggleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   // e.preventDefault();

  //   const val: boolean = e.target.checked;
  //   console.log(val);
  //   setIsUndeterminedEndTime(val);
  // };
  /////////////////
  const deleteMaintenanceRQ = useMutation(deleteMaintenance, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("maintenance deleted successfully!");
      queryClient.invalidateQueries(["scheduled-maintenance"]);
      showSuccessConfirmationDeleteMaintenance(true);
    },
    onError: (err) => {
      console.log("error maintenance deleted", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setMaintenanceToDelete(null);
      setIsLoading(false);
    },
  });
  const handleDeleteMaintenance = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!maintenanceToDelete) return;

    deleteMaintenanceRQ.mutate(maintenanceToDelete);
    showConfirmationDeleteMaintenance(false);
  };
  /////////////////

  const saveMaintenanceRQ = useMutation(saveMaintenance, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("new schedule maintenance created successfully!");
      queryClient.invalidateQueries(["scheduled-maintenance"]);

      showSuccessConfirmationCreateMaintenance(true);
    },
    onError: (err) => {
      console.log("error maintenance created", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const onSubmit = (data: FormInputs) => {
    let payload: IMaintenance = {
      id: uuidV4(),
      notes: data.notes,
      startTime: new Date(data.startDate).getTime(),
      isFullWebsiteMaintenance: true,
      isUndeterminedEndTime: data.isUndeterminedEndTime,
      components: ["All Website"],
      message: data.message,
    };

    if (!isUndeterminedEndTime)
      payload = {
        ...payload,
        endTime: new Date(data.endDate).getTime(),
      };

    saveMaintenanceRQ.mutate(payload);
  };

  const handleUpdateMaintenance = (payload: IMaintenance) => {
    console.log(payload);
    showEditMaintenanceForm(false);
    setMaintenanceToEdit(null);
    saveMaintenanceRQ.mutate(payload);
  };

  return (
    <div className={cls.root}>
      {successConfirmationCreateMaintenance && (
        <ConfirmationPopUp
          type='success'
          message='Schedule maintenance was created sucessfully!'
          onClick={() => showSuccessConfirmationCreateMaintenance(false)}
        />
      )}

      {confirmationDeleteMaintenance && (
        <CallToActionPopUp
          message='Are you sure you want to delete this scheduled maintenance?'
          onConfirm={handleDeleteMaintenance}
          onDeny={() => [
            setMaintenanceToDelete(null),
            showConfirmationDeleteMaintenance(false),
          ]}
        />
      )}

      {successConfirmationDeleteMaintenance && (
        <ConfirmationPopUp
          type='success'
          message='Scheduled maintenance deleted sucessfully!'
          onClick={() => showSuccessConfirmationDeleteMaintenance(false)}
        />
      )}

      {editMaintenanceForm && (
        <MaintenanceEditor
          maintenanceId={maintenanceToEdit}
          onClose={() => [
            showEditMaintenanceForm(false),
            setMaintenanceToEdit(null),
          ]}
          onSave={handleUpdateMaintenance}
        />
      )}
      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />
        <div className={cls.container}>
          {(isLoadingMaintenance || isLoading) && <Loading />}
          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Maintenance</div>
              <div className={cls.subtitle}>
                Lorem ipsum dolor amet. Lorem ipsum dolor amet.
              </div>
            </div>
          </div>

          <div className={cls.contents}>
            <div className={cls.form}>
              <form action=''>
                <div className={cls.row}>
                  <div className={cls.title}>Schedule New Maintenance</div>
                  <div className={cls.fields}>
                    <div className={cls.field}>
                      <Controller
                        name='startDate'
                        defaultValue=''
                        control={control}
                        render={({
                          field: { onChange, onBlur, ref },
                          fieldState: { isTouched, isDirty, error },
                        }) => (
                          <LmsDatePicker
                            label='Starting Date'
                            defaultValue=''
                            // onChange={onChange}
                            minDate={
                              new Date(
                                new Date().setDate(new Date().getDate() - 1)
                              )
                            }
                            onChange={(date) => {
                              console.log(date);

                              // setValue("endDate", "2023-01-01");
                              return onChange(
                                `${date.year}-${date.month}-${date.day}`
                              );
                            }}
                            // onBlur={onBlur}
                            // inputRef={ref}
                            // isTouched={isTouched}
                            isDirty={isDirty}
                            error={error}
                          />
                        )}
                      />
                    </div>
                    <div className={cls.field}>
                      <Controller
                        name='endDate'
                        defaultValue=''
                        control={control}
                        render={({
                          field: { onChange, onBlur, ref },
                          fieldState: { isTouched, isDirty, error },
                        }) => (
                          <LmsDatePicker
                            label='Ending Date'
                            disabled={isUndeterminedEndTime}
                            defaultValue=''
                            minDate={new Date(watch("startDate"))}
                            onChange={(date) => {
                              // console.log(date);

                              return onChange(
                                `${date.year}-${date.month}-${date.day}`
                              );
                            }}
                            // onBlur={onBlur}
                            // inputRef={ref}
                            // isTouched={isTouched}
                            isDirty={isDirty}
                            error={error}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className={cls.row}>
                  <div className={cls.subtitle}>Determined Ending Date</div>
                  <label className={cls.toggle} htmlFor='toggle'>
                    <span
                      className={`${cls.slider} ${cls.round} ${
                        !isUndeterminedEndTime ? cls.on : ""
                      }`}
                    />
                    <input
                      {...register("isUndeterminedEndTime")}
                      type='checkbox'
                      id='toggle'
                      onChange={() =>
                        setIsUndeterminedEndTime(!isUndeterminedEndTime)
                      }
                      checked={isUndeterminedEndTime}
                    />
                    <span className={cls.label}>
                      {!isUndeterminedEndTime ? "Yes" : "No"}
                    </span>
                  </label>
                </div>

                <div className={cls.row}>
                  <div className={cls.subtitle}>
                    Components selected for maintenance
                  </div>
                  <div className={cls.fields}>
                    <div className={cls.field}>
                      <Controller
                        name='component'
                        defaultValue=''
                        control={control}
                        render={({
                          field: { onChange, onBlur, ref },
                          fieldState: { error },
                        }) => (
                          <ComponentSelector
                            onChange={onChange}
                            error={error}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className={cls.row}>
                  <Controller
                    name='notes'
                    defaultValue=''
                    control={control}
                    render={({
                      field: { onChange, onBlur, ref },
                      fieldState: { isTouched, isDirty, error },
                    }) => (
                      <FormInput
                        type='text'
                        label='Internal Notes'
                        defaultValue=''
                        onChange={onChange}
                        onBlur={onBlur}
                        inputRef={ref}
                        isTouched={isTouched}
                        isDirty={isDirty}
                        error={error}
                      />
                    )}
                  />
                </div>

                <div className={cls.row}>
                  <div className={cls.title}>User Message</div>
                  <Controller
                    name='message'
                    defaultValue=''
                    control={control}
                    render={({
                      field: { onChange, onBlur, ref },
                      fieldState: { isTouched, isDirty, error },
                    }) => (
                      <TextareaInput
                        label='Message'
                        defaultValue=''
                        onChange={onChange}
                        onBlur={onBlur}
                        inputRef={ref}
                        isTouched={isTouched}
                        isDirty={isDirty}
                        error={error}
                      />
                    )}
                  />
                </div>
              </form>

              <div className={cls.maintenanceContainer}>
                <div className={cls.title}>Upcoming Maintenance</div>
                <div className={cls.maintenanceList}>
                  {maintenanceSchedules?.map(
                    (maintenance: IMaintenance, index: number) => (
                      <div className={cls.maintenanceItem} key={index}>
                        <div className={cls.maintenanceItemWrapper}>
                          <div className={cls.row}>
                            <div className={cls.content}>
                              <span>Start:</span>
                              <span>
                                {timeFormatter(maintenance.startTime)}
                              </span>
                            </div>
                            <div className={cls.content}>
                              <span>End:</span>
                              <span>{timeFormatter(maintenance.endTime)}</span>
                            </div>
                          </div>
                          <div className={cls.row}>
                            <div className={cls.content}>
                              <span>Component:</span>
                              <div className={cls.body}>Full Website</div>
                            </div>
                          </div>
                          <div className={cls.row}>
                            <div className={cls.content}>
                              <span>Notes:</span>
                              <div className={`${cls.body} ${cls.notes}`}>
                                {maintenance.notes || "No available notes"}
                              </div>
                            </div>
                          </div>
                          <div className={cls.row}>
                            <div className={cls.content}>
                              <span>Message:</span>
                              <div className={`${cls.body} ${cls.message}`}>
                                {maintenance.message}
                              </div>
                            </div>
                          </div>
                          <div className={cls.actions}>
                            <div
                              className={cls.button}
                              onClick={() => [
                                setMaintenanceToEdit(maintenance.id),
                                showEditMaintenanceForm(true),
                              ]}>
                              Edit
                            </div>
                            <div
                              className={cls.button}
                              onClick={() => [
                                setMaintenanceToDelete(maintenance.id),
                                showConfirmationDeleteMaintenance(true),
                              ]}>
                              Delete
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className={cls.actions}>
                <MainBtn
                  label='Back'
                  transparent={true}
                  width={130}
                  height={44}
                  onClick={() => navigate(-1)}
                />

                <MainBtn
                  label='Save Changes'
                  width={180}
                  height={44}
                  onClick={handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
