import { QueryFunctionContext, useQuery, useQueryClient } from "react-query";
import { cacheTimes } from "../cacheTimesRQ";
import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../requestApi";

type FilteringOperation = "==" | ">" | ">=" | "<=" | "!=" | "<";
export enum EChangeLogAction {
  DELETE_OR_ARCHIVE_HELP_QUESTION_CATEGORY = "DELETE_OR_ARCHIVE_HELP_QUESTION_CATEGORY",
  DELETE_HELP_QUESTION = "DELETE_HELP_QUESTION",
  DELETE_LECTURER = "DELETE_LECTURER",
  SAVE_LECTURER = "SAVE_LECTURER",
  SAVE_SUPPORT_TICKET = "SAVE_SUPPORT_TICKET",
  SUBMIT_SUPPORT_TICKET_MESSAGE = "SUBMIT_SUPPORT_TICKET_MESSAGE",
  UPDATE_SUPPORT_TICKETS_CATEGORY = "UPDATE_SUPPORT_TICKETS_CATEGORY",
  UPDATE_SUPPORT_TICKET = "UPDATE_SUPPORT_TICKET",
  DELETE_OR_ARCHIVE_SUPPORT_TICKETS_CATEGORY = "DELETE_OR_ARCHIVE_SUPPORT_TICKETS_CATEGORY",
  ANSWER_CLASS_QUESTION = "ANSWER_CLASS_QUESTION",
  ADD_CLASS_QUESTION = "ADD_CLASS_QUESTION",
  UPDATE_HELP_QUESTION_CATEGORY = "UPDATE_HELP_QUESTION_CATEGORY",
  ADD_CHAT_MESSAGE_TO_GLOBAL_CHAT = "ADD_CHAT_MESSAGE_TO_GLOBAL_CHAT",
  ADD_CHAT_MESSAGE_TO_CHAT_ROOM = "ADD_CHAT_MESSAGE_TO_CHAT_ROOM",
  ADD_COMMENT_TO_HOMEWORK = "ADD_COMMENT_TO_HOMEWORK",
  ADD_FEEDBACK_TO_HOMEWORK = "ADD_FEEDBACK_TO_HOMEWORK",
  ADD_HOMEWORK = "ADD_HOMEWORK",
  CREATE_ZOOM_MEETING = "CREATE_ZOOM_MEETING",
  DELETE_CLASS = "DELETE_CLASS",
  DELETE_TERM = "DELETE_TERM",
  DELETE_WEEK = "DELETE_WEEK",
  DELETE_MAINTENANCE = "DELETE_MAINTENANCE",
  SAVE_MAINTENANCE = "SAVE_MAINTENANCE",
  DELETE_TIER = "DELETE_TIER",
  DELETE_USER = "DELETE_USER",
  DELETE_COURSE = "DELETE_COURSE",
  DELETE_VIDEO = "DELETE_VIDEO",
  MANUAL_REMOVE_ZOOM_MEETING = "MANUAL_REMOVE_ZOOM_MEETING",
  MANUAL_SEND_ZOOM_INVITES = "MANUAL_SEND_ZOOM_INVITES",
  SET_HOMEWORK_REVIEW_STATE = "SET_HOMEWORK_REVIEW_STATE",
  CLASS_CREATED_TRIGGER = "CLASS_CREATED_TRIGGER",
  INSTRUCTOR_VIDEO_UPLOADED_TRIGGER = "INSTRUCTOR_VIDEO_UPLOADED_TRIGGER",
  CLASS_UPDATED_TRIGGER = "CLASS_UPDATED_TRIGGER",
  WEEK_CREATED_TRIGGER = "WEEK_CREATED_TRIGGER",
  WEEK_DELETED_TRIGGER = "WEEK_DELETED_TRIGGER",
  CLASS_DELETED_TRIGGER = "CLASS_DELETED_TRIGGER",
  SAVE_HELP_QUESTION = "SAVE_HELP_QUESTION",
  ENABLE_USER_ACCOUNT = "ENABLE_USER_ACCOUNT",
  INSTRUCTOR_REVIEW_ASSET_UPLOADED = "INSTRUCTOR_REVIEW_ASSET_UPLOADED",
  VIDEO_DELETED_TRIGGER = "VIDEO_DELETED_TRIGGER",
  SAVE_CLASS = "SAVE_CLASS",
  SAVE_COURSE = "SAVE_COURSE",
  SAVE_LECTURE = "SAVE_LECTURE",
  SAVE_TERM = "SAVE_TERM",
  SAVE_TIER = "SAVE_TIER",
  SAVE_USER = "SAVE_USER",
  USER_REGISTERED = "USER_REGISTERED",
  STRIPE_ITEM_PURCHASED_WEBHOOK = "STRIPE_ITEM_PURCHASED_WEBHOOK",
  SAVE_USER_PROGRESS = "SAVE_USER_PROGRESS",
  SAVE_VIDEO = "SAVE_VIDEO",
  SET_NOTIFICATION_READ_STATE = "SET_NOTIFICATION_READ_STATE",
  SET_USER_LAST_MESSAGE_READ = "SET_USER_LAST_MESSAGE_READ",
  TOGGLE_LIKE_ON_COMMENT = "TOGGLE_LIKE_ON_COMMENT",
  UPDATE_HOMEWORK_FEEDBACK = "UPDATE_HOMEWORK_FEEDBACK",
  UPDATE_USER = "UPDATE_USER",
  UPDATE_ZOOM_NOTIFICATION = "UPDATE_ZOOM_NOTIFICATION",
  CREATE_USER_PASSWORDLESS = "CREATE_USER_PASSWORDLESS",
}
export enum EEntityType {
  HELP_QUESTION_CATEGORY = "HELP_QUESTION_CATEGORY",
  HELP_QUESTION = "HELP_QUESTION",
  LECTURER = "LECTURER",
  SUPPORT_TICKET = "SUPPORT_TICKET",
  SUPPORT_TICKET_MESSAGE = "SUPPORT_TICKET_MESSAGE",
  SUPPORT_TICKETS_CATEGORY = "SUPPORT_TICKETS_CATEGORY",
  CLASS_QUESTION_ANSWER = "CLASS_QUESTION_ANSWER",
  CLASS_QUESTION = "CLASS_QUESTION",
  CHAT_MESSAGE = "CHAT_MESSAGE",
  COMMENT_TO_HOMEWORK = "COMMENT_TO_HOMEWORK",
  FEEDBACK_TO_HOMEWORK = "FEEDBACK_TO_HOMEWORK",
  CLASS = "CLASS",
  TERM = "TERM",
  WEEK = "WEEK",
  MAINTENANCE = "MAINTENANCE",
  TIER = "TIER",
  USER = "USER",
  COURSE = "COURSE",
  VIDEO = "VIDEO",
  NOTIFICATION = "NOTIFICATION",
  HOMEWORK_UPLOAD = "HOMEWORK_UPLOAD",
  USER_NOTIFICATION_REFERENCE = "USER_NOTIFICATION_REFERENCE",
  SCHEDULE = "SCHEDULE",
  USER_SCHEDULE_REFERENCE = "USER_SCHEDULE_REFERENCE",
  SECRET = "SECRET",
  CHANGE_LOG = "CHANGE_LOG",
  GLOBAL_FEATURE_FLAG = "GLOBAL_FEATURE_FLAG",
  SUPPORT_TICKET_HISTORY = "SUPPORT_TICKET_HISTORY",
  TRANSACTION = "TRANSACTION",
  ACCESS_CONTROL_RESOURCE = "ACCESS_CONTROL_RESOURCE",
  ROLE = "ROLE",
  SKU = "SKU",
  HISTORY = "HISTORY",
  CHAT_ROOM = "CHAT_ROOM",
}

export interface IChangeLogRecord {
  userId: string;
  actionType: EChangeLogAction;
  createdAtTimestamp: number;
  id: string;
  isFromAutomatedTrigger?: boolean;
}

export interface IChange {
  before: { [prop: string]: any } | null;
  after: { [prop: string]: any } | null;
  entityId: string;
  entityType: EEntityType;
  documentPath: string;
}

export interface ISorting {
  field: keyof IChangeLogRecord; // defaults to id
  order: "asc" | "desc"; // defaults to asc
}

export interface IFiltering {
  field: keyof IChangeLogRecord;
  condition: FilteringOperation;
  value: IChangeLogRecord[keyof IChangeLogRecord];
}

export interface IRequest {
  pagination: {
    page: number;
    pageSize: number;
  };
  sorting?: ISorting;
  filtering?: IFiltering[];
  includeCount?: boolean;
}

export const fetchHistoryLogs = (payload: IRequest) => {
  return requestApi({
    url: ApiEndpoints.GET_CHANGE_LOGS,
    method: "post",
    data: payload,
  });
};

export const fetchLogDataById = ({ queryKey }: QueryFunctionContext) => {
  const logId: string = queryKey[1] as string;
  return requestApi({
    url: `${ApiEndpoints.GET_CHANGE_LOGS_BY_ID}/${logId}`,
    method: "get",
  });
};

export const useLogData = (logId: string, enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["history-log-data", logId], fetchLogDataById, {
    refetchOnWindowFocus: true,
    enabled: enabled,
    cacheTime: cacheTimes.HISTORY_LOGS_CACHE,
    staleTime: cacheTimes.HISTORY_LOGS_STALE,
    initialData: queryClient.getQueryData(["history-log-data", logId]),
    select: (data) => data.data,
    onError: (err) => {
      console.log(err);
    },
  });
};

///// REACT QUERY /////
// const fetchHistoryLogs = ({ queryKey }: QueryFunctionContext) => {
//   const page: number = queryKey[1] as number;
//   const pageSize: number = queryKey[2] as number;

//   const payload: IRequest = {
//     pagination: {
//       page,
//       pageSize,
//     },
//     sorting: {
//       field: "createdAtTimestamp",
//       order: "desc",
//     },
//     includeCount: true,
//   };

//   return requestApi({
//     url: ApiEndpoints.GET_CHANGE_LOGS,
//     method: "post",
//     data: payload,
//   });
// };

// export const useHistoryLogs = (
//   page: number,
//   pageSize: number,
//   enabled?: boolean
// ) => {
//   const queryClient = useQueryClient();
//   return useQuery(["history-logs", page, pageSize], fetchHistoryLogs, {
//     refetchOnWindowFocus: false,
//     enabled: enabled,
//     cacheTime: cacheTimes.HISTORY_LOGS_CACHE,
//     staleTime: cacheTimes.HISTORY_LOGS_STALE,
//     initialData: queryClient.getQueryData(["history-logs", page, pageSize]),
//     keepPreviousData: true,
//     select: (data) => data.data,
//     onError: (err) => {
//       console.log(err);
//     },
//   });
// };
