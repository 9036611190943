import cls from "./ProfileImageUploader.module.scss";
import { FieldError } from "react-hook-form";
import { useEffect, useState } from "react";

type Props = {
  label?: string;

  defaultValue?: string;
  value?: File | null;
  disabled?: boolean;
  imgRef?: React.Ref<HTMLInputElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isTouched?: boolean;
  isDirty?: boolean;
  error?: FieldError | undefined;
};

const ProfileImageUploader: React.FC<Props> = ({
  defaultValue,
  value,
  label,
  disabled,
  imgRef,
  onChange,
  onBlur,
  isTouched,
  isDirty,
  error,
}) => {
  const [imgUrl, setImgUrl] = useState<any>();

  const readURL = (file: File) => {
    // to do => improve file reader
    let url: string | ArrayBuffer | null | undefined = "";
    if (file) {
      var reader = new FileReader();

      reader.onload = function (e) {
        setImgUrl(e.target?.result);
        url = e.target?.result || undefined;
      };
      reader.readAsDataURL(file);
    }
    return url;
  };

  useEffect(() => {
    if (value) {
      readURL(value);
    }
  }, [value]);
  return (
    <div className={cls.root}>
      <div className={cls.label}>{label}</div>
      <div className={cls.wrapper}>
        {!defaultValue && !value && <img src='' alt='' />}
        {defaultValue && !value && <img src={defaultValue} alt='' />}
        {value && <img src={imgUrl} alt='' />}

        <label
          htmlFor='imgInput'
          role='button'
          className={`${cls.fileUpload} ${disabled ? cls.disabled : ""}`}>
          Upload
        </label>
        <input
          disabled={disabled}
          ref={imgRef}
          id='imgInput'
          type='file'
          name='imgInput'
          multiple={false}
          accept='image/*'
          onBlur={onBlur}
          onChange={onChange}
        />
      </div>

      {/* {value && <p>{value.name}</p>} */}
    </div>
  );
};

export default ProfileImageUploader;
