import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  deleteHelpQuestion,
  deleteHelpQuestionCategories,
  saveHelpQuestionCategories,
  useAllHelpQuestionCategories,
  useAllHelpQuestions,
} from "../../api/help/helpService";
import AppHeader from "../../components/appHeader/AppHeader";
import CallToActionPopUp from "../../components/common/callToActionPopUp/CallToActionPopUp";
import ConfirmationPopUp from "../../components/common/confirmationPopUp/ConfirmationPopUp";
import Loading from "../../components/common/loading/Loading";
import AddNewCategory from "../../components/help/addNewCategory/AddNewCategory";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";
import { CollapsedSidebarContext } from "../../context/collapsedSidebarProvider";
import {
  HelpQuestionCategory,
  IHelpQuestion,
} from "../../interfaces/IHelpQuestion";
import cls from "./Help.module.scss";

import { v4 as uuidV4 } from "uuid";
import EditCategory from "../../components/help/editCategory/EditCategory";

const Help: React.FC = () => {
  const navigate = useNavigate();
  const { collapsed } = useContext(CollapsedSidebarContext);
  const queryClient = useQueryClient();

  const { data: questions, isLoading: isLoadingQuestions } =
    useAllHelpQuestions();
  const { data: categories, isLoading: isLoadingCategories } =
    useAllHelpQuestionCategories();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [activeCategory, setActiveCategory] = useState<HelpQuestionCategory>();
  const [categoryToEdit, setCategoryToEdit] =
    useState<HelpQuestionCategory | null>(null);

  const [addCategoryForm, showAddCategoryForm] = useState<boolean>(false);
  const [editCategoryForm, showEditCategoryForm] = useState<boolean>(false);

  const [questionToDelete, setQuestionToDelete] = useState<string | null>(null);

  const [successConfirmationSaveCategory, showSuccessConfirmationSaveCategory] =
    useState<boolean>(false);
  const [confirmationDeleteCategory, showConfirmationDeleteCategory] =
    useState<boolean>(false);
  const [
    successConfirmationDeleteCategory,
    showSuccessConfirmationDeleteCategory,
  ] = useState<boolean>(false);

  const [confirmationDeleteQuestion, showConfirmationDeleteQuestion] =
    useState<boolean>(false);
  const [
    successConfirmationDeleteQuestion,
    showSuccessConfirmationDeleteQuestion,
  ] = useState<boolean>(false);

  ///////////
  const createNewCategoryRQ = useMutation(saveHelpQuestionCategories, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("new category created/updated successfully!");
      queryClient.invalidateQueries(["help-question-categories"]);
      showSuccessConfirmationSaveCategory(true);
    },
    onError: (err) => {
      console.log("error categorey created/updated", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setCategoryToEdit(null);
      setIsLoading(false);
    },
  });

  const deleteCategoryRQ = useMutation(deleteHelpQuestionCategories, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log(" category deleted successfully!");
      queryClient.invalidateQueries(["help-question-categories"]);
      queryClient.invalidateQueries(["help-questions"]);
      showSuccessConfirmationDeleteCategory(true);
    },
    onError: (err) => {
      console.log("error categorey delete", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setCategoryToEdit(null);
      setIsLoading(false);
    },
  });

  const handleSaveNewCategory = (data: { name: string }) => {
    const payload: HelpQuestionCategory[] = [
      ...categories,
      {
        id: uuidV4(),
        name: data.name,
        isArchived: false,
        orderIndex:
          categories.length === 0
            ? 0
            : Math.max(
                ...categories.map((obj: HelpQuestionCategory) => obj.orderIndex)
              ) + 1,
      },
    ];
    createNewCategoryRQ.mutate(payload);
    showAddCategoryForm(false);
  };
  const handleEditCategory = (data: HelpQuestionCategory) => {
    console.log(data);
    let payload: HelpQuestionCategory[] = categories.filter(
      (c: HelpQuestionCategory) => c.id !== data.id
    );
    payload = [...payload, data];
    console.log(payload);
    createNewCategoryRQ.mutate(payload);
    showEditCategoryForm(false);
  };

  const handleDeleteCategory = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!categoryToEdit) return;

    const payload: string[] = [categoryToEdit.id];

    deleteCategoryRQ.mutate(payload);
    showConfirmationDeleteCategory(false);
  };

  ///////////
  const deleteHelpQuestionRQ = useMutation(deleteHelpQuestion, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("question deleted successfully!");
      queryClient.invalidateQueries(["help-questions"]);
      showSuccessConfirmationDeleteQuestion(true);
    },
    onError: (err) => {
      console.log("error question deleted", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setQuestionToDelete(null);
      setIsLoading(false);
    },
  });
  const handleDeleteHelpQuestion = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!questionToDelete) return;

    console.log(questionToDelete);
    deleteHelpQuestionRQ.mutate(questionToDelete);
    showConfirmationDeleteQuestion(false);
  };

  const handleSetActiveCategory = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const categoryKey = e.currentTarget.id;
    const currentCategory = categories.find(
      (cat: HelpQuestionCategory) => cat.id === categoryKey
    );
    setActiveCategory(currentCategory);
  };

  useEffect(() => {
    if (categories && categories.length !== 0) setActiveCategory(categories[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  return (
    <div className={cls.root}>
      {addCategoryForm && (
        <AddNewCategory
          width={400}
          onSave={handleSaveNewCategory}
          onDeny={() => showAddCategoryForm(false)}
        />
      )}

      {editCategoryForm && (
        <EditCategory
          width={400}
          category={categoryToEdit}
          onSave={handleEditCategory}
          onDeny={() => showEditCategoryForm(false)}
        />
      )}
      {successConfirmationSaveCategory && (
        <ConfirmationPopUp
          type='success'
          message='Help category was saved sucessfully!'
          onClick={() => showSuccessConfirmationSaveCategory(false)}
        />
      )}

      {confirmationDeleteCategory && (
        <CallToActionPopUp
          message='Are you sure you want to delete this category?'
          onConfirm={handleDeleteCategory}
          onDeny={() => showConfirmationDeleteCategory(false)}
        />
      )}

      {successConfirmationDeleteCategory && (
        <ConfirmationPopUp
          type='success'
          message='Help category was deleted sucessfully!'
          onClick={() => showSuccessConfirmationDeleteCategory(false)}
        />
      )}

      {confirmationDeleteQuestion && (
        <CallToActionPopUp
          message='Are you sure you want to delete this question?'
          onConfirm={handleDeleteHelpQuestion}
          onDeny={() => [
            setQuestionToDelete(null),
            showConfirmationDeleteQuestion(false),
          ]}
        />
      )}

      {successConfirmationDeleteQuestion && (
        <ConfirmationPopUp
          type='success'
          message='Help question was deleted sucessfully!'
          onClick={() => showSuccessConfirmationDeleteQuestion(false)}
        />
      )}

      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />
        <div className={cls.container}>
          {(isLoadingQuestions || isLoadingCategories || isLoading) && (
            <Loading />
          )}
          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Help Editor</div>
              <div className={cls.subtitle}>
                Lorem ipsum dolor amet. Lorem ipsum dolor amet.
              </div>
            </div>
          </div>

          <div className={cls.contents}>
            <div className={cls.categories}>
              <div className={cls.row}>
                <div
                  className={cls.add}
                  onClick={() => showAddCategoryForm(true)}>
                  <div className={cls.body}>
                    <div className={cls.icon}></div>
                    <div className={cls.label}>Add New Category</div>
                  </div>
                </div>
              </div>
              <div className={cls.row}>
                {categories?.map(
                  (category: HelpQuestionCategory, index: number) => (
                    <div key={index + 1} className={cls.item}>
                      <div
                        className={`${cls.body} ${
                          activeCategory?.id === category.id ? cls.isActive : ""
                        }`}>
                        <div
                          id={category.id}
                          className={cls.label}
                          onClick={handleSetActiveCategory}>
                          {category.name}
                        </div>
                        <div className={cls.actions}>
                          <span
                            onClick={() => [
                              setCategoryToEdit(category),
                              showEditCategoryForm(true),
                            ]}>
                            Edit
                          </span>
                          <span
                            onClick={() => [
                              setCategoryToEdit(category),
                              showConfirmationDeleteCategory(true),
                            ]}>
                            Delete
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                )}
                <div key={0} className={cls.item}>
                  <div
                    className={`${cls.body} ${
                      activeCategory?.id === "null" ? cls.isActive : ""
                    }`}>
                    <div
                      // id={category.id}
                      className={cls.label}
                      onClick={() =>
                        setActiveCategory({
                          id: "null",
                          name: "NO CATEGORY",
                          isArchived: false,
                          orderIndex: Infinity,
                        })
                      }>
                      NO CATEGORY
                    </div>
                    <div className={cls.actions}>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {categories && (
              <div className={cls.questions}>
                <div className={cls.title}>{activeCategory?.name}</div>
                <div className={cls.row}>
                  <div className={cls.add} onClick={() => navigate("create")}>
                    <div className={cls.body}>
                      <div className={cls.icon}>+</div>
                      <div className={cls.label}>Add New Question</div>
                    </div>
                  </div>
                </div>
                <div className={cls.row} style={{ marginTop: 40 }}>
                  {questions
                    ?.filter((question: IHelpQuestion) =>
                      activeCategory?.id === "null"
                        ? question.categoryId === null
                        : question.categoryId === activeCategory?.id
                    )
                    .map((question: IHelpQuestion, index: number) => (
                      <div key={index} className={cls.item}>
                        <div className={cls.title}>{question.title}</div>
                        <div className={cls.actions}>
                          <span onClick={() => navigate(question.id)}>
                            Edit
                          </span>
                          <span
                            onClick={() => [
                              setQuestionToDelete(question.id),
                              showConfirmationDeleteQuestion(true),
                            ]}>
                            Delete
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
