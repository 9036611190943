import cls from "./EditWeek.module.scss";
import AppHeader from "../../../components/appHeader/AppHeader";
import SidebarMenu from "../../../components/sidebarMenu/SidebarMenu";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { CollapsedSidebarContext } from "../../../context/collapsedSidebarProvider";
import MainBtn from "../../../components/common/buttons/mainBtn/MainBtn";
import FormInput from "../../../components/common/formInput/FormInput";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import Loading from "../../../components/common/loading/Loading";
import ConfirmationPopUp from "../../../components/common/confirmationPopUp/ConfirmationPopUp";
import WeekRichTextEditor from "../../../components/course/weekRichTextEditor/WeekRichTextEditor";
import {
  updateWeek,
  UpdateWeekPayload,
  useWeeks,
} from "../../../api/weeks/weeksService";
import { UrlParams } from "../../../interfaces/UrlParams";
import { IWeek } from "../../../interfaces/IWeek";
import {
  deleteMultipleVideos,
  DeleteMultipleVideosPayload,
  useVideos,
} from "../../../api/videos/videosService";

import WeekVideosEditor from "../../../components/course/weekVideosEditor/WeekVideosEditor";
import CallToActionPopUp from "../../../components/common/callToActionPopUp/CallToActionPopUp";

type FormInputs = {
  title: string;
  overview: string;
  homeworkDescription: string;
};
const schema = yup.object().shape({
  title: yup.string().required("Week title is required!"),
  overview: yup.string(),
  homeworkDescription: yup.string(),
});

const EditWeek: React.FC = () => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, termId, weekId } = routeParameters;
  const queryClient = useQueryClient();
  const { collapsed } = useContext(CollapsedSidebarContext);
  const navigate = useNavigate();

  const { data: weeksData, isLoading: isLoadingWeeks } = useWeeks(
    courseId,
    termId
  );
  const { data: videoListData, isLoading: isLoadingVideos } = useVideos(
    courseId,
    termId,
    weekId
  );

  const [videosToDelete, setVideosToDelete] = useState<string[]>([]);
  const [weekData, setWeekData] = useState<IWeek | null>(null);

  const [deleteVideosPopup, showDeleteVideosPopup] = useState<boolean>(false);
  const [successConfirmationUpdate, showSuccessConfirmationUpdate] =
    useState<boolean>(false);
  const [successConfirmationDeleteVideo, showSuccessConfirmationDeleteVideo] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  /// handle select videos
  const handleSelectVideos = (e: React.ChangeEvent<HTMLInputElement>) => {
    // e.preventDefault();
    const videoId: string = e.target.value;
    if (videosToDelete.indexOf(videoId) === -1) {
      setVideosToDelete([...videosToDelete, videoId]);
    } else {
      const newVideosToDeleteState: string[] = videosToDelete.filter(
        (id: string) => id !== videoId
      );
      setVideosToDelete(newVideosToDeleteState);
    }
  };

  ///////////////// handle delete videos
  const deleteMultipleVideosRQ = useMutation(deleteMultipleVideos, {
    // onMutate: () => {
    //   setIsLoading(true);
    // },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("videos deleted successfully!");
      queryClient.invalidateQueries(["videos", courseId, termId, weekId]);
      showSuccessConfirmationDeleteVideo(true);
    },
    onError: (err) => {
      console.log("error deleting videos...", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const handleDeleteMultipleVideos = () => {
    showDeleteVideosPopup(false);
    setIsLoading(true);
    const payload: DeleteMultipleVideosPayload = {
      courseId,
      termId,
      weekId,
      videoIds: videosToDelete,
    };

    deleteMultipleVideosRQ.mutate(payload);
  };

  ///////////////// handle submit form
  const { handleSubmit, control, reset } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const updateWeekRQ = useMutation(updateWeek, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("week updated successfully!");
      queryClient.invalidateQueries(["weeks", courseId, termId]);
      showSuccessConfirmationUpdate(true);
    },
    onError: (err) => {
      console.log("error updating week...", err);
      setIsLoading(false);
      reset();
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const onSubmit = (data: FormInputs) => {
    if (weekData) {
      const newWeek: IWeek = {
        ...weekData,
        title: data.title,
        overview: data.overview,
        homeworkDescription: data.homeworkDescription,
      };

      const payload: UpdateWeekPayload = {
        week: newWeek,
        courseId,
        termId,
      };
      updateWeekRQ.mutate(payload);
    }
  };

  useEffect(() => {
    if (weeksData) {
      const weekData = weeksData.find((week: IWeek) => week.id === weekId);
      setWeekData(weekData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeksData]);

  return (
    <div className={cls.root}>
      {successConfirmationUpdate && (
        <ConfirmationPopUp
          type='success'
          message='Week updated!'
          onClick={() => showSuccessConfirmationUpdate(false)}
        />
      )}
      {successConfirmationDeleteVideo && (
        <ConfirmationPopUp
          type='success'
          message='Video/s deleted!'
          onClick={() => showSuccessConfirmationDeleteVideo(false)}
        />
      )}

      {deleteVideosPopup && (
        <CallToActionPopUp
          message={
            videosToDelete.length === 1
              ? "Are you sure you want to delete this video?"
              : "Are you sure you want to delete these videos?"
          }
          onDeny={() => showDeleteVideosPopup(false)}
          onConfirm={handleDeleteMultipleVideos}
        />
      )}

      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />

        <div className={cls.container}>
          {(isLoading || isLoadingWeeks || isLoadingVideos) && <Loading />}

          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Edit Week</div>
              <div className={cls.subtitle}>{weekData?.title}</div>
            </div>
            <MainBtn
              label='Back'
              transparent={true}
              onClick={() => navigate(-1)}
            />
          </div>

          <div className={cls.contents}>
            {weekData && (
              <div className={cls.form}>
                <form action=''>
                  <div className={cls.row}>
                    <div className={cls.title}>Week details</div>
                    <div className={cls.fields}>
                      <div className={cls.field}>
                        <Controller
                          name='title'
                          defaultValue={weekData.title}
                          control={control}
                          render={({
                            field: { onChange, onBlur, ref },
                            fieldState: { isTouched, isDirty, error },
                          }) => (
                            <FormInput
                              type='text'
                              label='Title'
                              defaultValue={weekData.title}
                              maxLength={60}
                              onChange={onChange}
                              onBlur={onBlur}
                              inputRef={ref}
                              isTouched={isTouched}
                              isDirty={isDirty}
                              error={error}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={cls.row}>
                    <Controller
                      name='overview'
                      control={control}
                      defaultValue={weekData.overview}
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => (
                        <WeekRichTextEditor
                          label='Overview Editor'
                          data={weekData.overview}
                          onChange={onChange}
                          error={error}
                        />
                      )}
                    />
                  </div>
                  <div className={cls.row}>
                    <Controller
                      name='homeworkDescription'
                      control={control}
                      defaultValue={weekData.homeworkDescription}
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => (
                        <WeekRichTextEditor
                          label='Assignment Description Editor'
                          data={weekData.homeworkDescription}
                          onChange={onChange}
                          error={error}
                        />
                      )}
                    />
                  </div>
                </form>

                <div className={cls.videos}>
                  <div className={cls.title}>Videos</div>
                  {videoListData && (
                    <WeekVideosEditor
                      videoListData={videoListData}
                      courseId={courseId}
                      termId={termId}
                      weekId={weekId}
                      onSelect={handleSelectVideos}
                    />
                  )}
                </div>

                <div className={cls.actions}>
                  <MainBtn
                    disabled={isLoading}
                    label='Back'
                    transparent={true}
                    width={130}
                    height={44}
                    onClick={() => navigate(`/courses/${courseId}/${termId}`)}
                  />
                  {videoListData?.length !== 0 && (
                    <div
                      className={`${cls.delete} ${
                        videosToDelete.length === 0 ? cls.disabled : ""
                      }`}
                      onClick={() =>
                        showDeleteVideosPopup(videosToDelete.length > 0)
                      }
                      style={{ width: 180, height: 44 }}>
                      Delete Selected
                    </div>
                  )}

                  <div
                    className={cls.save}
                    onClick={handleSubmit(onSubmit)}
                    style={{ width: 180, height: 44 }}>
                    Save
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditWeek;
