import AppHeader from "../../../components/appHeader/AppHeader";
import SidebarMenu from "../../../components/sidebarMenu/SidebarMenu";
import cls from "./CreateCourse.module.scss";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { CollapsedSidebarContext } from "../../../context/collapsedSidebarProvider";
import MainBtn from "../../../components/common/buttons/mainBtn/MainBtn";
import FormInput from "../../../components/common/formInput/FormInput";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextareaInput from "../../../components/common/textareaInput/TextareaInput";
import BackgroundImageUploader from "../../../components/common/backgroundImageUploader/BackgroundImageUploader";
import { ICourseReference } from "../../../interfaces/ICourseReference";
import {
  CourseCreationData,
  ICoursePreview,
} from "../../../interfaces/ICoursePreview";
import CourseReferenceEditor from "../../../components/course/courseReferenceEditor/CourseReferenceEditor";
import LecturersEditor from "../../../components/course/lecturerEditor/LecturerEditor";
import { useMutation, useQueryClient } from "react-query";
import {
  createNewCourse,
  getDefaultFeatureFlags,
  useAllCourses,
} from "../../../api/course/courseService";
import Loading from "../../../components/common/loading/Loading";
import ConfirmationPopUp from "../../../components/common/confirmationPopUp/ConfirmationPopUp";
import { useLecturers } from "../../../api/lecturer/lecturerService";
import { ICourseSyllabus } from "../../../interfaces/ICourseSyllabus";
import CourseSyllabusEditor from "../../../components/course/courseSyllabusEditor/CourseSyllabusEditor";

type FormInputs = {
  title: string;
  description: string;
  backgroundImage: File;
  // reference: ICourseReference[];
  // syllabus: ICourseSyllabus[];
  // lecturerIds: string[];
};
const schema = yup.object().shape({
  title: yup.string().required("Course title is required!"),
  description: yup.string().required("Course description is required!"),
  backgroundImage: yup.mixed(),
  // reference: yup.mixed(),
  // syllabus: yup.mixed(),
  // lecturerIds: yup.array().of(yup.string()),
});

const CreateCourse: React.FC = () => {
  const { isLoading: isLoadingLecturers } = useLecturers();
  const queryClient = useQueryClient();
  const { collapsed } = useContext(CollapsedSidebarContext);
  const navigate = useNavigate();
  const [successConfirmation, showSuccessConfirmation] =
    useState<boolean>(false);

  const { data: courseList, isLoading: isLoadingCourses } = useAllCourses();

  const [courseId, setCourseId] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  ///////////////// handle submit form
  const { handleSubmit, control } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const createNewCourseRQ = useMutation(createNewCourse, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (courseId) => {
      setCourseId(courseId);
      // Invalidates cache and refetch
      console.log("new course created successfully!");
      queryClient.invalidateQueries("all-courses");
      queryClient.invalidateQueries("all-classes");

      showSuccessConfirmation(true);
    },
    onError: (err) => {
      console.log("error course created", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });
  const onSubmit = (data: FormInputs) => {
    const year = new Date();

    const featuresFlagMap = getDefaultFeatureFlags();

    const courseIdx =
      courseList?.length === 0
        ? 0
        : Math.max(
            ...courseList?.map((course: ICoursePreview) => course.orderIndex)
          ) + 1;

    const payload: CourseCreationData = {
      ...data,
      featuresFlagMap: featuresFlagMap,
      year: year.getFullYear(),
      orderIndex: courseIdx,
    };
    // console.log(payload)
    if (!isLoading) createNewCourseRQ.mutate(payload);
  };

  return (
    <div className={cls.root}>
      {successConfirmation && (
        <ConfirmationPopUp
          type='success'
          message='Course created!'
          onClick={() => navigate(`/courses/${courseId}`)}
        />
      )}
      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />

        <div className={cls.container}>
          {(isLoading || isLoadingLecturers || isLoadingCourses) && <Loading />}

          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Create Course</div>
              <div className={cls.subtitle}>Lorem ipsum dolor amet.</div>
            </div>
            <MainBtn
              label='Back'
              transparent={true}
              onClick={() => navigate(-1)}
            />
          </div>

          <div className={cls.contents}>
            <div className={cls.form}>
              <form action=''>
                <div className={cls.row}>
                  <div className={cls.title}>Course details</div>
                  <div className={cls.fields}>
                    <div className={cls.field}>
                      <Controller
                        name='title'
                        defaultValue=''
                        control={control}
                        render={({
                          field: { onChange, onBlur, ref },
                          fieldState: { isTouched, isDirty, error },
                        }) => (
                          <FormInput
                            type='text'
                            label='Title'
                            maxLength={60}
                            defaultValue=''
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            isTouched={isTouched}
                            isDirty={isDirty}
                            error={error}
                          />
                        )}
                      />
                    </div>
                    <div className={cls.field}>
                      <Controller
                        name='description'
                        control={control}
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, ref },
                          fieldState: { isTouched, isDirty, error },
                        }) => (
                          <TextareaInput
                            label='Description'
                            defaultValue=''
                            maxLength={1100}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            isTouched={isTouched}
                            isDirty={isDirty}
                            error={error}
                          />
                        )}
                      />
                    </div>
                    <div className={cls.field}>
                      <Controller
                        name='backgroundImage'
                        control={control}
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, ref, value },
                          fieldState: { isTouched, isDirty, error },
                        }) => (
                          <BackgroundImageUploader
                            defaultValue=''
                            label='Background Image'
                            value={value}
                            onChange={(event) => {
                              const val = event.target.files?.[0];
                              return onChange(val);
                            }}
                            onBlur={onBlur}
                            inputRef={ref}
                            isTouched={isTouched}
                            isDirty={isDirty}
                            error={error}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className={cls.row}>
                  <div className={cls.title}>Lecture editor</div>
                  <Controller
                    name='lecturerIds'
                    control={control}
                    defaultValue={[]}
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <LecturersEditor
                        selectedLecturers={[]}
                        onChange={onChange}
                        error={error}
                      />
                    )}
                  />
                </div>
                <div className={cls.row}>
                  <div className={cls.title}>References category editor</div>

                  <Controller
                    name='reference'
                    control={control}
                    defaultValue={[]}
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <CourseReferenceEditor
                        referencesData={[]}
                        onChange={onChange}
                        error={error}
                      />
                    )}
                  />
                </div>
                <div className={cls.row}>
                  <div className={cls.title}>Syllabus category editor</div>

                  <Controller
                    name='syllabus'
                    control={control}
                    defaultValue={[]}
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <CourseSyllabusEditor
                        syllabusData={[]}
                        onChange={onChange}
                        error={error}
                      />
                    )}
                  />
                </div> */}
              </form>
              <div className={cls.actions}>
                <MainBtn
                  disabled={isLoading}
                  label='Back'
                  transparent={true}
                  width={130}
                  height={44}
                  onClick={() => navigate("/courses")}
                />

                <div
                  className={cls.save}
                  onClick={handleSubmit(onSubmit)}
                  style={{ width: 180, height: 44 }}>
                  Save
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCourse;
