import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import {
  Firestore,
  //   doc,
  //   getDoc,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { FirebaseStorage, getStorage } from "firebase/storage";

const firebaseConfig: Record<string, string> = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
  appId: process.env.REACT_APP_FIREBASE_APP_ID!,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID!,
};

// const firebaseConfig: Record<string, string> = config.firebase;
const firebase: FirebaseApp = initializeApp(firebaseConfig);

export const storage: FirebaseStorage = getStorage(firebase);
export const firestore: Firestore = getFirestore(firebase);
export const timestamp = serverTimestamp(); // to be used in the future
export const auth: Auth = getAuth(firebase);

// Function to check if the user has a specific role
// export const hasRole = async (uid: string, requiredRole: string) => {
//   try {
//     const userDocRef = doc(firestore, "Users", uid);
//     const userDocSnapshot = await getDoc(userDocRef);

//     // Check if the user document exists and has the specific role
//     return (
//       userDocSnapshot.exists() &&
//       userDocSnapshot.data().role.name === requiredRole
//     );
//   } catch (error) {
//     console.error("Error checking user role:", error);
//     return false;
//   }
// };
