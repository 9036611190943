import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../../api/requestApi";
import { IVimeoResponse } from "./vimeo";
import { AxiosResponse } from "axios";
import { TusUpload } from "../videos/tusProtocolHelper";

export type VimeoUploadResponse = {
  embedUrl: string;
  thumbnailUrl: string;
  vimeoVideoId: string;
};

class VimeoServiceClass {
  private accessToken: string = "";
  private readonly VIMEO_BASE_URL: string = "https://api.vimeo.com";

  public async Initialize() {
    if (this.accessToken) return;

    const response: AxiosResponse = await requestApi({
      url: ApiEndpoints.VIMEO_ACCESS_TOKEN,
      data: null,
      method: "GET",
    });

    if (response.status !== 200)
      throw Error("error initializing vimeo service");
    // throw new LMSException(response.body?.error?.code || ErrorCodes.INTERNAL_ERROR);

    this.accessToken = response.data.accessToken;
  }

  public async UploadVideo(
    file: File,
    title: string,
    parentFolder: string | undefined,
    onProgress: (percentageUploaded: number) => void
  ): Promise<VimeoUploadResponse> {
    const response = await requestApi({
      url: "https://api.vimeo.com/me/videos",

      data: JSON.stringify({
        upload: {
          approach: "tus",
          size: file.size,
        },
        name: title,
      }),

      method: "post",

      headers: {
        "Content-Type": "application/json",
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        Authorization: `bearer ${this.accessToken}`,
      },
    });

    if (response.status >= 400) throw Error("Video creation fail");

    await TusUpload(file, response.data.upload.upload_link, onProgress);

    const [, , videoId]: string[] = response.data.uri.split("/");

    if (parentFolder) await this.PutVideoInsideFolder(parentFolder, videoId);

    return {
      // embedUrl: `https://player.vimeo.com/video/${videoId}?h=${hash}`,
      embedUrl: response.data.player_embed_url,
      thumbnailUrl:
        response.data.pictures.sizes?.[response.data.pictures.sizes?.length - 2]
          ?.link || "",
      vimeoVideoId: videoId,
    };
  }

  public async PutVideoInsideFolder(
    folderUri: string,
    videoId: string
  ): Promise<void> {
    const response = await requestApi({
      url: `https://api.vimeo.com${folderUri}/videos/${videoId}`,
      data: null,
      method: "put",
      headers: {
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        Authorization: `bearer ${this.accessToken}`,
      },
    }).catch(() => ({ status: 500, data: null }));

    if (response.status !== 204) throw Error("Unable to move video to folder");
  }

  // public async GetVideoData(videoId: string): Promise<IVimeoResponse>
  // {
  //     const {
  //         body,
  //         statusCode
  //     }: { body: IVimeoResponse, statusCode: number; } = await MakeRequestToURL(`https://api.vimeo.com/videos/${videoId}`, null, 'GET',
  //         {
  //             'Accept': 'application/vnd.vimeo.*+json;version=3.4',
  //             'Authorization': `bearer ${this.accessToken}`
  //         }).catch(() => ({ statusCode: 500, body: null as unknown as IVimeoResponse }));

  //     if (statusCode >= 400)
  //         throw new LMSException(ErrorCodes.VIDEO_NOT_FOUND);

  //     return body;

  // }

  public async CreateFolder(
    folderName: string,
    parentFolderUri?: string
  ): Promise<{ folderUri: string }> {
    const response = await requestApi({
      url: "https://api.vimeo.com/me/projects",
      data: JSON.stringify({
        name: folderName,
        parent_folder_uri: parentFolderUri,
      }),
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        Authorization: `bearer ${this.accessToken}`,
      },
    }).catch((err) => ({
      status: 500,
      data: null as unknown as IVimeoResponse,
    }));

    if (response.status >= 400)
      // throw new LMSException(ErrorCodes.FOLDER_NOT_CREATED);
      throw Error("folder not created");

    return { folderUri: response.data.uri };
  }

  // public async GetVideoThumbnail(vimeoVideoId: string): Promise<string>
  // {
  //     const { body } = await MakeRequestToURL(`${this.VIMEO_BASE_URL}/videos/${vimeoVideoId}/pictures`, null, 'GET',
  //         {
  //             'Content-Type': 'application/json',
  //             'Accept': 'application/vnd.vimeo.*+json;version=3.4',
  //             'Authorization': `bearer ${this.accessToken}`
  //         }).catch(() => ({ statusCode: 500, body: null as unknown as IVimeoResponse }));

  //     const picture: Pictures | undefined = (body?.data || []).find((picture: Pictures) => picture?.active);

  //     const index: number = ~~((picture?.sizes?.length || 0) / 2);

  //     return picture?.sizes?.[index]?.link || '';
  // }

  public async UploadThumbnailToVimeo(
    vimeoVideoId: string,
    file: File
  ): Promise<string> {
    const uploadURI: string = await this.GetUploadURI(vimeoVideoId);

    if (!uploadURI) return "";

    const { uploadLink, viewLink, activateURI } = await this.GetUploadLink(
      uploadURI
    );

    const formData: FormData = new FormData();

    formData.append("file", file, file.name);
    formData.append("uploadLink", uploadLink);
    formData.append("uploadURI", activateURI);

    await requestApi({
      url: ApiEndpoints.SAVE_VIDEO_THUMBNAIL,
      data: formData,
      method: "post",
      headers: {
        skipContentTypeHeader: true,
      },
    });

    return viewLink;
  }

  private async GetUploadURI(vimeoVideoId: string): Promise<string> {
    const response = await requestApi({
      url: `${this.VIMEO_BASE_URL}/videos/${vimeoVideoId}?fields=metadata.connections.pictures.uri`,
      data: null,
      method: "get",
      headers: {
        "Content-Type": "image/jpeg",
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        Authorization: `bearer ${this.accessToken}`,
      },
    }).catch((err) => ({ status: 500, data: null }));

    return response.data?.metadata?.connections?.pictures?.uri || "";
  }

  private async GetUploadLink(
    uploadURI: string
  ): Promise<{ uploadLink: string; viewLink: string; activateURI: string }> {
    const response = await requestApi({
      url: `${this.VIMEO_BASE_URL}${uploadURI}`,
      data: null,
      method: "post",
      headers: {
        "Content-Type": "image/jpeg",
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        Authorization: `bearer ${this.accessToken}`,
      },
    }).catch((err) => ({ status: 500, data: null }));

    return {
      uploadLink: response.data?.link || "",
      viewLink:
        response.data?.sizes?.[(response.data.sizes?.length || 1) - 1]?.link ||
        "",
      activateURI: response.data?.uri || "",
    };
  }
}

const VimeoService: VimeoServiceClass = new VimeoServiceClass();

export default VimeoService;
