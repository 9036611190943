import { useContext } from "react";
import { CollapsedSidebarContext } from "../../context/collapsedSidebarProvider";
import { Link } from "react-router-dom";
import cls from "./SidebarMenu.module.scss";
// load imgs
import Logo from "../../assets/images/logo@2x.png";
// load icons
import Icon from "../../assets/icons/Icon";
import MenuList from "./MenuList";

const SidebarMenu: React.FC = () => {
  const { collapsed, handleSidebarState } = useContext(CollapsedSidebarContext);
  const toggleSidebarState = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    handleSidebarState();
  };
  return (
    <div className={`${cls.root} ${collapsed ? cls.collapsed : ""}`}>
      {/* ------------ LOGO ------------ */}
      <Link
        to={"/users"}
        className={`${cls.logo} ${collapsed ? cls.collapsed : ""}`}>
        <img alt='Visign' src={Logo} />
      </Link>
      {/* ------------ SIDEBAR EXPANDER ------------ */}
      <div className={cls.expander}>
        <span onClick={toggleSidebarState}>
          <Icon icon='Expand' />
        </span>
      </div>
      {/* ------------ MENU LIST ------------ */}
      <MenuList collapsed={collapsed} />
    </div>
  );
};

export default SidebarMenu;
