import { Birthday, IUser } from "../../../../interfaces/IUser";
import cls from "./ProfileDetails.module.scss";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "../../../common/formInput/FormInput";
import GenderSelect from "../../../common/genderSelect/GenderSelect";
import RoleSelector from "../../../roleSelector/RoleSelector";
// import DatePicker from "../../../common/datePicker/DatePicker";
import { FaGraduationCap } from "react-icons/fa";
import MainBtn from "../../../common/buttons/mainBtn/MainBtn";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CallToActionPopUp from "../../../common/callToActionPopUp/CallToActionPopUp";
import ConfirmationPopUp from "../../../common/confirmationPopUp/ConfirmationPopUp";
import {
  deleteUserAccount,
  disableUserAccount,
  enableUserAccount,
  updateUserAccount,
  // useAdminUser,
  useRoles,
} from "../../../../api/user/userService";
import { useMutation, useQueryClient } from "react-query";
import ProfilePicture from "../picture/ProfilePicture";
import { IRole } from "../../../../interfaces/IRole";
import { useAllCourses } from "../../../../api/course/courseService";
import { ICoursePreview } from "../../../../interfaces/ICoursePreview";
import AddCourseStepper from "../../../course/addCourseStepper/AddCourseStepper";
import { IClassQuery } from "../../../../interfaces/IClassQuery";
import { ICourseClass } from "../../../../interfaces/ICourseClass";
import { saveClass } from "../../../../api/class/classService";
import Loading from "../../../common/loading/Loading";
import LmsDatePicker from "../../../common/lmsDatePicker/LmsDatePicker";
import { AuthContext } from "../../../../context/authContext";

type Props = {
  user: IUser;
};
type FormInputs = {
  firstName: string;
  lastName: string;
  birthday: string;
  gender: string;
  phone: string;
  role: string;
};

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required!"),
  lastName: yup.string().required("Last name is required!"),
  birthday: yup.string(),
  gender: yup.string(),
  phone: yup
    .string()
    .matches(/^(\+?\d*\s*)*\d+$/, { message: "Not a valid phone number!" }),
  role: yup.string(),
});

const Profiledetails: React.FC<Props> = ({ user }) => {
  // const { data: adminUser, isLoading: adminUserLoading } = useAdminUser();
  const authInstance = useContext(AuthContext);

  const { data: userRoles } = useRoles();
  const { data: allCourses } = useAllCourses();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [enrolledCourses, setEnrolledCourses] = useState<ICoursePreview[]>([]);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [addCourseStepper, showAddCourseStepper] = useState<boolean>(false);

  /////////////// POPUPS ////////////////
  const [confirmationPopUp, showConfirmationPopUp] = useState<boolean>(false);

  const [successConfirmationUpdated, showSuccessConfirmationUpdated] =
    useState<boolean>(false);

  const [successConfirmationEnabled, showSuccessConfirmationEnabled] =
    useState<boolean>(false);
  const [successConfirmationDisabled, showSuccessConfirmationDisabled] =
    useState<boolean>(false);

  const [confirmationPopUpDelete, showConfirmationPopUpDelete] =
    useState<boolean>(false);

  const [errorConfirmation, showErrorConfirmation] = useState<boolean>(false);
  /////// ENABLE USER //////
  const enableUserRQ = useMutation(enableUserAccount, {
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("user enabled successfully!");
      queryClient.invalidateQueries("user-list");
      showSuccessConfirmationEnabled(true);
    },
    onError: (err) => {
      console.log("error user enabled", err);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      setButtonDisabled(false);
    },
  });
  const handleConfirmEnableAccount = () => {
    setButtonDisabled(true);
    enableUserRQ.mutate(user.id);
    showConfirmationPopUp(false);
  };
  /////// DISABLE USER //////
  const disableUserRQ = useMutation(disableUserAccount, {
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("user disabled successfully!");
      queryClient.invalidateQueries("user-list");
      showSuccessConfirmationDisabled(true);
    },
    onError: (err) => {
      console.log("error user disabled", err);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      setButtonDisabled(false);
    },
  });
  const handleConfirmDisableAccount = () => {
    setButtonDisabled(true);
    disableUserRQ.mutate(user.id);
    showConfirmationPopUp(false);
  };
  /////// DELETE USER //////
  const deleteUserRQ = useMutation(deleteUserAccount, {
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("Account deleted successfully!");
      navigate(-1);
      queryClient.invalidateQueries("user-list");
      //   showSuccessConfirmationDeleted(true);
    },
    onError: (err) => {
      console.log("error account deleted", err);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      setButtonDisabled(false);
    },
  });
  const handleConfirmDeleteAccount = () => {
    setButtonDisabled(true);
    deleteUserRQ.mutate(user.id);
    showConfirmationPopUpDelete(false);
  };
  /////// UPDATE USER //////
  const updateUserRQ = useMutation(updateUserAccount, {
    onSuccess: (s) => {
      //   initUser(variables);
      // Invalidates cache and refetch
      queryClient.invalidateQueries("user-list"); // TO DO => replace only user in cache
      showSuccessConfirmationUpdated(true);
    },
    onError: (err) => {
      console.log("error user updated", err);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      setButtonDisabled(false);
      setIsLoading(false);
    },
  });
  ///////////////// handle submit form
  const { handleSubmit, control, formState } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: FormInputs) => {
    // setButtonDisabled(true);
    if (formState.isDirty) setIsLoading(true);

    const userRole: IRole =
      authInstance?.currentUser && authInstance?.userRole === "OWNER"
        ? userRoles.filter((role: IRole) => role.name === data.role)[0]
        : user.role;
    updateUserRQ.mutate({
      ...user,
      // isDisabled: !user.isDisabled ? false : user.isDisabled,
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      phoneNumber: data.phone.length > 0 ? data.phone : undefined,
      birthday: formatDatePayload(data.birthday),
      role: userRole,
    });
  };

  const handleDenyConfirmation = () => {
    showConfirmationPopUp(false);
    showConfirmationPopUpDelete(false);
  };

  ///////////////// format date
  const getDateValue = (birthday: Birthday | undefined): string => {
    let result: string = "";
    if (birthday) {
      const { year, month, day } = birthday;
      const targetMonth: string | number = month > 9 ? month : `0${month}`;
      const targetDay: string | number = day > 9 ? day : `0${day}`;
      const date: Date = new Date(year, month - 1, day);
      result = `${date.getFullYear()}-${targetMonth}-${targetDay}`;
    }

    return result;
  };

  const formatDatePayload = (date: string) => {
    if (date) {
      const splitString = date.split("-");
      let result = {
        year: parseInt(splitString[0]),
        month: parseInt(splitString[1]),
        day: parseInt(splitString[2]),
      };

      return result;
    }

    return;
  };
  /////// ADD COURSE //////
  const addCourseRQ = useMutation(saveClass, {
    onMutate: () => setIsLoading(true),
    onSuccess: (s) => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries("user-list");
      queryClient.invalidateQueries("all-classes");

      showSuccessConfirmationUpdated(true);
      setButtonDisabled(true);
    },
    onError: (err) => {
      console.log("error class updated", err);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });
  const handleAddCourse = (
    course: ICoursePreview,
    tier: string,
    courseClass: IClassQuery
  ) => {
    if (!course && !tier && !courseClass) return;

    let payload: ICourseClass = courseClass.class;
    if (user.role.name === "USER")
      payload.enrolledUsers = [...payload.enrolledUsers, user.id];
    if (user.role.name === "INSTRUCTOR")
      payload.instructors = [...payload.instructors, user.id];

    showAddCourseStepper(false);
    addCourseRQ.mutate(payload);
  };

  useEffect(() => {
    const enrolledCourses: ICoursePreview[] = [];
    if (allCourses)
      if (user.enrolledCourses) {
        for (const enrolledCourse of user.enrolledCourses) {
          const course: ICoursePreview = allCourses.find(
            (c: ICoursePreview) => c.id === enrolledCourse.courseId
          );
          if (course) enrolledCourses.push(course);
        }
      }
    setEnrolledCourses(enrolledCourses);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCourses, user]);

  return (
    <div className={cls.root}>
      {isLoading && <Loading />}
      {confirmationPopUp && (
        <CallToActionPopUp
          message={
            user?.isDisabled
              ? "Are you sure you want to enable this account?"
              : "Are you sure you want to disable this account?"
          }
          onConfirm={() =>
            user?.isDisabled
              ? handleConfirmEnableAccount()
              : handleConfirmDisableAccount()
          }
          onDeny={() => handleDenyConfirmation()}
        />
      )}
      {successConfirmationUpdated && (
        <ConfirmationPopUp
          type='success'
          message='Account successfully updated!'
          onClick={() => showSuccessConfirmationUpdated(false)}
        />
      )}

      {successConfirmationEnabled && (
        <ConfirmationPopUp
          type='success'
          message='Account successfully enabled!'
          onClick={() => showSuccessConfirmationEnabled(false)}
        />
      )}

      {successConfirmationDisabled && (
        <ConfirmationPopUp
          type='success'
          message='Account successfully disabled!'
          onClick={() => showSuccessConfirmationDisabled(false)}
        />
      )}

      {confirmationPopUpDelete && (
        <CallToActionPopUp
          message='Are you sure you want to delete this account?'
          onConfirm={() => handleConfirmDeleteAccount()}
          onDeny={() => handleDenyConfirmation()}
        />
      )}

      {errorConfirmation && (
        <ConfirmationPopUp
          type='error'
          message='Could not update the account!'
          onClick={() => showErrorConfirmation(false)}
        />
      )}

      {addCourseStepper && (
        <AddCourseStepper
          user={user}
          onClose={() => showAddCourseStepper(false)}
          onSave={handleAddCourse}
        />
      )}

      <div className={cls.form}>
        <ProfilePicture user={user} />

        <form action=''>
          <div className={cls.row}>
            <div className={cls.fields}>
              <div className={cls.field}>
                <Controller
                  name='firstName'
                  defaultValue={user.firstName}
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, ref },
                    fieldState: { isTouched, isDirty, error },
                  }) => (
                    <FormInput
                      type='text'
                      label='First Name'
                      maxLength={20}
                      defaultValue={user.firstName}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      isTouched={isTouched}
                      isDirty={isDirty}
                      error={error}
                    />
                  )}
                />
              </div>

              <div className={cls.field}>
                <Controller
                  name='lastName'
                  defaultValue={user.lastName}
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, ref },
                    fieldState: { isTouched, isDirty, error },
                  }) => (
                    <FormInput
                      type='text'
                      label='Last Name'
                      maxLength={20}
                      defaultValue={user.lastName}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      isTouched={isTouched}
                      isDirty={isDirty}
                      error={error}
                    />
                  )}
                />
              </div>

              <div className={cls.field}>
                <Controller
                  name='birthday'
                  defaultValue={getDateValue(user.birthday)}
                  control={control}
                  render={({
                    field: { onChange, onBlur, ref },
                    fieldState: { isTouched, isDirty, error },
                  }) => (
                    <LmsDatePicker
                      label='Birthday'
                      defaultValue={getDateValue(user.birthday)}
                      maxDate={new Date()}
                      // onChange={onChange}
                      onChange={(date) => {
                        // console.log(date);
                        return onChange(
                          `${date.year}-${date.month}-${date.day}`
                        );
                      }}
                      // onBlur={onBlur}
                      // inputRef={ref}
                      // isTouched={isTouched}
                      isDirty={isDirty}
                      error={error}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className={cls.row}>
            <Controller
              name='gender'
              defaultValue={user.gender}
              control={control}
              render={({ field: { onChange } }) => (
                <GenderSelect onChange={onChange} defaultValue={user.gender} />
              )}
            />
          </div>

          <div className={cls.row}>
            <div className={cls.title}>Contact Information</div>
            <div className={cls.fields}>
              <div className={cls.field}>
                <FormInput
                  type='email'
                  label='Email'
                  disabled={true}
                  defaultValue={user.email}
                />
              </div>
              <div className={cls.field}>
                <Controller
                  name='phone'
                  defaultValue={user.phoneNumber || ""}
                  control={control}
                  render={({
                    field: { onChange, onBlur, ref },
                    fieldState: { isTouched, isDirty, error },
                  }) => (
                    <FormInput
                      type='text'
                      label='Phone Number'
                      maxLength={40}
                      defaultValue={user.phoneNumber || ""}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      isTouched={isTouched}
                      isDirty={isDirty}
                      error={error}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          {authInstance?.currentUser && authInstance?.userRole === "OWNER" && (
            <div className={cls.row}>
              <div className={cls.title}>Role</div>
              <Controller
                name='role'
                defaultValue={user.role.name}
                control={control}
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <RoleSelector
                    onChange={onChange}
                    defaultValue={user.role.name}
                    userRoles={userRoles}
                    error={error}
                  />
                )}
              />
            </div>
          )}

          <div className={cls.row} style={{ marginTop: 0 }}>
            <div className={cls.title}>Courses</div>
            <div className={cls.courseContainer}>
              {enrolledCourses?.map((course: ICoursePreview, index: number) => {
                const classId: string | undefined = user.enrolledCourses?.find(
                  (ec: any) => ec.courseId === course.id
                )?.classId;

                return (
                  <div className={cls.courseItem} key={index}>
                    <div className={cls.wrapper}>
                      <div className={cls.avatar}>
                        <img
                          src={course.thumbnailUrl || "/placeholder.webp"}
                          alt=''
                        />
                      </div>
                      {/* <div className={cls.subtitle}>Lorem ipsum sin dolor</div> */}

                      <div className={cls.title}>{course.title}</div>

                      <div className={cls.description}>
                        {course.description}
                      </div>
                      <div
                        className={cls.classView}
                        onClick={() => {
                          if (classId)
                            navigate(
                              `/courses/${course.id}/classes/${classId}`
                            );
                        }}>
                        <FaGraduationCap size={16} /> <span>Go to class</span>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div
                className={cls.add}
                onClick={() => showAddCourseStepper(true)}>
                <div className={cls.body}>
                  <div className={cls.icon}></div>
                  <div className={cls.label}>Add New Course</div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className={cls.actions}>
          <MainBtn
            // disabled={buttonDisabled}
            label='Back'
            transparent={true}
            width={130}
            height={44}
            onClick={() => navigate(-1)}
          />
          <MainBtn
            // disabled={buttonDisabled}
            label='Delete Account'
            transparent={true}
            width={180}
            height={44}
            onClick={() => showConfirmationPopUpDelete(true)}
          />
          <MainBtn
            // disabled={buttonDisabled}
            label={user?.isDisabled ? "Enable Account" : "Disable Account"}
            transparent={true}
            width={180}
            height={44}
            onClick={() => showConfirmationPopUp(true)}
          />
          <MainBtn
            disabled={!formState.isDirty}
            label='Save Changes'
            width={180}
            height={44}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </div>
  );
};

export default Profiledetails;
