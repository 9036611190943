import { EServiceTier } from "../../../../../interfaces/IServiceTier";
import cls from "./AddTier.module.scss";

type Props = {
  onAddTier: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedTier?: string;
};

const AddTier: React.FC<Props> = ({ onAddTier, selectedTier }) => {
  return (
    <div className={cls.root}>
      <div className={`${cls.wrapper}`}>
        {(Object.keys(EServiceTier) as (keyof typeof EServiceTier)[]).map(
          (key, index) => {
            return (
              <div
                className={`${cls.field}  ${
                  key !== "STANDARD" ? cls.disabled : ""
                }`}
                key={index}>
                <input
                  name='role'
                  type='radio'
                  value={key}
                  // className={`${
                  //   EServiceTier[key] === selectedTier ? cls.active : ""
                  // }`}
                  disabled={key !== "STANDARD"}
                  checked={key === selectedTier}
                  onChange={onAddTier}
                />
                <label>{EServiceTier[key]}</label>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default AddTier;
