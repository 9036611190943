import cls from "./LoginForm.module.scss";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import FormInput from "../../common/formInput/FormInput";
import MainBtn from "../../common/buttons/mainBtn/MainBtn";
// import Loading from "../../common/loading/Loading";
import { auth } from "../../../api/firebase";

type FormInputs = {
  email: string;
  password: string;
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Not a valid email address!")
    .required("Email is required!"),
  password: yup.string().required("Password is required!"),
});

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  ///////////////// handle submit form

  const { handleSubmit, control } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormInputs) => {
    try {
      setIsLoading(true);

      // Sign in the user with email and password
      const { user } = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );

      const { claims } = await user.getIdTokenResult();

      if (claims?.role === "USER") {
        // user is type of "USER"
        setError("User does not have the required role, login denied.");

        await signOut(auth);
      } else {
        navigate("/users");
      }
    } catch (e) {
      setError("Invalid email or password. Please try again");
      console.error("Error signing in with email and password", e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={cls.root}>
      {/* {!isLoading && <Loading />} */}

      <div className={cls.title}>Log In</div>
      <form>
        <div className={cls.row}>
          <Controller
            name='email'
            defaultValue=''
            control={control}
            rules={{ required: true }}
            render={({
              field: { onChange, onBlur, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <FormInput
                type='email'
                label='Email'
                defaultValue=''
                onChange={(e) => [setError(""), onChange(e)]}
                onBlur={onBlur}
                inputRef={ref}
                isTouched={isTouched}
                isDirty={isDirty}
                error={error}
              />
            )}
          />
        </div>
        <div className={cls.row}>
          <Controller
            name='password'
            defaultValue=''
            control={control}
            rules={{ required: true }}
            render={({
              field: { onChange, onBlur, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <FormInput
                type='password'
                label='Password'
                defaultValue=''
                onChange={(e) => [setError(""), onChange(e)]}
                onBlur={onBlur}
                inputRef={ref}
                isTouched={isTouched}
                isDirty={isDirty}
                error={error}
              />
            )}
          />
        </div>
        <p className={cls.error}> {error && error} </p>
        <div className={`${cls.row} ${cls.actions}`}>
          <MainBtn
            label='Login now'
            width={174}
            height={44}
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
