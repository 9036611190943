const sec = 1000;
const min = 60 * sec;
const hour = 60 * min;
const day = 24 * hour;

export enum cacheTimes {
  //////
  USER_DATA_CACHE = day,
  USER_DATA_STALE = 12 * hour,
  //////
  USER_LIST_DATA_CACHE = 10 * min,
  USER_LIST_DATA_STALE = 5 * min,
  //////
  // COURSE_LIST_DATA_CACHE = day,
  // COURSE_LIST_DATA_STALE = 12 * hour,
  COURSE_LIST_DATA_CACHE = 0,
  COURSE_LIST_DATA_STALE = 0,
  //////
  // CLASS_LIST_DATA_CACHE = 12 * hour,
  // CLASS_LIST_DATA_STALE = 6 * hour,
  CLASS_LIST_DATA_CACHE = 0,
  CLASS_LIST_DATA_STALE = 0,
  //////
  USER_ROLES_DATA_CACHE = day,
  USER_ROLES_DATA_STALE = 12 * hour,
  //////
  COURSE_TIER_CACHE = 4 * day,
  COURSE_TIER_STALE = 2 * day,
  //////
  // COURSE_TERMS_CACHE = 12 * hour,
  // COURSE_TERMS_STALE = 6 * hour,
  COURSE_TERMS_CACHE = 0,
  COURSE_TERMS_STALE = 0,
  //////
  // WEEK_LIST_CACHE = day,
  // WEEK_LIST_STALE = 12 * hour,
  WEEK_LIST_CACHE = 0,
  WEEK_LIST_STALE = 0,
  //////
  LECTURERS_DATA_CACHE = day,
  LECTURERS_DATA_STALE = 12 * hour,
  //////
  // VIDEO_LIST_CACHE = 12 * hour,
  // VIDEO_LIST_STALE = 6 * hour,
  VIDEO_LIST_CACHE = 0,
  VIDEO_LIST_STALE = 0,
  //////
  // CLASS_QUESTIONS_CACHE = 6 * min,
  // CLASS_QUESTIONS_STALE = 2 * min,
  CLASS_QUESTIONS_CACHE = 0,
  CLASS_QUESTIONS_STALE = 0,
  //////
  // CLASS_QUESTION_COMMENTS_CACHE = 6 * min,
  // CLASS_QUESTION_COMMENTS_STALE = 2 * min,
  CLASS_QUESTION_COMMENTS_CACHE = 0,
  CLASS_QUESTION_COMMENTS_STALE = 0,
  //   //////
  HELP_QUESTIONS_CACHE = 2 * day,
  HELP_QUESTIONS_STALE = 1 * day,
  //   //////
  HELP_QUESTION_CATEGORIES_CACHE = 2 * day,
  HELP_QUESTION_CATEGORIES_STALE = 1 * day,
  //////
  // SUPPORT_TICKETS_CACHE = 6 * min,
  // SUPPORT_TICKETS_STALE = 2 * min,
  SUPPORT_TICKETS_CACHE = 0,
  SUPPORT_TICKETS_STALE = 0,
  //////
  SUPPORT_DEPARTMENTS_CACHE = 7 * day,
  SUPPORT_DEPARTMENTS_STALE = 3 * day,
  /////
  // SUPPORT_TICKET_MESSAGES_CACHE = 6 * min,
  // SUPPORT_TICKET_MESSAGES_STALE = 2 * min,
  SUPPORT_TICKET_MESSAGES_CACHE = 0,
  SUPPORT_TICKET_MESSAGES_STALE = 0,
  //   //////
  SCHEDULED_MAINTENANCE_CACHE = 2 * day,
  SCHEDULED_MAINTENANCE_STALE = 1 * day,
  //   //////
  HISTORY_LOGS_CACHE = 10 * min,
  HISTORY_LOGS_STALE = 5 * min,
}
