import { useContext, useState } from "react";
import AppHeader from "../../../components/appHeader/AppHeader";
import SidebarMenu from "../../../components/sidebarMenu/SidebarMenu";
import { CollapsedSidebarContext } from "../../../context/collapsedSidebarProvider";
import cls from "./CreateUser.module.scss";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MainBtn from "../../../components/common/buttons/mainBtn/MainBtn";
import { useNavigate } from "react-router-dom";
import FormInput from "../../../components/common/formInput/FormInput";
import RolePicker from "../../../components/rolePicker/RolePicker";
import {
  createUserAccount,

  // useAdminUser
} from "../../../api/user/userService";
// import Loading from "../../../components/common/loading/Loading";
import { useMutation, useQueryClient } from "react-query";
import { NewUser } from "../../../interfaces/IUser";
import ConfirmationPopUp from "../../../components/common/confirmationPopUp/ConfirmationPopUp";
import { AuthContext } from "../../../context/authContext";

type FormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
};

const CreateUser: React.FC = () => {
  // const { data: adminUser, isLoading: isLoadingAdminUser } = useAdminUser();
  const authInstance = useContext(AuthContext);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { collapsed } = useContext(CollapsedSidebarContext);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [errorConfirmation, showErrorConfirmation] = useState<boolean>(false);
  const [successConfirmation, showSuccessConfirmation] =
    useState<boolean>(false);

  const schema = yup.object().shape({
    firstName: yup.string().required("First name is required!"),
    lastName: yup.string().required("Last name is required!"),
    email: yup.string().email().required("Email is required"),
    role: yup.string().required("Role name is required!"),
  });

  const { handleSubmit, control } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const createUserRQ = useMutation(createUserAccount, {
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("user updated successfully!");
      queryClient.invalidateQueries("user-list");
      showSuccessConfirmation(true);
    },
    onError: (err) => {
      console.log("error user created", err);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      setButtonDisabled(false);
    },
  });

  const onSubmit = (data: FormInputs) => {
    // console.log(data);
    setButtonDisabled(true);
    const payload: NewUser = { ...data };
    createUserRQ.mutate(payload);
  };
  return (
    <div className={cls.root}>
      {successConfirmation && (
        <ConfirmationPopUp
          type='success'
          message='Confirmation email was sent!'
          onClick={() => showSuccessConfirmation(false)}
        />
      )}
      {errorConfirmation && (
        <ConfirmationPopUp
          type='error'
          message='Could not create user!'
          onClick={() => showErrorConfirmation(false)}
        />
      )}
      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />
        <div className={cls.container}>
          {/* {isLoadingAdminUser && <Loading />} */}
          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Create an account</div>
              <div className={cls.subtitle}>Lorem ipsum dolor amet.</div>
            </div>
          </div>

          <div className={cls.contents}>
            {/* {!isLoadingAdminUser && ( */}
            <form>
              <div className={cls.row}>
                {/* first name field */}
                <div className={cls.field}>
                  <Controller
                    name='firstName'
                    defaultValue=''
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, ref },
                      fieldState: { isTouched, isDirty, error },
                    }) => (
                      <FormInput
                        type='text'
                        label='First Name*'
                        defaultValue=''
                        onChange={onChange}
                        onBlur={onBlur}
                        inputRef={ref}
                        isTouched={isTouched}
                        isDirty={isDirty}
                        error={error}
                      />
                    )}
                  />
                </div>
                {/* last name field */}
                <div className={cls.field}>
                  <Controller
                    name='lastName'
                    defaultValue=''
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, ref },
                      fieldState: { isTouched, isDirty, error },
                    }) => (
                      <FormInput
                        type='text'
                        label='Last Name*'
                        defaultValue=''
                        onChange={onChange}
                        onBlur={onBlur}
                        inputRef={ref}
                        isTouched={isTouched}
                        isDirty={isDirty}
                        error={error}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={cls.row}>
                {/* email field */}
                <div className={cls.field}>
                  <Controller
                    name='email'
                    defaultValue=''
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, ref },
                      fieldState: { isTouched, isDirty, error },
                    }) => (
                      <FormInput
                        type='text'
                        label='Email*'
                        defaultValue=''
                        onChange={onChange}
                        onBlur={onBlur}
                        inputRef={ref}
                        isTouched={isTouched}
                        isDirty={isDirty}
                        error={error}
                      />
                    )}
                  />
                </div>
                {/* role field */}
                <div className={cls.field}>
                  <Controller
                    name='role'
                    defaultValue={""}
                    control={control}
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <RolePicker
                        onChange={onChange}
                        error={error}
                        defaultValue={""}
                        loggedUserRole={authInstance?.userRole}
                      />
                    )}
                  />
                </div>
              </div>
            </form>
            {/* )} */}

            <div className={cls.actions}>
              <MainBtn
                label='Back'
                transparent={true}
                width={130}
                height={44}
                onClick={() => navigate(-1)}
              />
              <MainBtn
                label='Save'
                width={170}
                height={44}
                disabled={buttonDisabled}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
