import cls from "./TicketListItem.module.scss";
import React, { useEffect, useRef, useState } from "react";
import Icon from "../../../assets/icons/Icon";
import {
  ESupportTicketPriority,
  ESupportTicketStatus,
  ISupportTicket,
  SupportTicketCategory,
} from "../../../interfaces/ISupportTicket";
import MessageSubmitter from "../../common/messageSubmitter/MessageSubmitter";
import { IUser } from "../../../interfaces/IUser";
import {
  ReplyPayload,
  submitTicketReply,
  updateSupportTicket,
  UpdateTicketPayload,
  useSupportTicketMessages,
} from "../../../api/support/supportService";
import { IChatMessage } from "../../../interfaces/IChatMessage";
import { useMutation, useQueryClient } from "react-query";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import ConfirmationPopUp from "../../common/confirmationPopUp/ConfirmationPopUp";
import Loading from "../../common/loading/Loading";

type Props = {
  data: ISupportTicket;
  userList: IUser[];
  departments: SupportTicketCategory[];
};

const TicketListItem: React.FC<Props> = ({ data, userList, departments }) => {
  const queryClient = useQueryClient();
  const { data: replies } = useSupportTicketMessages(data.id);
  const [expandedDetails, setExpandedDetails] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<IUser>();

  const [priority, setPriority] = useState<ESupportTicketPriority>();
  const [priorityList, showPriorityList] = useState<boolean>(false);
  const [status, setStatus] = useState<ESupportTicketStatus>();
  const [statusList, showStatusList] = useState<boolean>(false);
  const [department, setDepartment] = useState<string>();
  const [departmentList, showDepartmentList] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const statusField = useRef(null);
  const priorityField = useRef(null);
  const departmentField = useRef(null);

  const [successConfirmationSaveTicket, showSuccessConfirmationSaveTicket] =
    useState<boolean>(false);

  const getDate = (time: number) => {
    const date = new Date(time);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const handleExpandDetails = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    setExpandedDetails(!expandedDetails);
  };

  const getUserAvatar = (userId: string) => {
    const user = userList.find((u: IUser) => u.id === userId);
    return !user?.avatarUrl ? "/user-avatar.webp" : user.avatarUrl;
  };
  /////////
  const handleClickOutsideStatusList = (e: MouseEvent) => {
    showStatusList(false);
  };
  useOnClickOutside(statusField, handleClickOutsideStatusList);

  const handleClickOutsidePriorityList = (e: MouseEvent) => {
    showPriorityList(false);
  };
  useOnClickOutside(priorityField, handleClickOutsidePriorityList);

  const handleClickOutsideDepartmentList = (e: MouseEvent) => {
    showDepartmentList(false);
  };
  useOnClickOutside(departmentField, handleClickOutsideDepartmentList);
  /////////

  const addReplyToTicket = useMutation(submitTicketReply, {
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries(["ticket-messages", data.id]);
    },
    onError: (err) => {
      console.log("addCommentToQuestionRQ", err);
    },
    // onSettled: () => {

    // },
  });

  const handleSubmitReply = (textToSubmit: string) => {
    // e.preventDefault();
    if (textToSubmit.length === 0) return;
    console.log(textToSubmit);

    const payload: ReplyPayload = {
      ticketId: data.id,
      message: textToSubmit,
    };
    addReplyToTicket.mutate(payload);
  };
  //////////
  const saveTicketRQ = useMutation(updateSupportTicket, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("ticket updated successfully!");
      queryClient.invalidateQueries(["support-tickets"]);
      showSuccessConfirmationSaveTicket(true);
    },
    onError: (err) => {
      console.log("error ticket updated", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const handleChangeTicketPriority = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const val = e.currentTarget.id as ESupportTicketPriority;

    if (val === data.priority) return;

    setPriority(val);
    const payload: UpdateTicketPayload = {
      ticketId: data.id,
      updateData: { priority: val },
    };

    saveTicketRQ.mutate(payload);
  };

  const handleChangeTicketStatus = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const val = e.currentTarget.id as ESupportTicketStatus;

    if (val === data.status) return;
    setStatus(val);
    const payload: UpdateTicketPayload = {
      ticketId: data.id,
      updateData: { status: val },
    };
    saveTicketRQ.mutate(payload);
  };

  const handleChangeTicketDepartment = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.preventDefault();
    const val: string = e.currentTarget.id;

    if (val === data.categoryId) return;
    setDepartment(val);
    const payload: UpdateTicketPayload = {
      ticketId: data.id,
      updateData: { categoryId: val },
    };
    saveTicketRQ.mutate(payload);
  };
  ///////////////
  useEffect(() => {
    if (userList && data) {
      const user = userList.find((u: IUser) => u.id === data.userId);
      setUserDetails(user);
      setPriority(data.priority);
      setStatus(data.status);
      setDepartment(data.categoryId);
    }
  }, [userList, data]);

  return (
    <div className={cls.root}>
      {successConfirmationSaveTicket && (
        <ConfirmationPopUp
          type='success'
          message='Support ticket was updated sucessfully!'
          onClick={() => showSuccessConfirmationSaveTicket(false)}
        />
      )}
      {isLoading && <Loading />}
      <div className={cls.wrapper}>
        <div className={`${cls.header} ${expandedDetails ? cls.expanded : ""}`}>
          <div className={cls.headRow}>
            <span className={cls.caseId}>{data.id.slice(0, 6)}</span>
            <span className={cls.user}>
              <img src={userDetails?.avatarUrl || "/user-avatar.webp"} alt='' />
              <span>{`${userDetails?.firstName} ${userDetails?.lastName}`}</span>
            </span>
            <span className={cls.subject}>{data.message}</span>
          </div>
          <div className={cls.headRow}>
            <span
              ref={statusField}
              onClick={() => showStatusList(!statusList)}
              className={`${cls.status} ${status === "OPEN" ? cls.open : ""} ${
                status === "CLOSED" ? cls.close : ""
              }`}>
              {status}
              <span className={cls.icon}>v</span>
              {statusList && (
                <div className={cls.options}>
                  {Object.values(ESupportTicketStatus).map((status) => (
                    <div
                      key={status}
                      id={status}
                      onClick={handleChangeTicketStatus}>
                      {status}
                    </div>
                  ))}
                </div>
              )}
            </span>
            <span
              ref={priorityField}
              onClick={() => showPriorityList(!priorityList)}
              className={`${cls.priority} ${
                priority === "MEDIUM" ? cls.medium : ""
              } ${priority === "HIGH" ? cls.high : ""} ${
                priority === "URGENT" ? cls.urgent : ""
              }`}>
              {priority}
              <span className={cls.icon}>v</span>
              {priorityList && (
                <div className={cls.options}>
                  {Object.values(ESupportTicketPriority).map((priority) => (
                    <div
                      key={priority}
                      id={priority}
                      onClick={handleChangeTicketPriority}>
                      {priority}
                    </div>
                  ))}
                </div>
              )}
            </span>
            <span
              className={cls.department}
              ref={departmentField}
              onClick={() => showDepartmentList(!departmentList)}>
              {
                departments?.find(
                  (d: SupportTicketCategory) => d.id === department
                )?.name
              }
              <span className={cls.icon}>v</span>
              {departmentList && (
                <div className={cls.options}>
                  {departments?.map((department: SupportTicketCategory) => (
                    <div
                      key={department.id}
                      id={department.id}
                      onClick={handleChangeTicketDepartment}>
                      {department.name}
                    </div>
                  ))}
                </div>
              )}
            </span>
            <span className={cls.date}>{getDate(data.timestamp)}</span>
          </div>
          <span className={cls.icon} onClick={handleExpandDetails}>
            <Icon
              icon='ArrowLeft'
              className={cls.arrow}
              viewBox='0 0 320 512'
            />
          </span>
        </div>
        {expandedDetails && (
          <div className={`${cls.body} ${expandedDetails ? cls.expanded : ""}`}>
            <div className={cls.message}>
              <div className={cls.left}>
                <img
                  src={userDetails?.avatarUrl || "/user-avatar.webp"}
                  alt=''
                />
              </div>
              <div className={cls.right}>
                <div className={cls.date}>{getDate(data.timestamp)}</div>
                <div className={cls.message}>{data.message}</div>
              </div>
            </div>
            {replies?.length > 0 && (
              <div className={cls.replies}>
                {replies?.map((reply: IChatMessage, index: number) => (
                  <div className={cls.reply} key={reply.unixTimestamp}>
                    <div className={cls.left}>
                      <img src={getUserAvatar(reply.userId)} alt='' />
                    </div>
                    <div className={cls.right}>
                      <div className={cls.date}>
                        {getDate(reply.unixTimestamp)}
                      </div>
                      <div className={cls.message}>{reply.content}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className={cls.messageComposer}>
              {data.status !== "CLOSED" && (
                <MessageSubmitter
                  emojiEnabled={false}
                  submitMessage={handleSubmitReply}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketListItem;
