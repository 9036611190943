import { IClassQuery } from "../../../../../interfaces/IClassQuery";
import { ICoursePreview } from "../../../../../interfaces/ICoursePreview";
import cls from "./Review.module.scss";

type Props = {
  course?: ICoursePreview;
  tier?: string;
  courseClass?: IClassQuery;
};

const Review: React.FC<Props> = ({ course, courseClass, tier }) => {
  return (
    <div className={cls.root}>
      <div className={`${cls.container}`}>
        <div className={cls.courseItem}>
          <div className={cls.title}>Course</div>
          <div className={cls.wrapper}>
            <div className={cls.avatar}>
              <img src={course?.thumbnailUrl || "/placeholder.webp"} alt='' />
            </div>
            <div className={cls.subtitle}>Lorem ipsum sin dolor</div>

            <div className={cls.title}>{course?.title}</div>

            <div className={cls.description}>{course?.description}</div>
          </div>
        </div>

        <div className={cls.classItem}>
          <div className={cls.title}>Class</div>
          <div className={cls.wrapper}>
            <div className={cls.title}>{courseClass?.class.name}</div>

            <div className={cls.row}>
              <div className={cls.label}>Users</div>
              <div className={cls.val}>
                {courseClass?.class.enrolledUsers.length}
              </div>
            </div>
            <div className={cls.row}>
              <div className={cls.label}>Course</div>
              <div className={cls.val}>{course?.title}</div>
            </div>
            <div className={cls.row}>
              <div className={cls.label}>Service Tier</div>
              <div className={cls.val}>{courseClass?.serviceTier}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
