import { useQuery, useQueryClient } from "react-query";
import { cacheTimes } from "../cacheTimesRQ";
import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../requestApi";
// import { v4 as uuidV4 } from "uuid";
import {
  HelpQuestionCategory,
  IHelpQuestion,
  INewHelpQuestion,
} from "../../interfaces/IHelpQuestion";

const fetchHelpQuestions = () => {
  return requestApi({
    url: ApiEndpoints.GET_HELP_QUESTIONS_LIST,
    method: "GET",
    data: null,
  });
};

export const useAllHelpQuestions = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["help-questions"], fetchHelpQuestions, {
    refetchOnWindowFocus: false,
    enabled: enabled,
    cacheTime: cacheTimes.HELP_QUESTIONS_CACHE,
    staleTime: cacheTimes.HELP_QUESTIONS_STALE,
    initialData: queryClient.getQueryData(["help-questions"]),
    select: (data) =>
      data.data.helpQuestions.sort(
        (a: IHelpQuestion, b: IHelpQuestion) => a.orderIndex - b.orderIndex
      ),
    onError: (err) => {
      console.log(err);
    },
  });
};

export const saveHelpQuestion = (
  // both edit and new
  helpQuestion: IHelpQuestion | INewHelpQuestion
) => {
  return requestApi({
    url: ApiEndpoints.SAVE_HELP_QUESTION,
    method: "POST",
    data: { helpQuestion },
  });
};

export const deleteHelpQuestion = (helpQuestionId: string) => {
  return requestApi({
    url: ApiEndpoints.DELETE_HELP_QUESTION,
    method: "post",
    data: { helpQuestionId },
  });
};
/////////////////////
const fetchHelpQuestionCategories = () => {
  return requestApi({
    url: ApiEndpoints.GET_HELP_QUESTION_CATEGORIES,
    method: "GET",
    data: {},
  });
};

export const useAllHelpQuestionCategories = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["help-question-categories"], fetchHelpQuestionCategories, {
    refetchOnWindowFocus: false,
    enabled: enabled,
    cacheTime: cacheTimes.HELP_QUESTION_CATEGORIES_CACHE,
    staleTime: cacheTimes.HELP_QUESTION_CATEGORIES_STALE,
    initialData: queryClient.getQueryData(["help-question-categories"]),
    select: (data) =>
      data.data.categories.sort(
        (a: HelpQuestionCategory, b: HelpQuestionCategory) =>
          a.orderIndex - b.orderIndex
      ),
    onError: (err) => {
      console.log(err);
    },
  });
};

export const saveHelpQuestionCategories = (
  categories: HelpQuestionCategory[]
) => {
  return requestApi({
    url: ApiEndpoints.SAVE_HELP_QUESTION_CATEGORIES,
    method: "POST",
    data: { categories },
  });
};

export const deleteHelpQuestionCategories = (categoryIds: string[]) => {
  return requestApi({
    url: ApiEndpoints.DELETE_HELP_QUESTION_CATEGORIES,
    method: "POST",
    data: { categoryIds },
  });
};
