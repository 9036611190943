import cls from "./MaintenanceEditor.module.scss";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import React, { useEffect, useState } from "react";

import MainBtn from "../../common/buttons/mainBtn/MainBtn";
import { IMaintenance } from "../../../interfaces/IMaintenance";
// import DatePicker from "../../common/datePicker/DatePicker";
import ComponentSelector from "../componentSelector/ComponentSelector";
import FormInput from "../../common/formInput/FormInput";
import TextareaInput from "../../common/textareaInput/TextareaInput";
import { useScheduledMaintenance } from "../../../api/maintenance/maintenanceService";
import LmsDatePicker from "../../common/lmsDatePicker/LmsDatePicker";

type Props = {
  onClose?: () => void;
  maintenanceId: string | null;
  onSave: (maintenanceData: IMaintenance) => void;
};
type FormInputs = {
  startDate: string;
  endDate: string;
  component: string;
  notes: string;
  message: string;
  isUndeterminedEndTime: boolean;
};

const schema = yup.object().shape({
  startDate: yup.string().required("Starting date is required!"),
  endDate: yup.string().when("isUndeterminedEndTime", {
    is: false,
    then: yup.string().required("Ending date is required!"),
    otherwise: yup.string(),
  }),
  component: yup.string().required("Component date is required!"),
  notes: yup.string().required("Notes are required!"),
  message: yup.string().required("Message is required!"),
  isUndeterminedEndTime: yup.boolean(),
});

const MaintenanceEditor: React.FC<Props> = ({
  onClose,
  maintenanceId,
  onSave,
}) => {
  const { data: maintenanceSchedules } = useScheduledMaintenance();

  const [maintenanceData, setMaintenanceData] = useState<IMaintenance>();
  const [isUndeterminedEndTime, setIsUndeterminedEndTime] = useState<boolean>();

  const timeFormatter = (tm: number | undefined) => {
    if (!tm) return;

    const d = new Date(tm);
    const output =
      d.getFullYear() +
      "-" +
      (d.getMonth() < 9 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1) +
      "-" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

    return output.toString();
  };

  ///////////////// handle submit form
  const { handleSubmit, control, register, watch } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: FormInputs) => {
    if (maintenanceData) {
      let payload: IMaintenance = {
        id: maintenanceData.id,
        isFullWebsiteMaintenance: true,
        notes: data.notes,
        startTime: new Date(data.startDate).getTime(),
        isUndeterminedEndTime: data.isUndeterminedEndTime,
        components: ["All Website"],
        message: data.message,
      };

      if (!isUndeterminedEndTime)
        payload = {
          ...payload,
          endTime: new Date(data.endDate).getTime(),
        };
      onSave(payload);
    }
  };
  useEffect(() => {
    if (maintenanceSchedules) {
      const maintenanceData: IMaintenance = maintenanceSchedules?.find(
        (m: IMaintenance) => m.id === maintenanceId
      );

      setMaintenanceData(maintenanceData);
      setIsUndeterminedEndTime(maintenanceData.isUndeterminedEndTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintenanceSchedules]);
  return (
    <div className={cls.root}>
      <span className={cls.close} onClick={onClose}>
        &#120;
      </span>
      <div className={`${cls.wrapper}`}>
        <div className={cls.header}>
          <div className={cls.title}>Edit Maintenance </div>
        </div>
        <div className={cls.body}>
          {maintenanceData && (
            <form action=''>
              <div className={cls.row}>
                <div className={cls.title}>Schedule New Maintenance</div>
                <div className={cls.fields}>
                  <div className={cls.field}>
                    <Controller
                      name='startDate'
                      defaultValue={timeFormatter(maintenanceData.startTime)}
                      control={control}
                      render={({
                        field: { onChange, onBlur, ref },
                        fieldState: { isTouched, isDirty, error },
                      }) => (
                        <LmsDatePicker
                          label='Starting Date'
                          defaultValue={timeFormatter(
                            maintenanceData.startTime
                          )}
                          minDate={
                            new Date(
                              new Date().setDate(new Date().getDate() - 1)
                            )
                          }
                          // onChange={onChange}
                          onChange={(date) => {
                            // console.log(date);
                            return onChange(
                              `${date.year}-${date.month}-${date.day}`
                            );
                          }}
                          // onBlur={onBlur}
                          // inputRef={ref}
                          // isTouched={isTouched}
                          isDirty={isDirty}
                          error={error}
                        />
                      )}
                    />
                  </div>
                  <div className={cls.field}>
                    <Controller
                      name='endDate'
                      defaultValue={timeFormatter(maintenanceData.endTime)}
                      control={control}
                      render={({
                        field: { onChange, onBlur, ref },
                        fieldState: { isTouched, isDirty, error },
                      }) => (
                        <LmsDatePicker
                          label='Ending Date'
                          disabled={isUndeterminedEndTime}
                          defaultValue={timeFormatter(maintenanceData.endTime)}
                          minDate={new Date(watch("startDate"))}
                          // onChange={onChange}
                          onChange={(date) => {
                            // console.log(date);
                            return onChange(
                              `${date.year}-${date.month}-${date.day}`
                            );
                          }}
                          // onBlur={onBlur}
                          // inputRef={ref}
                          // isTouched={isTouched}
                          isDirty={isDirty}
                          error={error}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className={cls.row}>
                <div className={cls.subtitle}>Determined Ending Date</div>
                <label className={cls.toggle} htmlFor='toggle'>
                  <span
                    className={`${cls.slider} ${cls.round} ${
                      !isUndeterminedEndTime ? cls.on : ""
                    }`}></span>
                  <input
                    {...register("isUndeterminedEndTime")}
                    type='checkbox'
                    id='toggle'
                    onChange={() =>
                      setIsUndeterminedEndTime(!isUndeterminedEndTime)
                    }
                    checked={isUndeterminedEndTime}
                  />
                  <span className={cls.label}>
                    {!isUndeterminedEndTime ? "Yes" : "No"}
                  </span>
                </label>
              </div>

              <div className={cls.row}>
                <div className={cls.subtitle}>
                  Components selected for maintenance
                </div>
                <div className={cls.fields}>
                  <div className={cls.field}>
                    <Controller
                      name='component'
                      defaultValue={
                        maintenanceData.components
                          ? maintenanceData.components[0]
                          : ""
                      }
                      control={control}
                      render={({
                        field: { onChange, onBlur, ref },
                        fieldState: { error },
                      }) => (
                        <ComponentSelector
                          onChange={onChange}
                          error={error}
                          defaultValue={
                            maintenanceData.components
                              ? maintenanceData.components[0]
                              : ""
                          }
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className={cls.row}>
                <Controller
                  name='notes'
                  defaultValue={maintenanceData.notes}
                  control={control}
                  render={({
                    field: { onChange, onBlur, ref },
                    fieldState: { isTouched, isDirty, error },
                  }) => (
                    <FormInput
                      type='text'
                      label='Internal Notes'
                      defaultValue={maintenanceData.notes}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      isTouched={isTouched}
                      isDirty={isDirty}
                      error={error}
                    />
                  )}
                />
              </div>

              <div className={cls.row}>
                <div className={cls.title}>User Message</div>
                <Controller
                  name='message'
                  defaultValue={maintenanceData.message}
                  control={control}
                  render={({
                    field: { onChange, onBlur, ref },
                    fieldState: { isTouched, isDirty, error },
                  }) => (
                    <TextareaInput
                      label='Message'
                      defaultValue={maintenanceData.message}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      isTouched={isTouched}
                      isDirty={isDirty}
                      error={error}
                    />
                  )}
                />
              </div>
            </form>
          )}
        </div>
        <div className={cls.footer}>
          <MainBtn
            label='Back'
            transparent={true}
            width={130}
            height={44}
            onClick={onClose}
          />

          <MainBtn
            label='Save Maintenance'
            width={190}
            height={44}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </div>
  );
};

export default MaintenanceEditor;
