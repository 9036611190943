import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  addCommentToQuestion,
  AddCommentToQuestionPayload,
  useComments,
} from "../../../api/class/classQuestionService";
import { useAllUsers } from "../../../api/user/userService";
import { IChatMessage } from "../../../interfaces/IChatMessage";
import { IClassQuestionWithIdentifier } from "../../../interfaces/IClassQuestionTopic";
import { IUser } from "../../../interfaces/IUser";
import MessageSubmitter from "../../common/messageSubmitter/MessageSubmitter";
import cls from "./ClassQuestionTopic.module.scss";

type Props = {
  question: IClassQuestionWithIdentifier;
  user: IUser;
};

const ClassQuestions: React.FC<Props> = ({ question, user }) => {
  const queryClient = useQueryClient();
  const { data: users } = useAllUsers();
  const { data: comments } = useComments(
    question.identifier.weekId,
    question.identifier.termId,
    question.identifier.classId,
    question.identifier.courseId,
    question.question.id
  );

  const [replies, showReplies] = useState<boolean>(false);

  const addCommentToQuestionRQ = useMutation(addCommentToQuestion, {
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries([
        "comments",
        question.identifier.weekId,
        question.identifier.termId,
        question.identifier.classId,
        question.identifier.courseId,
        question.question.id,
      ]);
    },
    onError: (err) => {
      console.log("addCommentToQuestionRQ", err);
    },
    // onSettled: () => {
    //   setSubmitButtonEnabled(false);
    //   setReplyDescription("");
    // },
  });

  const handleSubmitReply = (textToSubmit: string) => {
    // e.preventDefault();
    if (textToSubmit.length === 0) return;

    const payload: AddCommentToQuestionPayload = {
      weekId: question.identifier.weekId,
      classId: question.identifier.classId,
      courseId: question.identifier.courseId,
      termId: question.identifier.termId,
      questionId: question.question.id,
      comment: textToSubmit,
    };
    addCommentToQuestionRQ.mutate(payload);
  };

  const handleShowReplies = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    showReplies(!replies);
  };

  const timeFormatter = (tm: number) => {
    const date = new Date(tm);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}/${month}/${year} at ${hours}:${minutes}`;
  };

  return (
    <div className={cls.root}>
      <div className={cls.comment} onClick={handleShowReplies}>
        <div className={cls.left}>
          <img src={user?.avatarUrl || "/user-avatar.webp"} alt='' />
        </div>
        <div className={cls.center}>
          <div className={cls.header}>
            <div className={cls.name}>
              {user?.firstName} {user?.lastName}
            </div>
            <div className={cls.date}>
              {timeFormatter(question.question.unixTimestamp)}
            </div>
          </div>
          <div className={cls.body}>{question.question.description}</div>
          <div className={cls.footer}>
            <div className={cls.reply}>Reply</div>
            <div className={cls.comments}>Comments ({comments?.length})</div>
          </div>
        </div>
        <div className={cls.right}></div>
      </div>
      {replies && (
        <div className={cls.replies}>
          {comments?.map((reply: IChatMessage, index: number) => {
            const user: IUser = users?.find(
              (u: IUser) => u.id === reply.userId
            );

            return (
              <div className={cls.replyText} key={index}>
                <div className={cls.left}>
                  {user?.role.name === "ADMIN" && <div> &#8594;</div>}

                  <img src={user?.avatarUrl || "/user-avatar.webp"} alt='' />
                </div>

                <div className={cls.right}>
                  <div className={cls.header}>
                    {user?.role.name === "ADMIN" && (
                      <div style={{ fontSize: 12, color: "#8e34fc" }}>
                        {" "}
                        [ADMIN]:
                      </div>
                    )}
                    <div className={cls.name}>
                      {user?.firstName} {user?.lastName}
                    </div>
                    <div className={cls.date}>
                      {timeFormatter(reply.unixTimestamp)}
                    </div>
                  </div>
                  <div
                    className={`${cls.body} ${
                      user?.role.name === "ADMIN" ? cls.adminMessage : ""
                    }`}>
                    {reply.content}
                  </div>
                </div>
              </div>
            );
          })}
          <div className={cls.messageComposer}>
            <MessageSubmitter
              emojiEnabled={true}
              submitMessage={handleSubmitReply}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassQuestions;
