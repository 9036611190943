import cls from "./WeekList.module.scss";

import { useParams } from "react-router-dom";
import { UrlParams } from "../../../interfaces/UrlParams";
import { IWeek } from "../../../interfaces/IWeek";
import { useWeeks } from "../../../api/weeks/weeksService";
import { useQuestions } from "../../../api/class/classQuestionService";
import { IClassQuestionWithIdentifier } from "../../../interfaces/IClassQuestionTopic";
import { useEffect, useState } from "react";

type Props = {
  _termId: string;
  selectedWeek?: string;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const WeekList: React.FC<Props> = ({ _termId, selectedWeek, onSelect }) => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, classId } = routeParameters;
  const { data: weeksData } = useWeeks(courseId, _termId);
  const { data: questions, isLoading: isLoadingQuestions } =
    useQuestions(classId);

  const WeekItem: React.FC<{
    week: IWeek;
    questions: IClassQuestionWithIdentifier[];
  }> = ({ week, questions }) => {
    const [weeklyQuestions, setWeeklyQuestions] = useState<
      IClassQuestionWithIdentifier[]
    >([]);

    useEffect(() => {
      if (questions) {
        const thisWeekQuestions = questions.filter(
          (question: IClassQuestionWithIdentifier) =>
            question.identifier.weekId === week.id
        );

        const thisWeekQuestionsCount = thisWeekQuestions?.filter(
          (classQuestion: any) =>
            !classQuestion.question.lastAnswer ||
            classQuestion.question.lastAnswer === null ||
            classQuestion.question.lastAnswer?.userRole === "USER"
        );
        setWeeklyQuestions(thisWeekQuestionsCount);
      }
    }, [questions, week.id]);

    return (
      <div className={cls.option}>
        <input
          name='select'
          type='radio'
          value={week.id}
          id={week.title}
          checked={week.title === selectedWeek}
          onChange={onSelect}
        />
        <label htmlFor={week.title}>
          - {week.title}
          {weeklyQuestions.length > 0 && (
            <div className={cls.questionCount}>{weeklyQuestions.length}</div>
          )}
        </label>
      </div>
    );
  };

  return (
    <div className={cls.root}>
      {weeksData?.map((week: IWeek, index: number) => {
        return <WeekItem key={index} week={week} questions={questions} />;
      })}
    </div>
  );
};

export default WeekList;
