import cls from "./Users.module.scss";
import { useContext, useState } from "react";
import AppHeader from "../../components/appHeader/AppHeader";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";
import { CollapsedSidebarContext } from "../../context/collapsedSidebarProvider";
import { useAllUsers } from "../../api/user/userService";
import Loading from "../../components/common/loading/Loading";
import { IUser } from "../../interfaces/IUser";
import UserListItem from "../../components/userListItem/UserListItem";
import SearchInput from "../../components/common/searchInput/SearchInput";
import MainBtn from "../../components/common/buttons/mainBtn/MainBtn";
import { useNavigate } from "react-router-dom";

const Users: React.FC = () => {
  const { collapsed } = useContext(CollapsedSidebarContext);
  const navigate = useNavigate();
  const { data: userList, isLoading: isLoadingUserList } = useAllUsers();
  const [filter, setFilter] = useState<string>("");

  const handleFilterUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const text = e.target.value;
    setFilter(text);
  };

  const matchesFilter = (user: IUser, filter: string) => {
    return (
      user.email.toLowerCase().includes(filter.toLowerCase()) ||
      user.firstName?.toLowerCase().includes(filter.toLowerCase()) ||
      user.lastName?.toLowerCase().includes(filter.toLowerCase()) ||
      user.phoneNumber?.toLowerCase().includes(filter.toLowerCase()) ||
      user.role?.name?.toLowerCase().includes(filter.toLowerCase()) ||
      user.id.toLowerCase().includes(filter.toLowerCase())
    );
  };

  return (
    <div className={cls.root}>
      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />
        <div className={cls.container}>
          {isLoadingUserList && <Loading />}
          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>All Users</div>
              <div className={cls.subtitle}>Lorem ipsum dolor amet.</div>
            </div>
            <SearchInput
              customPlaceholder={"Search user"}
              width={320}
              height={55}
              onValueChange={handleFilterUsers}
            />
            <MainBtn
              label='Create user'
              onClick={() => navigate("/users/create")}
            />
          </div>
          {userList?.length === 0 && (
            <p
              style={{
                textAlign: "center",
                color: "white",
                padding: "30px",
                fontSize: "1.5rem",
              }}>
              No users found!
            </p>
          )}
          <div className={cls.contents}>
            {(filter.length > 2
              ? userList.filter((u: IUser) => matchesFilter(u, filter))
              : userList
            )?.map((user: IUser, index: number) => (
              <UserListItem data={user} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
