import cls from "./RoleSelector.module.scss";
import { FieldError } from "react-hook-form";
import { useEffect, useState } from "react";
import { useAdminUser } from "../../api/user/userService";
import { IRole } from "../../interfaces/IRole";

type Props = {
  width?: number;
  height?: number;
  defaultValue?: string;
  userRoles: IRole[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: FieldError | undefined;
};

const RoleSelector: React.FC<Props> = ({
  width,
  height,
  onChange,
  userRoles,
  defaultValue,
  error,
}) => {
  const { data: adminUser } = useAdminUser();
  // const { data: userRoles } = useRoles(!!adminUser);

  const [roleList, setRoleList] = useState<IRole[]>([]);
  const [currentVal, setCurrentVal] = useState<string | undefined>(
    defaultValue
  );

  const [currentPermissions, setCurrentPermissions] = useState<string[]>([]);

  const isChecked = (val: string) => {
    return currentVal ? val === currentVal : false;
  };

  const canAssignRole = (targetRole: string) => {
    if (adminUser?.role?.name === "OWNER") return true;

    return (
      adminUser?.role?.name === "ADMIN" &&
      targetRole !== "OWNER" &&
      targetRole !== "ADMIN"
    );
  };

  useEffect(() => {
    let list: IRole[] = [];
    if (userRoles) for (const role of userRoles) list.push(role);
    setRoleList(list);

    const perms = list.filter((role: IRole) => role.name === currentVal);
    setCurrentPermissions(perms[0]?.permissions);
  }, [userRoles, currentVal]);

  return (
    <div className={cls.root} style={{ width: width, height: height }}>
      {roleList.map((role: IRole, index: number) => {
        return (
          <div
            className={`${cls.field} ${
              isChecked(role.name) ? cls.active : ""
            } ${!canAssignRole(role.name) ? cls.disabled : ""}`}
            key={index}>
            <input
              name='role'
              type='radio'
              value={role.name}
              className={`${isChecked(role.name) ? cls.active : ""}`}
              disabled={!canAssignRole(role.name)}
              onClick={() => setCurrentVal(role.name)}
              onChange={onChange}
            />
            <label>{role.name}</label>
          </div>
        );
      })}
      <div className={cls.footer}>
        <div className={cls.permissions}>Permissions:</div>

        {currentPermissions?.map((role: string, index: number) => (
          <span key={index}>{role}</span>
        ))}
      </div>
      <p className={cls.error}>{`${error ? error.message : ""}`}</p>
    </div>
  );
};

export default RoleSelector;
