import { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  saveHelpQuestion,
  useAllHelpQuestionCategories,
  useAllHelpQuestions,
} from "../../../api/help/helpService";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AppHeader from "../../../components/appHeader/AppHeader";
import MainBtn from "../../../components/common/buttons/mainBtn/MainBtn";
import ConfirmationPopUp from "../../../components/common/confirmationPopUp/ConfirmationPopUp";
import FormInput from "../../../components/common/formInput/FormInput";
import Loading from "../../../components/common/loading/Loading";
import TextareaInput from "../../../components/common/textareaInput/TextareaInput";
import SidebarMenu from "../../../components/sidebarMenu/SidebarMenu";
import { CollapsedSidebarContext } from "../../../context/collapsedSidebarProvider";
import {
  HelpQuestionCategory,
  IHelpQuestion,
  INewHelpQuestion,
} from "../../../interfaces/IHelpQuestion";
// import { v4 as uuidV4 } from "uuid";
import cls from "./CreateQuestion.module.scss";
import CategorySelector from "../../../components/help/categorySelector/CategorySelector";

type FormInputs = {
  title: string;
  answer: string;
};

const schema = yup.object().shape({
  title: yup.string().required("Title is required!"),
  answer: yup.string().required("Answer is required!"),
});

const CreateQuestion: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { collapsed } = useContext(CollapsedSidebarContext);

  const { data: allHelpCategories, isLoading: isLoadingAllHelpCategories } =
    useAllHelpQuestionCategories();
  const { data: allHelpQuestions, isLoading: isLoadingAllHelpQuestions } =
    useAllHelpQuestions();

  const [categorySelector, showCategorySelector] = useState<boolean>(false);

  const [categoryData, setCategoryData] = useState<HelpQuestionCategory>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  /////////////// POPUPS ////////////////

  const [successConfirmationCreated, showSuccessConfirmationCreated] =
    useState<boolean>(false);

  const handleSelectCategory = (cat: HelpQuestionCategory) => {
    setCategoryData(cat);
    showCategorySelector(false);
  };

  /////// SAVE QUESTION //////
  const { handleSubmit, control, formState } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const saveQuestionRQ = useMutation(saveHelpQuestion, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      // Invalidates cache and refetch
      console.log("questions updated successfully!");
      queryClient.invalidateQueries(["help-questions"]);
      showSuccessConfirmationCreated(true);
      // setButtonDisabled(true);
    },
    onError: (err) => {
      console.log("error question update", err);
      setIsLoading(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const onSubmit = (data: FormInputs) => {
    if (formState.isDirty) {
      if (allHelpQuestions && allHelpCategories && categoryData) {
        const payload: INewHelpQuestion = {
          categoryId: categoryData.id,
          title: data.title,
          // id: uuidV4(),
          content: data.answer,
          orderIndex:
            allHelpQuestions.length === 0
              ? 0
              : Math.max(
                  ...allHelpQuestions.map(
                    (obj: IHelpQuestion) => obj.orderIndex
                  )
                ) + 1,
        };
        console.log(payload);
        saveQuestionRQ.mutate(payload);
      }
    }
  };

  useEffect(() => {
    if (allHelpCategories) setCategoryData(allHelpCategories[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allHelpCategories]);

  return (
    <div className={cls.root}>
      {successConfirmationCreated && (
        <ConfirmationPopUp
          type='success'
          message='Question successfully created!'
          onClick={() => [
            showSuccessConfirmationCreated(false),
            navigate("/help"),
          ]}
        />
      )}

      {categorySelector && categoryData && (
        <CategorySelector
          onSubmit={handleSelectCategory}
          onDeny={() => showCategorySelector(false)}
          defaultValue={categoryData}
        />
      )}
      {<SidebarMenu />}
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />

        <div className={cls.container}>
          {(isLoadingAllHelpQuestions ||
            isLoadingAllHelpCategories ||
            isLoading) && <Loading />}

          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Help Editor</div>
              <div className={cls.subtitle}>Create new help question</div>
            </div>
          </div>
          <div className={cls.contents}>
            <div className={cls.form}>
              {allHelpCategories && (
                <form action=''>
                  <div className={cls.row}>
                    <Controller
                      name='title'
                      defaultValue=''
                      control={control}
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, ref },
                        fieldState: { isTouched, isDirty, error },
                      }) => (
                        <FormInput
                          type='text'
                          label='Question Title'
                          defaultValue=''
                          maxLength={600}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          isTouched={isTouched}
                          isDirty={isDirty}
                          error={error}
                        />
                      )}
                    />
                  </div>

                  <div className={cls.row}>
                    <Controller
                      name='answer'
                      defaultValue=''
                      control={control}
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, ref },
                        fieldState: { isTouched, isDirty, error },
                      }) => (
                        <TextareaInput
                          label='Answer'
                          defaultValue=''
                          maxLength={1100}
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          isTouched={isTouched}
                          isDirty={isDirty}
                          error={error}
                        />
                      )}
                    />
                  </div>
                  <div className={cls.row}>
                    <div className={cls.categories}>
                      <div className={cls.selected}>
                        <div className={cls.category}>
                          <div className={cls.body}>
                            <div className={cls.label}>
                              {categoryData?.name}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={cls.add}
                        onClick={() => showCategorySelector(true)}>
                        <div className={cls.body}>
                          <div className={cls.icon}></div>
                          <div className={cls.label}>Select Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}

              <div className={cls.actions}>
                <MainBtn
                  label='Back'
                  transparent={true}
                  width={130}
                  height={44}
                  onClick={() => navigate("/help")}
                />

                <MainBtn
                  disabled={!formState.isDirty}
                  label='Save'
                  width={180}
                  height={44}
                  onClick={handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateQuestion;
